<header>
    <h3>Master Shopping List <app-tooltip [key]="TooltipTypeReader.MASTER_SHOPPING_LIST"></app-tooltip></h3>
    <a class="btn-icon" [routerLink]="['/reader/my-receipts']" [queryParams]="{tab: '0'}">See more</a>
</header>

<app-content-wrapp class="content-wrapp no-padding">
    <mat-tab-group class="tabs ag-h-100" (selectedIndexChange)="selectedIndex=$event">
        <mat-tab label="Historical Items">
           <app-simple-table *ngIf="!(isMobile$|async)" class="table" [data]='historicalItems' [columns]='itemsColumns'
                              [isLoading]='isLoading' [useOldIcon]="true" [pagination]="itemsPagination" (paginationUpdate)="getHistoricalItems()">
            </app-simple-table>
           <app-simple-table-mobile *ngIf="(isMobile$|async) && selectedIndex==0" class="table-mobile" [data]='historicalItems' [columns]='itemsColumns'
                                    [isLoading]='isLoading' [pagination]="itemsPagination" (paginationUpdate)="getHistoricalItems()">
           </app-simple-table-mobile>
        </mat-tab>
        <mat-tab label="Shopping List">
            <app-simple-table  *ngIf="!(isMobile$|async)" class="table" [data]='shoppingList' [columns]='shoppingListColumns'
                                [isLoading]='isLoading' [pagination]="shoppingListPagination" (paginationUpdate)="getShoppingList()">
            </app-simple-table>
            <app-simple-table-mobile *ngIf="(isMobile$|async)&& selectedIndex==1" class="table-mobile" [data]='shoppingList' [columns]='shoppingListColumns'
                                      [isLoading]='isLoading' [pagination]="shoppingListPagination" (paginationUpdate)="getShoppingList()">
            </app-simple-table-mobile>
        </mat-tab>
        <mat-tab label="Saved Shopping Lists">
            <app-simple-table  *ngIf="!(isMobile$|async)" class="table" [data]='savedShoppingList' [columns]='savedShoppingListColumns'
                                [isLoading]='isLoading' [pagination]="savedShoppingListPagination" (paginationUpdate)="getSavedShoppingLists()" >
            </app-simple-table>
            <app-simple-table-mobile *ngIf="(isMobile$|async)&& selectedIndex==2" class="table-mobile" [data]='savedShoppingList' [columns]='savedShoppingListColumns'
                                [isLoading]='isLoading' [pagination]="savedShoppingListPagination" (paginationUpdate)="getSavedShoppingLists()">
            </app-simple-table-mobile>
        </mat-tab>
        <mat-tab label="Orders">
            <app-simple-table  *ngIf="!(isMobile$|async)" class="table" [data]='orders' [columns]='ordersColumns'
                                [isLoading]='isLoading' [pagination]="ordersPagination" (paginationUpdate)="getSavedOrdersLists()">
            </app-simple-table>
            <app-simple-table-mobile *ngIf="(isMobile$|async)&& selectedIndex==3" class="table-mobile" [data]='orders' [columns]='ordersColumns'
                                      [isLoading]='isLoading' [pagination]="ordersPagination" (paginationUpdate)="getSavedOrdersLists()">
            </app-simple-table-mobile>
        </mat-tab>
    </mat-tab-group>
</app-content-wrapp>
