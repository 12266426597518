import { VerificationMode } from './../../../auth/verification.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { convertPagination } from '../../utils';
import { IOldPagination } from '../../interfaces/old-pagination.model';

@Injectable({
  providedIn: 'root'
})
export class HttpProfileService {

  constructor(private httpClient: HttpClient, private authUserService: AuthUserService) {}

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  private get accountType() {
    return this.authUserService.getAccountType();
  }

  // User Details

  getUserData() {
    console.log("user data ");
    
    return this.httpClient.get(`${environment.apiUrl}/users/getuserDetails/${this.userUid}`);
  }

  saveUserData(data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/userDetails/${this.userUid}`, data);
  }

  saveUserAvatar(avatar: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/avatar-user`, avatar);
  }

  getAvatar(avatarUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/avatars/${avatarUid}`);
  }

  checkUsernameValidation(username: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/check-username/${this.userUid}?username=${username}`);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.httpClient.put(`${environment.apiUrl}/users/save-update`, {userUid: this.userUid, mode: VerificationMode.CHANGE_PASSWORD, oldValue: oldPassword, newValue: newPassword});
  }

  changeEmail(mode: VerificationMode, newEmail: string) {
    return this.httpClient.put(`${environment.apiUrl}/users/save-update`, {userUid: this.userUid, mode, newValue: newEmail});
  }


  deactivateAccount() {
    return this.httpClient.put(`${environment.apiUrl}/users/deactivate`, {userUid: this.userUid});
  }

  // Notes

  getNotes(sortBy: string, pagination: IOldPagination) {
    const pag = convertPagination(pagination)
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/notes`,
    {params: {sortBy, rowsFrom:pag.rowsFrom, rowsTo:pag.rowsTo}});
  }

  addNote(note: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${this.userUid}/notes`, note);
  }

  updateNote(noteUid: string, note: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/notes/${noteUid}`, note);
  }

  deleteNote(noteUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/users/${this.userUid}/notes/${noteUid}`);
  }

  // Terms and privacy

  getUserPrivacyList() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/terms-and-privacy`);
  }

  getUserPrivacyText() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/privacy-free-text?accountType=${this.accountType}`);
  }

  updateUserPrivacy(data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/terms-and-privacy`, data);
  }

  // Header

  getUserHeaderReaderInfo() {
    return this.httpClient.get(`${environment.apiUrl}/users/header-info/${this.userUid}`);
  }

  // CheckOut

  getQRCode() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/qrCode`);
  }

  getBarcode() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/barcode`);
  }

  getUserDetailsCompliteStatus() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/is-user-details-complited`);
  }



}
