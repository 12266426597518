import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public toggleMenu = new Subject();
  public reloadHeader$ = new Subject();
  public reloadUnreadMessages$ = new Subject();
  public reloadUnreadSupport$ = new Subject();
  public selectedPageUrl = 'dashboard';

  constructor(private router: Router) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((val: NavigationEnd) => {
      this.selectedPageUrl = val.url});
  }

  public close() {
    this.toggleMenu.next('close');
  }

  public toggleLeftMenu(isOpened: boolean) {
    this.toggleMenu.next(isOpened);
  }

  public toReloadHeader() {
    this.reloadHeader$.next();
  }

  public toReloadUnreadMessages(count: number) {
    this.reloadUnreadMessages$.next(count);
  }

  public toReloadUnreadSupport(count: number) {
    this.reloadUnreadSupport$.next(count);
  }


}
