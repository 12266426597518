import { trigger, state, style, transition, animate, animation, useAnimation } from '@angular/animations';
import { isShort } from '../_services/helpers/detect-mobile';


export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'width': '70px'
    })
  ),
  state('open',
    style({
      'width': '205px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);


export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '70px'
    })
  ),
  state('open',
    style({
      'margin-left': '205px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);


export const animateText = trigger('animateText', [
  state('hide',
    style({
      'display': 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      'display': 'block',
      opacity: 0.5,
    })
  ),
  transition('close => open', animate('350ms ease-in')),
  transition('open => close', animate('350ms ease-out')),
]);

export const onSectionExpand = trigger('onSectionExpand', [
  state('close',
    style({
      'max-height': '360px'
    })
  ),
  state('open',
    style({
      'max-height': isShort() ?  'calc(100vh - 100px)' : 'calc(100vh - 270px)',
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
]);

export let fadeInAnimation = animation([
  style({opacity : 0}),
  animate('{{duration}} {{easing}}')
], {
  params: {
      duration: '500ms',
      easing: 'ease-in'
  }
});

export let fadeOutAnimation = animation([
  style({opacity : 1}),
  animate('{{duration}} {{easing}}', style({opacity: 0}))
], {
  params: {
      duration: '1s',
      easing: 'ease-out'
  }
});

export let fade = trigger('fade', [
  transition(':enter',
      useAnimation(fadeInAnimation)
  )]
);

export let fadeMix =
    trigger('fadeMix', [
          transition(':enter',
            useAnimation(fadeInAnimation)
          ),
          transition(':leave',
              useAnimation(fadeOutAnimation)
          )
]);


