import { MatTableDataSource } from "@angular/material/table";
import { AuthUserService } from "./../../@auth/auth-user.service";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IOldPagination } from "src/app/_shared/interfaces/old-pagination.model";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-simple-table-select",
  templateUrl: "./simple-table-select.component.html",
  styleUrls: ["./simple-table-select.component.scss"],
})
export class SimpleTableSelectComponent implements OnChanges, OnInit {
  @ViewChild("empTbSortWithObject") sort = new MatSort();
  @Input() data = [];
  @Input() columns: any = {};
  @Input() isFixedLayoutTable: boolean;
  @Input() isLoading = false;
  @Input() oPagination: IOldPagination = {
    page: 0,
    pageSize: 0,
    totalRows: 0,
  };
  @Output() pageChange = new EventEmitter<number>();
  @Output() sortChange = new EventEmitter<string>();
  @Input() tableName = "simple-table-select";
  @Input() selectedByColumn = "id";
  @Input() selectedArray = [];
  @Output() selectArrayChange = new EventEmitter();
  @Output() elementClicked = new EventEmitter();

  @Input() useOldIcon = false;

  sortInfo = {};
  dataSource: any;
  displayedColumns: string[] = [];
  currency = "";

  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  onMatSortChange() {
    this.sortChange.emit(
      this.createQueryString(this.sort.direction, this.sort.active)
    );
    this.tableName = this.tableName;

    Object.keys(this.sortInfo).forEach((p) => (this.sortInfo[p] = ""));
    this.sortInfo[this.sort.active] = this.sort.direction;

  }
  createQueryString(orderBy: string, sortBy: string) {
    return orderBy ? `?orderBy=${orderBy}&sortBy=${sortBy}` : "";
  }
  ngOnInit() {
    //this.dataSource.sort = sort;
  }
  ngOnChanges() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
      this.sortInfo[column] = "";
    }
    this.dataSource = new MatTableDataSource(this.data);
    //For accessing nested object (productDescription) for sorting purposes
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "productDescription": {
          if (typeof item[property] === "object")
            return item[property].productName;
          return item[property];
        }
        default:
          return item[property];
      }
    };
    this.displayedColumns = columns;
    this.oPagination = this.oPagination;
  }

  selectChange(e) {
    if (e.checked) {
      this.selectedArray.push(e.source.id);
    } else {
      this.selectedArray = this.selectedArray.filter((v) => v !== e.source.id);
    }
    this.selectArrayChange.emit(this.selectedArray);
  }

  elementClick(columnName, row) {
    this.elementClicked.emit({ columnName, row });
  }
}
