export enum TooltipTypeReader {
    CHECKOUT_ID = 'checkout_id',
    HAPPY_FACE = 'happy_face',
    NOTIFICATIONS = 'notifications',
    REVIEWS_AND_RATING = 'reviews_and_rating',
    MY_RECEIPTS = 'my_receipts',
    PRODUCT_RECALL = 'product_recall',
    SPECIAL_DEALS = 'special_deals',
    MY_PUBLISHERS = 'my_publishers',
    MOST_BOUGHT_ITEMS = 'most_bought_items',
    MASTER_SHOPPING_LIST = 'master_shopping_list',
    DETAILED_REPORT = 'detailed_report',
    TOTAL_SPEND_PUBLISHER = 'total_spend_publisher',
    LOYALTY_POINTS = 'loyalty_points',
    CARD_PAYMENT_DETAILS = 'card_payment_details',
    PAYMENT_DETAILS = 'payment_details',
    RATE_YOUR_EXPERIENCE = 'rate_your_experience',
    ADDITIONAL_RECEIPT_INFO = 'additional_receipt_info',
    RECEIPT_NOTES = 'receipt_notes',
    PROFILE_NOTES = 'profile_notes',
    PERSONAL_DETAILS = 'personal_details',
    SECURITY_DETAILS = 'security_details',
    PROFILE_IMAGE = 'profile_image',
    TERMS_OF_USE_AND_PRIVACY = 'terms_of_use_and_privacy',
    REQUEST_RECEIPT = 'request_receipt',
    EXPENSES_STATS = 'expenses_stats'
}

export enum TooltipTypePublisher {
    CHECKOUT_ID = 'checkout_id',
    NOTIFICATIONS = 'notifications',
    TOTAL_SPEND_READER = 'total_spend_reader',
    READERS_BY_GENDER = 'readers_by_gender'
}
