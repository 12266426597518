<div *ngIf="!filtersOff" class="head">
    <aside class="head mb-3">
        <div class="filter-wrapp">
            <input nbInput
            class="filter"
            fullWidth
            matInput
            (keyup)="textFilter($event)"
            placeholder="Search..."
            name="filter">
            <mat-icon class="search">search</mat-icon>
        </div>
        <div *ngIf="amountFilterData">
            <div class="gray-select flex amount-filter">
            <div class="flex-align">
                <mat-checkbox (change)='amountFilterUpdate($event)' [(ngModel)]="amountFilterData.useFilter"></mat-checkbox>
            </div>
            <mat-form-field floatLabel="never" appearance="outline">
                <span class="less"><</span>
                <input class="amount-input" (keyup.enter)="amountFilterUpdate($event)" type="text" matInput [(ngModel)]="amountFilterData.amount"  [matAutocomplete]="auto">
                <mat-autocomplete (optionSelected)="amountFilterUpdate($event)" #auto="matAutocomplete">
                <mat-option *ngFor="let type of amountSelect" [value]="type">
                    {{type}}
                </mat-option>
                </mat-autocomplete>
                <span class="currency">{{currency}}</span>
            </mat-form-field>
            </div>
        </div>
        <div *ngIf="ratingFilterData">
            <div class="gray-select flex amount-filter">
            <div class="flex-align">
                <mat-checkbox [(ngModel)]="ratingFilterData.useFilter" (change)='rateUpdate($event)'></mat-checkbox>
            </div>
            <div class="rating-wrapp">
                <star-rating [hoverEnabled]="true" [showHalfStars]="true" (ratingChange)="rateUpdate($event)" [rating]="ratingFilterData.rating" [readOnly]='false' [starType]="'svg'" ></star-rating>
            </div>
            </div>
        </div>
        <div class="date-wrapp">
            <div class="flex-align">
            <mat-form-field floatLabel="never" class="date-start" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
                <input matInput
                        placeholder="DD/MM/YY"
                        [matDatepicker]="dateStartFilter"
                        [max]="dateFilterData.dateTo"
                        [(ngModel)]="dateFilterData.dateFrom"
                        (dateInput)="dateUpdate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dateStartFilter"></mat-datepicker-toggle>
                        <mat-datepicker #dateStartFilter startView="month"></mat-datepicker>
            </mat-form-field>
            <mat-form-field floatLabel="never" class="date-end" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
                <input  matInput
                        placeholder="DD/MM/YY"
                        [min]="dateFilterData.dateFrom"
                        [matDatepicker]="dateEndFilter"
                        [(ngModel)]="dateFilterData.dateTo"
                        (dateInput)="dateUpdate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dateEndFilter"></mat-datepicker-toggle>
                        <mat-datepicker #dateEndFilter startView="month"></mat-datepicker>
            </mat-form-field>
            </div>
        </div>
        <div class="filters-wrapp">
            <div (click)="openColumnFilter()" class="rating-wrapp columns">
                Select Fields
            </div>
        </div>
    </aside>
</div>
<div [ngClass]="{'loading': isLoading}" class="table-wrapp">
    <table [hidden]='isLoading||data.length===0' mat-table matSort [dataSource]="dataSource" multiTemplateDataRows (matSortChange)="updatePagination($event, 'sort')" class="table">
        <ng-container matColumnDef="{{column.fieldName}}" *ngFor="let column of columns">
            <th class="table-head" mat-header-cell mat-sort-header *matHeaderCellDef>
                 {{column.title}}
            </th>
            <td [ngClass]="{opacity: element.status==='lock'}" class='table-row' mat-cell *matCellDef="let element">
                <span class="element" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                      blueUnderline: ('blue-underline').includes(column.extraClass), longElement: ('longText').includes(column.extraClass)}" *ngIf="!column.valuePrepareFunction&&!column.extraValue" >
                   <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : ''">{{element[column.fieldName]}} </span>
                </span>
                <span class="element blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                <span class="element select" *ngIf="column.extraValue=='select'" (click)="column.action ? column.action($event, element) : null" >
                    <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggleRow(element) : null"
                      [checked]="selection.isSelected(element)"
                      [aria-label]="checkboxLabel(element)">
                    </mat-checkbox>
                </span>
                <span class="element" *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
                <span class="avatar element" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                </span>
                <span class="element" *ngIf="column.extraValue=='lock'" (click)="column.action ? column.action($event, element) : null"><img src="../../../assets/images/Table/{{element[column.fieldName]}}.svg" alt=""> </span>
                <span class="element" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                </span>
                <span (click)="elementClick(column.fieldName, element)" class="element product-description" *ngIf="column.extraValue=='productDescription'" (click)="column.action ? column.action($event, element) : null">
                <div class="product-image">
                    <img *ngIf="element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="">
                    <img *ngIf="!element[column.fieldName].productDescriptionURL" src="../../../../assets/images/Table/product-placeholder.png" alt="">
                </div>
                <span>{{element[column.fieldName].productName}}</span>
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="element-description">
                    <div [ngClass]="{opacity: element.status==='lock'}" class="row row-1 full-flex">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="i<3" class="item"  [ngClass]="{'avatar': column.extraValue=='avatar'}">
                                <span *ngIf="column.title!=='Brand'">{{column.title}}:</span>
                               <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                               <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : ''" *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass)}">{{element[column.fieldName] ? element[column.fieldName] : '-'}}</span>
                               <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                               <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                    <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                                </span>
                                <span class="avatar value" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div [ngClass]="{opacity: element.status==='lock'}" class="row row-2 full-flex">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="(i>2&&i<5&&expandedColumn.length>5)||(i>2&&i<4&&expandedColumn.length<6)" class="item"  [ngClass]="{'avatar': column.extraValue=='avatar'}">
                                <span *ngIf="column.title!=='Brand'">{{column.title}}:</span>
                               <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                               <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : ''" *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass), longText: ('longText').includes(column.extraClass)}">{{element[column.fieldName]}}</span>
                               <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                               <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                    <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                                </span>
                                <span class="avatar value" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div [ngClass]="{opacity: element.status==='lock'}" class="row row-3 flex-center">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="(i>4&&expandedColumn.length>5)||(i>3&&expandedColumn.length<6)" class="item" [ngClass]="{'avatar': column.extraValue=='avatar'}">
                                <span *ngIf="column.title!=='Brand'">{{column.title}}:</span>
                               <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                               <span *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass), longText: ('longText').includes(column.extraClass)}">{{element[column.fieldName]}}</span>
                               <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                               <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                    <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                                </span>
                                <span class="avatar value" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="(!disableExpand && !(element['status'] === 'lock')) ? (expandedElement = expandedElement === element ? null : element) : null">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <div *ngIf="data.length===0&&!isLoading" class="empty">
        <img src="../../../../assets/images/Home/bag-empty-3.png" alt="">
        <h1 >List is empty</h1>
    </div>
    <mat-spinner [diameter]="100" [strokeWidth]='7' [style.display]="isLoading ? 'block' : 'none'"></mat-spinner>
</div>
<mat-paginator
    [hidden]='isLoading'
    *ngIf="usePagination"
    (page)="updatePagination($event, 'page')"
    class="pagination"
    [length]="totalRows"
    [pageSizeOptions]="paginationSizeOptions">
</mat-paginator>
