<div
  *ngIf="data"
  class="table-wrapp"
  [ngClass]="{ 'empty-wrapp': data.length === 0 }"
  [ngStyle]="{ height: customHeight ? customHeight : '' }"
>
  <table
    #empTbSortWithObject="matSort"
    (matSortChange)="onMatSortChange()"
    [ngClass]="{ select: !selectOff, isLockTable: isLockTable }"
    matSort
    [hidden]="!(data.length > 0)"
    (matSortChange)="updatePagination($event, 'sort')"
    #table
    mat-table
    matSort
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Expense</th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleRow(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="isLockTable"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>No.</span>
      </th>
      <td class="number" mat-cell *matCellDef="let row; let i = index">
        <span>{{ i + 1 }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="btn2">
      <th
        class="btn-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      ></th>
      <td mat-cell *matCellDef="let row">
        <button (click)="btnClicked2.emit(row)" class="btn btn-red ml-auto">
          {{ extraBtn2 }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="btn">
      <th
        class="btn-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      ></th>
      <td mat-cell *matCellDef="let row">
        <button (click)="btnClicked.emit(row)" class="btn btn-gray ml-auto">
          {{ extraBtn }}
        </button>
      </td>
    </ng-container>
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.fieldName"
    >
      <!-- header -->
      <ng-container
        *ngIf="column.title !== 'Warranty' && column.title !== 'Actions'"
      >
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="header"
          [ngClass]="{
            date: column.extraClass === 'date',
            hideSort: column.noSort
          }"
        >
          <span
            *ngIf="column.extraValue !== 'select'"
            style="display: flex; align-items: center"
            >{{ column.title }}
            <div class="table-arrow-wrapper">
              <!-- <div class="table-sort-up"></div>
             <div class="table-sort-down"></div> -->
              <div
                class="table-sort-up table-sort-up--empty"
                [ngClass]="{
                  'table-sort-up--empty': sortInfo[column.fieldName] !== 'asc',
                  'table-sort-up--full': sortInfo[column.fieldName] === 'asc'
                }"
              ></div>
              <div
                class="table-sort-down table-sort-down--empty"
                [ngClass]="{
                  'table-sort-down--empty':
                    sortInfo[column.fieldName] !== 'desc',
                  'table-sort-down--full': sortInfo[column.fieldName] === 'desc'
                }"
              ></div>
            </div>
          </span>
        </th>
      </ng-container>
      <ng-container
        *ngIf="
          column.title === 'Warranty' ||
          column.title === 'Actions' ||
          !!column.noSort
        "
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="header"
          [ngClass]="{ date: column.extraClass === 'date' }"
        >
          <span *ngIf="column.extraValue !== 'select'">{{ column.title }}</span>
        </th>
      </ng-container>

      <!-- header -->
      <!-- content -->
      <td
        [ngClass]="{
          'offers-column': false,
          'wide-col': column.extraClass === 'wide-col',
          'wideXL-col': column.extraClass === 'wideXL-col',
          'fixed-size-col': column.extraClass === 'fixed-size-col'
        }"
        mat-cell
        *matCellDef="let element"
        (click)="
          onRowClick
            ? onRowClick(element)
            : column.action
            ? column.action(element)
            : ''
        "
      >
        <span
          *ngIf="!column.valuePrepareFunction && !column.extraValue"
          [ngClass]="{
            'wide-col': column.extraClass === 'wide-col',
            bold: column.extraClass === 'bold',
            'hide-text': column.extraClass === 'hide-text',
            'blue-underline': column.extraClass === 'blue-underline',
            simple: !element[column.fieldName]
          }"
          (click)="elementClick(column.fieldName, element)"
        >
          {{ element[column.fieldName] ? element[column.fieldName] : "—" }}
        </span>
        <span
          *ngIf="column.valuePrepareFunction"
          (click)="column.action ? column.action($event, element) : null"
          [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
        ></span>
        <span
          class="amount"
          *ngIf="column.extraValue == 'amount'"
          (click)="column.action ? column.action($event, element) : null"
        >
          {{ element[column.fieldName] ? element[column.fieldName] : "-" }}
          <span *ngIf="element[column.fieldName]" class="valute">{{
            currency
          }}</span>
        </span>
        <span
          *ngIf="
            column.extraValue == 'rating' ||
            column.extraValue == 'rating-editable'
          "
        >
          <star-rating
            #rating
            (click)="
              column.extraValue == 'rating-editable' && !isLockTable
                ? elementClick(column.fieldName, {
                    element: element,
                    rating: rating
                  })
                : null
            "
            [hoverEnabled]="true"
            [showHalfStars]="true"
            [readOnly]="
              column.extraValue == 'rating-editable' && !isLockTable
                ? false
                : true
            "
            [starType]="'svg'"
            [rating]="element[column.fieldName]"
          ></star-rating>
        </span>
        <!-- <span
          *ngIf="
            column.extraValue == 'hasAction'
          "
          (click)="column.action && column.action(element)"
        >
        {{element[column.fieldName]}}
        </span> -->
        <span
          class="product-description"
          *ngIf="column.extraValue == 'productDescription'"
          (click)="elementClick(column.fieldName, element)"
        >
          <div class="product-image">
            <img
              *ngIf="element[column.fieldName].productDescriptionURL"
              [src]="element[column.fieldName].productDescriptionURL"
              alt=""
            />
            <img
              *ngIf="
                !useBlueIcon && !element[column.fieldName].productDescriptionURL
              "
              src="../../../assets/images/Table//product-placeholder.png"
              alt=""
            />
            <img
              *ngIf="
                useBlueIcon && !element[column.fieldName].productDescriptionURL
              "
              src="../../assets/images/New design/empty-product.svg"
              alt=""
            />
          </div>
          <span>{{ element[column.fieldName].productName }}</span>
        </span>
        <span
          class="multiple-actions"
          *ngIf="column.extraValue == 'reviewActions'"
          (click)="elementClick(column.fieldName, element)"
        >
          <div class="multiple-actions-container">
            <button
              class="btn btn-green"
              (click)="column.addAction(element)"
              [disabled]="column.isDisabledAdd(element)"
            >
              <mat-icon style="margin: 0">add</mat-icon>
            </button>
            <button
              class="btn btn-blue"
              [disabled]="column.isDisabledEdit(element)"
            >
              <mat-icon style="margin: 0" (click)="column.editAction(element)"
                >edit</mat-icon
              >
            </button>
          </div>
        </span>
        <span
          class="multiple-actions"
          *ngIf="column.extraValue == 'multipleActions'"
          (click)="elementClick(column.fieldName, element)"
        >
          <div class="multiple-actions-container">
            <button class="btn btn-green">
              <img
                src="../../../assets/images/New design/add_action1.svg"
                alt=""
              />
            </button>
            <button class="btn btn-blue">
              <img
                src="../../../assets/images/New design/add_action2.svg"
                alt=""
              />
            </button>
            <button class="btn btn-danger">
              <img
                src="../../../assets/images/New design/add_action.svg"
                alt=""
              />
            </button>
          </div>
        </span>
        <span
          class="multiple-actions"
          *ngIf="column.extraValue == 'customActions'"
          (click)="elementClick(column.fieldName, element)"
        >
          <div class="multiple-actions-container">
            <button
              class="btn btn-green"
              *ngFor="let action of column.actions"
              [ngClass]="{
                'btn-danger': action.color === 'red',
                'btn-blue': action.color === 'blue'
              }"
              (click)="action.onclick(element)"
            >
              <img [src]="action.iconSrc" [alt]="action.name" />
            </button>
          </div>
        </span>
        <span
          *ngIf="column.extraValue == 'offers'"
          (click)="column.action ? column.action($event, element) : null"
        >
          {{ element[column.fieldName] }}
          <!-- <span *ngIf="!element[column.fieldName]" class="advertisement">offers</span>  -->
          <img
            *ngIf="!element[column.fieldName]"
            class="advertisement"
            src="../../../../assets/images/AdPlaceholder/140x38.png"
            alt=""
          />
        </span>
        <span
          *ngIf="column.extraValue == 'star-rating'"
          (click)="column.action ? column.action($event, element) : null"
        >
          <star-rating
            [size]="'small'"
            [space]="'between'"
            [rating]="element[column.fieldName]"
            [staticColor]="'default'"
            [hoverEnabled]="true"
            [readOnly]="true"
            [starType]="'svg'"
          ></star-rating>
        </span>
      </td>
      <!-- content -->
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      class="thinner"
      [ngStyle]="{ 'background-color': activeRowIndex === i ? '#ebeff5' : '' }"
    ></tr>
  </table>
  <!-- <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator> -->
  <app-empty *ngIf="data.length === 0 && !isEmptyImgHidden"></app-empty>
</div>

<!-- <mat-paginator
  *ngIf="usePagination"
  #paginator
  (page)="updatePagination($event, 'page')"
  class="pagination"
  [length]="totalRows"
  [pageSizeOptions]="paginationSizeOptions"
>
</mat-paginator> -->
<div
  class="bottom-content ag-flex-row-between align-center ag-mt-1"
  style="height: 50px; justify-content: center"
  *ngIf="isCustomPaginationUsed"
>
  <div style="order: 2">
    <span
      *ngFor="
        let item of []
          | paginate
            : {
                itemsPerPage: oPagination.pageSize,
                currentPage: oPagination.page,
                totalItems: oPagination.totalRows,
                id: tableName
              }
      "
    ></span>
    <app-paginator
      (pageChange)="onCustomPaginationClick($event)"
      [id]="tableName"
    ></app-paginator>
  </div>
  <ng-content></ng-content>
</div>
