import { AuthUserService } from './../../../@auth/auth-user.service';
import { Subscription } from 'rxjs';
import { AdService } from './../../../_shared/services/rest-services/ad.service';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-ad-history',
  templateUrl: './ad-history.component.html',
  styleUrls: ['./ad-history.component.scss']
})
export class AdHistoryComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  @Output() openDetailsEvent = new EventEmitter();
  public analiticsData = [];
  public columns = [
    {
      fieldName: 'nameAd',
      title: 'Ad Name',
    },
    {
      fieldName: 'startAd',
      title: 'Start Date',
    },
    {
      fieldName: 'endAd',
      title: 'End Date',
    },
    {
      fieldName: 'targetPage',
      title: 'Target Page',
    },
    {
      fieldName: 'session',
      title: 'Sessions',
    },
    {
      fieldName: 'viewers',
      title: 'Clicks',
    },
    {
      fieldName: 'expense',
      title: 'Expense',
      extraValue: 'tokens',
    },
    {
      fieldName: 'details',
      title: 'Details',
      extraClass: 'blue-underline'
    }
  ];

  constructor(public adService: AdService,
              public authUserService: AuthUserService) { }

  ngOnInit(): void {
    this.getAnalitics();
  }

  openDetails = (event) => {
    this.openDetailsEvent.emit(event);
  }

  getAnalitics() {
    this.subscription.add(this.adService.getAnalitics(this.authUserService.getUserUid())
      .subscribe ((response: any) => {
        this.analiticsData = response.result.analiticsLIst;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
