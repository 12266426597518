<div class="content ag-profile-picture-editor">
  <button class="btn-icon close" (click)="dialogRef.close()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{ isEditMode ? "Fit Image" : "Edit Image" }}</h1>
  <div *ngIf="!isEditMode" class="ag-picture-inputs">
    <div class="" style="flex: 1">
      <p class="input">Upload New Image</p>
      <mat-form-field floatLabel="never" style="margin-top: 6px">
        <input type="text" matInput (change)="loadInputUrl($event)" />
      </mat-form-field>
    </div>

    <button
      class="btn btn-blue ag-ppe-button"
      style="height: 48px; width: 48px; bottom: 1.15em !important"
    >
      <input
        class="btn btn-blue"
        type="file"
        id="fileupload"
        (change)="imageUploadEvent($event)"
        accept="image/gif, image/jpeg, image/png"
        style="opacity: 0; width: 100%; height: 100%"
      />
    </button>
    <ngx-croppie
      style="opacity: 0; pointer-events: none; position:absolute"
      #ngxCroppie
      [defaultZoom]="0.2"
      [outputFormatOptions]="outputoption"
      [croppieOptions]="croppieOptions"
      [imageUrl]="inputImg"
      (result)="newImageResultFromCroppie($event)"
    >
    </ngx-croppie>
  </div>
  <div class="ng-img-content" *ngIf="currImgSrc">
    <div class="ng-img-preview" *ngIf="!isEditMode">
      <img
        [src]="currImgSrc"
        alt="your current profile picture"
        class="ag-curr-picture-preview"
        #previewImage
      />
      <div class="ag-crop-icon" (click)="toggleEditMode()">
        <mat-icon>crop</mat-icon>
      </div>
    </div>
    <div class="ng-img-edit" *ngIf="isEditMode">
      <ngx-croppie
        #ngxCroppie
        [defaultZoom]="0.2"
        [outputFormatOptions]="outputoption"
        [croppieOptions]="croppieOptions"
        [imageUrl]="currImgSrc"
        (result)="newImageResultFromCroppie($event)"
      >
      </ngx-croppie>
    </div>
  </div>
  <div mat-dialog-actions class="ag-flex-row-end">
    <div class="ag-flex-row-stretch ag-flex-column-on-phone">
      <ng-container *ngIf="!isEditMode">
        <button
          type="button"
          (click)="dialogRef.close()"
          class="btn btn-outline-dark-blue mr-6"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-blue"
          (click)="onConfirmEditedImg(true)"
        >
          Confirm
        </button>
      </ng-container>
      <ng-container *ngIf="isEditMode">
        <button class="btn btn-blue" (click)="onConfirmEditedImg()">
          Save
        </button>
      </ng-container>
    </div>
  </div>
</div>
