import { ComplexTableMobileComponent } from './_mobile/complex-table-mobile/complex-table-mobile.component';
import { SimpleTableMobileComponent } from './_mobile/simple-table-mobile/simple-table-mobile.component';
import { NotificationsComponent } from './../_widgets/notifications/notifications.component';
import { AddAttachmentsComponent } from './add-attachments/add-attachments.component';
import { ChartLegendPipe } from './analytics-components/pie-chart/chart-legend.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { MapComponent } from './map/map.component';
import { AttachmentsMobileComponent } from './_mobile/attachments-mobile/attachments-mobile.component';
import { SwipeAngularListModule } from 'swipe-angular-list';
import { ReplyMessageComponent } from './messages/reply-message/reply-message.component';
import { HighlightSearch } from './../_shared/pipes/highlight-search.pipe';
import { ReadTicketComponent } from './support/read-ticket/read-ticket.component';
import { NewTicketComponent } from './support/new-ticket/new-ticket.component';
import { MessagesLeftMenuComponent } from './messages/messages-left-menu/messages-left-menu.component';
import { ReadMessageComponent } from './messages/read-message/read-message.component';
import { ShopingListTableComponent } from './shoping-list-table/shoping-list-table.component';
import { FilterTableComponent } from './filter-table/filter-table.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/@theme/custom-material.module';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PageHeaderComponent } from './page-components/page-header/page-header.component';
import { PageBodyComponent } from './page-components/page-body/page-body.component';
import { PageComponent } from './page-components/page/page.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { PageFooterComponent } from './page-components/page-footer/page-footer.component';
import { MultipleAutocompleteSelectorComponent } from './multiple-autocomplete-selector/multiple-autocomplete-selector.component';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { IsTodayPipe } from '../_shared/pipes/is-today.pipe';
import { FilterCountPipe } from '../_shared/pipes/filter-count.pipe';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { StarRatingModule } from 'angular-star-rating';
import { ReviewsComponent } from './reviews/reviews.component';
import { PageHeaderWithBorderComponent } from './page-components/page-header-with-border/page-header-with-border.component';
import { SelectTableComponent } from './select-table/select-table.component';
import { NgxCroppieComponent } from './ngx-croppie/ngx-croppie.component';
import { HeaderPublisherComponent } from './header-publisher/header-publisher.component';
import { SupportComponent } from './support/support.component';
import { SupportLeftMenuComponent } from './support/suppory-left-menu/support-left-menu.component';
import { SearchComponent } from './search/search.component';
import { ReadMesssagesDialogComponent } from './messages/read-messsages-dialog/read-messsages-dialog.component';
import { ReadTicketDialogComponent } from './support/read-ticket-dialog/read-ticket-dialog.component';
import { ReadFeedbackComponent } from './messages/read-feedback/read-feedback.component';
import { CarouselViewerComponent } from './carousel-viewer/carousel-viewer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { VideoViewerComponent } from './video-viewer/video-viewer.component';
import { StatsMobileComponent } from './_mobile/stats-mobile/stats-mobile.component';
import { FilterTableMobileComponent } from './_mobile/filter-table-mobile/filter-table-mobile.component';
import { SelectTableMobileComponent } from './_mobile/select-table-mobile/select-table-mobile.component';
import { ShopingListMobileComponent } from './shoping-list-mobile/shoping-list-mobile.component';
import { HammerModule } from '@angular/platform-browser';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { ChartComponent } from './chart/chart.component';
import { StatsComponent } from './stats/stats.component';
import { SearchPipe } from './search/search.pipe';
import { SortComponent } from './sort/sort.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CheckoutIdComponent } from './checkout-id/checkout-id.component';
import { ProgressBarComponent } from './analytics-components/progress-bar/progress-bar.component';
import { PieChartComponent } from './analytics-components/pie-chart/pie-chart.component';
import { ReviewsRatingComponent } from './reviews-rating/reviews-rating.component';
import { ContentWrappComponent } from './page-components/content-wrapp/content-wrapp.component';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { LoaderComponent } from './loader/loader.component';
import { EmptyComponent } from './empty/empty.component';
import { AtomSearchComponent } from './atom/search/search.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { WidgetMyReceiptsComponent } from '../_widgets/my-receipts/my-receipts.component';
import { WidgetProductRecallComponent } from '../_widgets/product-recall/product-recall.component';
import { OneReceiptComponent } from '../_widgets/my-receipts/one-receipt/one-receipt.component';
import { OneRecallComponent } from '../_widgets/product-recall/one-recall/one-recall.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginatorComponent } from './paginator/paginator.component';
import { MasterShopppingListComponent } from '../pages-reader/n-dashboard-reader/master-shoppping-list/master-shoppping-list.component';
import { SimpleTableSelectComponent } from './simple-table-select/simple-table-select.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AtomButtonExpandComponent } from '../_widgets/atom/button-expand/button-expand.component';
import { OneReviewsRatingComponent } from './reviews-rating/one-reviews-rating/one-reviews-rating.component';
import { AtomListScrollComponent } from '../_widgets/atom/list-scroll/list-scroll.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { WalletIframeComponent } from './wallet-iframe/wallet-iframe.component';
import { Chart2dVerticalComponent } from './chart2d-vertical/chart2d-vertical.component';
import { Chart1dHorizontalComponent } from './chart1d-horizontal/chart1d-horizontal.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import {environment} from '../../environments/environment';
import { ProfilePictureEditorComponent } from './profile-picture-editor/profile-picture-editor.component';
import { ChartLinearHorizontalComponent } from './chart-linear-horizontal/chart-linear-horizontal.component';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    CarouselModule,
    StarRatingModule.forRoot(),
    HammerModule,
    SwipeAngularListModule,
    RoundProgressModule,
    ClickOutsideModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    AgmJsMarkerClustererModule,
    AngularSvgIconModule.forRoot(),
    NgxPaginationModule,
    NgxMatIntlTelInputModule,
    AngularResizedEventModule
  ],
  declarations: [
    FooterComponent,
    QuestionDialogComponent,
    IsTodayPipe,
    HighlightSearch,
    PageHeaderComponent,
    PageBodyComponent,
    PageFooterComponent,
    PageComponent,
    ImageEditorComponent,
    SwitcherComponent,
    MultipleAutocompleteSelectorComponent,
    FilterTableComponent,
    FilterCountPipe,
    ImageViewerComponent,
    ReviewsComponent,
    PageHeaderWithBorderComponent,
    SelectTableComponent,
    NgxCroppieComponent,
    HeaderPublisherComponent,
    ReadMessageComponent,
    MessagesLeftMenuComponent,
    NewTicketComponent,
    ShopingListTableComponent,
    SupportComponent,
    SupportLeftMenuComponent,
    ReadTicketComponent,
    SearchComponent,
    SearchPipe,
    ReadMesssagesDialogComponent,
    ReadTicketDialogComponent,
    ReplyMessageComponent,
    ReadFeedbackComponent,
    CarouselViewerComponent,
    VideoViewerComponent,
    StatsMobileComponent,
    FilterTableMobileComponent,
    SelectTableMobileComponent,
    ShopingListMobileComponent,
    AttachmentsMobileComponent,
    ChartComponent,
    StatsComponent,
    MapComponent,
    SortComponent,
    TooltipComponent,
    CheckoutIdComponent,
    ProgressBarComponent,
    PieChartComponent,
    ChartLegendPipe,
    ReviewsRatingComponent,
    ContentWrappComponent,
    SimpleTableComponent,
    SimpleTableSelectComponent,
    LoaderComponent,
    EmptyComponent,
    AtomSearchComponent,
    WidgetMyReceiptsComponent,
    WidgetProductRecallComponent,
    OneReceiptComponent,
    OneRecallComponent,
    MasterShopppingListComponent,
    PaginatorComponent,
    AddAttachmentsComponent,
    NotificationsComponent,
    SimpleTableMobileComponent,
    ComplexTableMobileComponent,
    AtomButtonExpandComponent,
    AtomListScrollComponent,
    OneReviewsRatingComponent,
    WalletIframeComponent,
    Chart2dVerticalComponent,
    Chart1dHorizontalComponent,
    RichTextEditorComponent,
    ProfilePictureEditorComponent,
    ChartLinearHorizontalComponent,
  ],
  entryComponents: [
    QuestionDialogComponent,
    NewTicketComponent,
    ReadMesssagesDialogComponent,
    ReadTicketDialogComponent,
    ReplyMessageComponent
  ],
  exports: [
    FooterComponent,
    QuestionDialogComponent,
    IsTodayPipe,
    HighlightSearch,
    PageHeaderComponent,
    PageBodyComponent,
    PageFooterComponent,
    PageComponent,
    ImageEditorComponent,
    SwitcherComponent,
    MultipleAutocompleteSelectorComponent,
    FilterTableComponent,
    FilterCountPipe,
    ImageViewerComponent,
    ReviewsComponent,
    PageHeaderWithBorderComponent,
    SelectTableComponent,
    HeaderPublisherComponent,
    ReadMessageComponent,
    MessagesLeftMenuComponent,
    ShopingListTableComponent,
    ReadFeedbackComponent,
    CarouselViewerComponent,
    VideoViewerComponent,
    StatsMobileComponent,
    FilterTableMobileComponent,
    SelectTableMobileComponent,
    ShopingListMobileComponent,
    AttachmentsMobileComponent,
    ChartComponent,
    StatsComponent,
    MapComponent,
    SortComponent,
    TooltipComponent,
    CheckoutIdComponent,
    ProgressBarComponent,
    PieChartComponent,
    ReviewsRatingComponent,
    ContentWrappComponent,
    SimpleTableComponent,
    SimpleTableSelectComponent,
    LoaderComponent,
    EmptyComponent,
    AtomSearchComponent,
    WidgetMyReceiptsComponent,
    WidgetProductRecallComponent,
    OneReceiptComponent,
    OneRecallComponent,
    AngularSvgIconModule,
    PaginatorComponent,
    MasterShopppingListComponent,
    NgxPaginationModule,
    AddAttachmentsComponent,
    NgxMatIntlTelInputModule,
    NotificationsComponent,
    SimpleTableMobileComponent,
    ComplexTableMobileComponent,
    AtomButtonExpandComponent,
    AtomListScrollComponent,
    AngularResizedEventModule,
    CustomMaterialModule,
    WalletIframeComponent,
    Chart2dVerticalComponent,
    Chart1dHorizontalComponent,
    RichTextEditorComponent,
    ChartLinearHorizontalComponent
  ]
})
export class ComponentsModule {
}
