import { AuthUserService } from './../../@auth/auth-user.service';
import { MatTableDataSource } from '@angular/material/table';
import { FiltersMobileComponent } from './../../_dialogs/filters-mobile/filters-mobile.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-shoping-list-mobile',
  templateUrl: './shoping-list-mobile.component.html',
  styleUrls: ['./shoping-list-mobile.component.scss']
})
export class ShopingListMobileComponent implements OnInit, OnChanges {
  public subscription = new Subscription();
  public displayedColumns: string[] = [];
  public expandedColumn = [];
  @ViewChild('tableWrapp') public tableWrapp: ElementRef;
  @Input() data: any = [];
  @Input() noEditable: boolean;
  @Input() listName: string;
  @Input() maxColumns = 3;
  @Input() columns: any = {};
  @Input() toStrike: (event: any) => any;
  @Input() isLoading: boolean;
  @Input() paginationData;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  @Input() hideIfEmpty: boolean;
  @Output() addItemEvent = new EventEmitter();
  @Output() quantityEvent = new EventEmitter();
  @Output() priceEvent = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() paginationUpdate = new EventEmitter();
  public form: FormGroup;
  public currency = '';
  public dataSource: any;
  public newItem = '';
  public newPrice = 0;

  constructor(public dialog: MatDialog,
              private authUserService: AuthUserService) { 
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit() {
    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }

  updatePagination(data, type) {
    this.paginationUpdate.emit({data, type});
  }

  openColumnFilter() {
    const filtersDialog = this.dialog.open(FiltersMobileComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '350px',
      data: {columns: this.columns, maxColumns: this.maxColumns},
    });
    this.subscription.add(filtersDialog.afterClosed()
      .subscribe(result => {
        this.tableInitialization();
    }));
  }


  ngOnChanges() {
    this.tableInitialization();
  }

  tableInitialization() {
    const columns = [];
    const expandedColumn = [];
    for (const column of this.columns) {
      column.toUseMobile ? columns.push(column.fieldName) : expandedColumn.push(column);
    }
    if (this.data && !this.noEditable) {
      const i = this.data.findIndex (x => x.productName === 'new_custom_element') ;
      if (i > -1) {
        this.data.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
    this.expandedColumn = expandedColumn;
    this.dataSource.sort = this.sort;
    this.form = new FormGroup({
      productName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
    });
    if (!this.usePagination) {
      this.dataSource.sort = this.sort;
    }
  }

  onKeydown(event){
    event.preventDefault();
  }

  addItem(event) {
    if (this.form.valid) {
      this.addItemEvent.emit(this.form.get('productName').value);
      this.tableInitialization();
    }
    this.form.patchValue({productName: ''});
    this.scrollToBottom();
  }

  changeQuantity(row, quantity) {
    this.quantityEvent.emit({row, newValue: quantity, key: 'quantity'});
  }

  changePrice(row) {
    this.priceEvent.emit({row, newValue: this.newPrice, key: 'price'});
  }

  toEditModePrice(element) {
    this.newPrice = element.price ? element.price : 0;
    this.dataSource.filteredData.forEach(el => el.isEdit = el === element ? true : false);
  }

  toCanceltoEditPrice(event, element) {
    if (!event.target.classList[0].includes('mat-icon') && window.innerWidth <= 576) {
      element.isEdit = false;
    }
  }

  public scrollToBottom(noSmooth?: boolean) {
    setTimeout(() => {
      this.tableWrapp.nativeElement.scroll({
        top: this.tableWrapp.nativeElement.scrollHeight,
        left: 0,
        behavior: noSmooth ? 'auto' : 'smooth'
      });
    }, noSmooth ? 300 : 100);
  }

}
