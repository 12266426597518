import { AuthUserService } from './../../@auth/auth-user.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, EventEmitter, OnChanges, ViewChild, Output, ElementRef, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';

@Component({
  selector: 'app-shoping-table-list',
  templateUrl: './shoping-list-table.component.html',
  styleUrls: ['./shoping-list-table.component.scss']
})
export class ShopingListTableComponent implements OnInit, OnChanges {
  @ViewChild('tableWrapp') public tableWrapp: ElementRef;
  @ViewChild('priceInput') public priceInput: ElementRef;
  @Input() data: any = [];
  @Input() listName: string;
  @Input() columns: any = {};
  @Input() toStrike: (event: any) => any;
  @Input() isLoading: boolean;
  @Input() noEditable: boolean;
  @Input() paginationData;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() hideIfEmpty: boolean;
  @Input() paginationSizeOptions;
  @Output() addItemEvent = new EventEmitter();
  @Output() quantityEvent = new EventEmitter();
  @Output() priceEvent = new EventEmitter();
  @Output() paginationUpdate = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public form: FormGroup;
  public currency = '';
  public dataSource: any;
  public newItem = '';
  public newPrice = 0;
  public displayedColumns: string[] = [];
  selection = new SelectionModel(true, []);

  isNewItem(index, item): boolean {
    return item.productName === 'new_custom_element';
  }

  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit() {
    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }

  updatePagination(data, type) {
    this.paginationUpdate.emit({data, type});
  }

  ngOnChanges() {
    this.createTable();
  }

  createTable() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    if (this.data && !this.noEditable) {
      const i = this.data.findIndex (x => x.productName === 'new_custom_element') ;
      if (i > -1) {
        this.data.splice(i, 1);
      }
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
    this.form = new FormGroup({
      productName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
    });
    if (!this.usePagination) {
      this.dataSource.sort = this.sort;
    }
  }

  onKeydown(event){
    event.preventDefault();
  }

  addItem(event) {
    if (this.form.valid) {
      this.addItemEvent.emit(this.form.get('productName').value);
      this.createTable();
    }
    this.form.patchValue({productName: ''});
    this.scrollToBottom();
  }

  changeQuantity(row, quantity) {
    this.quantityEvent.emit({row, newValue: quantity, key: 'quantity'});
  }

  changePrice(row) {
    this.priceEvent.emit({row, newValue: this.newPrice, key: 'price'});
  }

  toEditModePrice(element) {
    this.newPrice = element.price ? element.price : 0;
    this.dataSource.filteredData.forEach(el => el.isEdit = el === element ? true : false);
    setTimeout(() => this.priceInput.nativeElement.focus(), 300);
  }

  public scrollToBottom(noSmooth?: boolean) {
    setTimeout(() => {
      this.tableWrapp.nativeElement.scroll({
        top: this.tableWrapp.nativeElement.scrollHeight,
        left: 0,
        behavior: noSmooth ? 'auto' : 'smooth'
      });
    }, noSmooth ? 500 : 300);
  }


}
