import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss']
})
export class VideoViewerComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  public safeUrl;
  @Input() data;
  @Output() clickEvent = new EventEmitter();

  constructor( public sanitizer: DomSanitizer,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.videoCreator();
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: any): void {
    if (this.document.activeElement == this.iframe.nativeElement) {
      this.clickEvent.next();
    }
  }



  videoCreator() {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video.link);
      const link = 'https://www.youtube.com/embed/' + this.safeUrl.changingThisBreaksApplicationSecurity.split('watch?v=')[1];
      this.safeUrl.changingThisBreaksApplicationSecurity = link;
  }

}
