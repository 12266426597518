import { HttpReceiptService } from 'src/app/_shared/services/http-services/http-receipts.service';
import { PasswordConfirmComponent } from './../../_dialogs/password-confirm/password-confirm.component';
import { MatDialog } from "@angular/material/dialog";

export function unlockReceipt (receiptUid: string, dialog: MatDialog, httpReceiptService: HttpReceiptService) {
    const unlockPromise = new Promise((resolve, reject) => {
        const confirmDialog = dialog.open(PasswordConfirmComponent, {
            disableClose: true,
            backdropClass: 'dialog-back',
            width: '400px'
        });
        confirmDialog.afterClosed()
        .subscribe( password => {
            if (password) {
                httpReceiptService.receiptChangeLockStatus({receiptUid, password, setLock: false})
                    .subscribe (res => resolve(true), err => reject(err.error.errors[0].message));
            } else {
                reject();
            }
        });
    });
    return unlockPromise;
   
}