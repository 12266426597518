import { environment } from 'src/environments/environment';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/_models/api.model';

@Injectable({
  providedIn: 'root'
})
export class HttpNotificationsService {
  backendApiUrl = ''

  constructor(private httpClient: HttpClient, private authUserService: AuthUserService) {
    this.backendApiUrl = environment.apiUrl;
  }

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  getNotifications(pagination: Pagination) {
    return this.httpClient.get(`${this.backendApiUrl}/users/${this.userUid}/notification`, {params: {...pagination.request()} });
  }

  readNotification(notificationId: string) {
    return this.httpClient.put(`${this.backendApiUrl}/users/${this.userUid}/read-notification`, {notificationId});
  }


}
