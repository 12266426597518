<h1 style="text-transform: capitalize">Transactions</h1>
<div class="transactions-summary-container ag-mt-3 ag-shadow-light">
  <div class="ag-quarter-tns-block">
    <div class="ag-main-row">
      <img src="../../../../assets/images/Transaction/bx-money-white.svg" />
      <span
        ><span class="ag-main-value">{{ txIn }} </span></span
      >
    </div>
    <div class="ag-main-row ag-single-val-row">In</div>
  </div>
  <div class="ag-quarter-tns-block">
    <div class="ag-main-row">
      <img
        src="../../../../assets/images/Transaction/bx-credit-card-white.svg"
      />
      <span
        ><span class="ag-main-value">{{ txOut }}</span
        ><span class="ag-sub-value"></span
      ></span>
    </div>
    <div class="ag-main-row ag-single-val-row">Out</div>
  </div>
  <div class="ag-quarter-tns-block">
    <div class="ag-main-row">
      <img src="../../../../assets/images/Transaction/transactions.png" />
      <span
        ><span class="ag-main-value">{{ txTotal }} </span></span
      >
    </div>
    <div class="ag-main-row ag-single-val-row">Tx(s)</div>
  </div>
</div>

<div class="ag-flex-row-between ag-mt-3 break-and-center-on-medium">
  <div
    class="ag-box-light m-advert phone-fix-width ag-shadow-light"
    style="width: 48%"
  >
    Advert Banner
  </div>
  <div
    class="ag-box-light m-advert phone-fix-width ag-shadow-light"
    style="width: 48%"
  >
    Advert Banner
  </div>
</div>
<app-linear-chart-card
  (selectorBtnClicked)="onChartSelectorClick($event)"
  [data]="chartData"
  [selectorValue]="'monthly'"
  [btnSelectorList]="[
    { name: 'Daily', value: 'daily' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Yearly', value: 'yearly' }
  ]"
></app-linear-chart-card>
<div class="transactions-table-container ag-mt-3">
  <div class="transactions-table-upper">
    <div class="transactions-table-upper-img">
      <img
        src="../../../../assets/images/New design/transactions.svg"
        style="height: 33px; width: 33px"
      />
    </div>
  </div>
  <div style="box-shadow: 2px 2px 3px #0000000f; background-color: white">
    <div class="ag-filter-container">
      <div class="ag-upper-filters">
        <div class="ag-search-container">
          <input
            class="standard_input"
            type="text"
            (keyup)="markAsChanged()"
            [(ngModel)]="searchString"
            placeholder="Search"
          />
        </div>
        <div class="ag-filter-icon-container">
          <img
            src="../../../../assets/images/New design/filter_dark.svg"
            class="filter-icon"
            (click)="toggleAdvancedFilters()"
          />
        </div>
      </div>
      <div class="ag-bottom-filters" *ngIf="showAdvancedFilters">
        <div class="ag-filter-row">
          <div class="ag-filter-cell" style="position: relative">
            <label>Date</label>
            <mat-form-field
              [classList]="'d-block'"
              floatLabel="never"
              [class.invalid]="dateFrom > dateTo"
            >
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  disabled
                  [max]="dateTo"
                  [(ngModel)]="dateFrom"
                />
                <input
                  matEndDate
                  disabled
                  [min]="dateFrom"
                  [(ngModel)]="dateTo"
                  (dateChange)="markAsChanged()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matPrefix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker
                #picker
                disabled="false"
              ></mat-date-range-picker>
            </mat-form-field>
            <img
              class="clear_input"
              (click)="$event.stopImmediatePropagation(); resetDatePicker()"
              src="../../../../assets/images/Shopping List//clear.png"
              width="18px"
              height="18px"
            />
          </div>
          <div class="ag-filter-cell">
            <label>Tx Desc</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="text"
              (keyup)="markAsChanged()"
              [(ngModel)]="txDesc"
              placeholder="Search"
            />
          </div>
          <div class="ag-filter-cell">
            <label>Tx ID</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="number"
              (keyup)="markAsChanged()"
              pattern="[0-9]*"
              [(ngModel)]="txId"
              placeholder="Search"
            />
          </div>
          <div class="ag-filter-cell">
            <label>Amount</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="number"
              (keyup)="markAsChanged()"
              [(ngModel)]="amount"
              pattern="[0-9]*\.?[0-9]*"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="ag-filter-row">
          <div class="ag-filter-cell">
            <label>Tx Code</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="number"
              (keyup)="markAsChanged()"
              [(ngModel)]="txCode"
              placeholder="Search"
              pattern="[0-9]*\.?[0-9]*"
            />
          </div>
          <div class="ag-filter-cell">
            <label>Tx Fees ({{ currCurrencyName }})</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="number"
              (keyup)="markAsChanged()"
              [(ngModel)]="txFees"
              placeholder="Search"
              pattern="[0-9]*\.?[0-9]*"
            />
          </div>
          <div class="ag-filter-cell">
            <label>From</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="text"
              (keyup)="markAsChanged()"
              [(ngModel)]="from"
              placeholder="Search"
            />
          </div>
          <div class="ag-filter-cell">
            <label>To</label>
            <input
              class="standard_input filter-dark"
              style="width: 100%"
              type="text"
              (keyup)="markAsChanged()"
              [(ngModel)]="to"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="ag-filters-btn-container ag-mt-3">
          <button class="btn btn-transparent" (click)="clearFilters()">
            <img
              class="clear_input"
              src="../../../../assets/images/Shopping List//clear.png"
              width="18px"
              height="18px"
              style="left: 0; right: initial; top: 50%"
            />
            Clear Filters
          </button>
          <button style="position: relative" class="btn btn-blue">
            Save Filters
          </button>
        </div>
      </div>
    </div>
    <app-simple-table-select
      [oPagination]="oPagination"
      [tableName]="'transactions-table'"
      [data]="tableList"
      [columns]="tableColumns"
      [isLoading]="isLoading || !currCurrencyName"
      [useOldIcon]="false"
      (pageChange)="transactionsPageChange($event)"
      (sortChange)="sortChanged($event)"
      [isFixedLayoutTable]="true"
    >
    </app-simple-table-select>
  </div>
</div>
<div class="ag-flex-row-between ag-mt-3 break-and-center-on-medium">
  <div
    class="ag-box-light m-advert phone-fix-width ag-shadow-light"
    style="width: 48%"
  >
    Advert Banner
  </div>
  <div
    class="ag-box-light m-advert phone-fix-width ag-shadow-light"
    style="width: 48%"
  >
    Advert Banner
  </div>
</div>
<div class="ag-flex-row-between ag-mt-3 break-and-center-on-medium">
  <div
    class="ag-box-light m-advert phone-fix-width ag-shadow-light"
    style="width: 100%"
  >
    Advert Banner
  </div>
</div>
