import { Subscription } from 'rxjs';
import { onSideNavChange, animateText } from './../../../@animations/animations';
import { MenuService } from 'src/app/_shared/services/menu.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [ onSideNavChange, animateText ]
})
export class LeftMenuComponent implements OnInit {
  private subscription = new Subscription();
  @Input() menuList = [];
  @Input() unreadMessages = 0;
  @Input() unreadSupport = 0;
  public sideNavState: boolean;
  public linkText: boolean;

  constructor(public menuService: MenuService, private router: Router) {}

  ngOnInit(): void {
  }



  onSinenavToggle() {
    this.sideNavState = !this.sideNavState
    this.menuService.toggleLeftMenu(this.sideNavState);
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, this.linkText ? 110 : 200);
  }

  isActive(url): boolean {
    return this.router.isActive(url, false);
  }

}
