import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter, SimpleChanges, OnChanges,
} from '@angular/core';

@Component({
  selector: "app-rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.scss"],
})
export class RichTextEditorComponent implements OnChanges {
  @ViewChild("iframeEditor", { static: false }) iframe: ElementRef;
  @Output() onTextChange = new EventEmitter<string>();

  @Input() startText: string;
  public font: string = "Times New Roman";
  public fontSize: string = "4";
  public color: string = "#000";
  public activeCommands: string[] = []
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.startText.currentValue !== changes?.startText.previousValue) {
      this.startText = changes?.startText.currentValue;

      setTimeout((() => {
        this.iframe.nativeElement.contentDocument.documentElement.querySelector(
          "body"
        ).innerHTML = changes?.startText.currentValue;
      }), 0);
    }
  }

  updateText() {
    this.onTextChange.emit(
      this.iframe.nativeElement.contentDocument.documentElement.querySelector(
        "body"
      ).innerHTML
    );
  }
  executeCommand(command: string, args?) {
    this.iframe.nativeElement.contentDocument.execCommand(command, false, args);
    this.toggleHighlight(command);
  }
  toggleHighlight(command: string){
    if(this.activeCommands.includes(command)) {
      this.activeCommands = this.activeCommands.filter(item => item !== command)
    } else {
      this.activeCommands = [...this.activeCommands, command];
    }
  }
  onIframeLoad(iframe) {
    if(this.startText) {
      iframe.contentDocument.documentElement.querySelector(
        "body"
      ).innerHTML = this.startText;
    }

    iframe.contentDocument.designMode = "on";
  }
}
