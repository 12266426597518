<div class="content">
  <button class="btn-icon menu-toggle"(click)="onSinenavToggle()"><img src="../../../../assets/images/New design/menu-toggle.svg" alt="menu-toggle" [ngClass]="{'opened': !sideNavState}" ></button>

  <mat-nav-list class="nav-list ag-flex-column-between" [ngClass]="{'bigger': sideNavState, 'smaller': !sideNavState }" [@onSideNavChange]="sideNavState ? 'open' : 'close'" >
    <div [ngClass]="{'closed': !sideNavState}" class="menu-categories">
      <aside class="category" *ngFor="let item of menuList">
        <a class="menu-item" [routerLink]="[item.route]" [routerLinkActive]="'active-menu'" >
          <img height="32px" width="32px" class="non-active-icon" [src]="'/assets/images/Menu/Readers/' + item.iconName + '.svg'">
          <img height="32px" width="32px" class="active-icon" [src]="'/assets/images/Menu/Readers/' + item.iconName + '_active.svg'">
          <span class="name">
            {{item.displayName}}
          </span>
        </a>
      </aside>
    </div>
    <app-menu-footer *ngIf="sideNavState" class="menu-footer" [@animateText]="linkText ? 'show' : 'hide'"></app-menu-footer>
  </mat-nav-list>
</div>

