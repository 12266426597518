<header>
  <h3>My Receipts <app-tooltip [key]="TooltipTypeReader.MY_RECEIPTS"></app-tooltip></h3>
</header>
<div class="ag-box-v ag-box-rounding margin-button">
  <div class="ag-bg-dark ">
    <div class="ag-flex-row-between">
      <div>
        <div class="ag-t-c"><span class="m-sum-value">{{oReceiptsSummary.nTotalAmount}}</span><span class="m-cur ag-t-light">{{sCurrency}}</span></div>
        <div class="ag-t-c m-sum-label">Total Amount</div>
      </div>
      <div class="ag-pipe-v"></div>
      <div>
        <div class="ag-t-c"><span class="m-sum-value">{{oReceiptsSummary.nTotalItems | currency:' '}}</span></div>
        <div class="ag-t-c m-sum-label">Total Items</div>
      </div>
      <div class="ag-pipe-v"></div>
      <div>
        <div class="ag-t-c"><span class="m-sum-value">{{oReceiptsSummary.nAvarageAmount | currency:' '}}</span> <span class="m-cur ag-t-light">{{sCurrency}}</span></div>
        <div class="ag-t-c m-sum-label">Average Amount</div>
      </div>
    </div>
    <div class="ag-my-1">
      <atom-search [bDarkMode]="true" (textChanged)="textUpdate($event)" ></atom-search>
    </div>

    <div class="ag-strong m-sum-label m-filter-by">
      Filter by:
    </div>

    <!-- <div (resized)="onResized($event)" [class]="bIsSmallSize ? 'ag-filter-flex-column' : 'ag-flex-row'"> -->
    <div class="ag-filter-flex-column">
      <!-- <div [class]="bIsSmallSize ? 'ag-flex-row-start' : 'ag-filter-flex-column'"> -->
      <div class="ag-flex-row-start">
        <div class="m-sum-label" (click)="switchAmountFilterAndUpdate()">
          <mat-checkbox [checked]="(aSkipFilter.indexOf('amount') === -1) ? true : false" disabled>
            Amount
          </mat-checkbox>
        </div>
        <div class="m-md-form m-amount-wrapp ag-action ag-flex-row-center">
          <mat-form-field floatLabel="never" appearance="outline">
            <span *ngIf="amountFilter.length"><</span>
            <input class="amount-input ag-action" (keyup.enter)="amountUpdate()" type="number" matInput [(ngModel)]="amountFilter"  [matAutocomplete]="auto">
            <mat-autocomplete  (optionSelected)="amountUpdate()" #auto="matAutocomplete">
              <mat-option *ngFor="let type of amountSelect" [value]="type">
                {{type}}
              </mat-option>
            </mat-autocomplete>
            <span>{{sCurrency}}</span>
          </mat-form-field>
        </div>
      </div>

      <!-- <div [class]="bIsSmallSize ? 'ag-flex-row-start' : 'ag-filter-flex-column'"> -->
      <div class="ag-flex-row-start">
        <div class="m-sum-label" (click)="switchRateAndUpdate()" >
          <mat-checkbox [checked]="(aSkipFilter.indexOf('rating') === -1) ? true : false" disabled>
            Rating
          </mat-checkbox>
        </div>
        <div class="m-rating-wrapp ag-action" >
          <star-rating [hoverEnabled]="true" (ratingChange)="rateUpdate($event)" [rating]="ratingFilter" [readOnly]='false' [starType]="'svg'" ></star-rating>
        </div>
      </div>

      <!-- <div [class]="bIsSmallSize ? 'ag-flex-row-start' : 'ag-filter-flex-column'"> -->
      <div class="ag-flex-row-start">
        <div class=" m-sum-label" (click)="switchDateAndUpdate()">
          <mat-checkbox [checked]="(aSkipFilter.indexOf('date') === -1) ? true : false" disabled>
            Date
          </mat-checkbox>
        </div>
        <div  >
          <div class="m-md-form m-date-wrapp ag-flex-row-center">
            <mat-form-field floatLabel="never" appearance="outline" [class.invalid]="dateFilterData.dateFrom>dateFilterData.dateTo">
              <mat-date-range-input [rangePicker]="picker" >
                <input matStartDate placeholder="Start date" disabled
                [max]="dateFilterData.dateTo"
                [(ngModel)]="dateFilterData.dateFrom"
                >
                <input matEndDate placeholder="End date" disabled
                [min]="dateFilterData.dateFrom"
                [(ngModel)]="dateFilterData.dateTo"
                (dateChange)="dateUpdate()"
                      >
              </mat-date-range-input>
              <mat-datepicker-toggle  matPrefix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="ag-bg-light ag-t-dark ag-p-none ">
    <div>&nbsp;</div>
    <atom-widget-list-scroll (onNewDataNeed)="onListScroll()" [isLoading]="bLoading" [isRollLoading]="isRollLoading" [bShowExpandButton]="aReceiptsList.length > 3" [bShowList]="aReceiptsList.length > 0">
      <app-one-receipt agList *ngFor="let oReceipt of aReceiptsList; last as bHideBorder" [oReceipt]="oReceipt" [sCurrency]="sCurrency" [bIsSmallSize]="bIsSmallSize" [bHideBorder]="bHideBorder"></app-one-receipt>
    </atom-widget-list-scroll>
  </div>
</div>
