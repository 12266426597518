import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header-public",
  templateUrl: "./header-public.component.html",
  styleUrls: ["./header-public.component.scss"],
})
export class HeaderPublicComponent implements OnInit {
  public landingLink = environment.landing;
  public stats = [
    {
      fieldName: "Total Tokens",
      icon: "total_tokens.svg",
      value: 123,
      isToken: true,
    },
    {
      fieldName: "Total Spend",
      icon: "total_spend.svg",
      value: 321,
      isAmount: true,
    },
    { fieldName: "My Publishers", icon: "publishers.svg", value: 1234 },
    { fieldName: "My Receipts", icon: "my_receipts.svg", value: 246 },
    { fieldName: "My Items", icon: "items.svg", value: 369 },
  ];
  constructor(private router: Router) {}

  returnToLogin() {
    this.router.navigateByUrl("auth");
  }

  ngOnInit(): void {}
}
