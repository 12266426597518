import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit {
  @Input() id: string = Math.trunc(Math.random() * 1000).toString();
  @Input() maxSize = 5;
  @Input() useNumbers = true;
  @Output() pageChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
