import { MenuService } from './../../../_shared/services/menu.service';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  @Input() menuList = [];
  @Input() user;
  @Input() unreadMessages = 0;
  @Input() unreadSupport = 0;
  @Output() toggleEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  public isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  constructor(private router: Router,
              private authUserService: AuthUserService,
              public menuService: MenuService) { }

  ngOnInit() {
  }

  toSearch(searchData) {
    this.router.navigate(['reader/search'],  {queryParams: {searchData}});
    this.toggleEvent.emit();
  }

  logout() {
    this.toggleEvent.emit();
    this.authUserService.logout();
    this.router.navigateByUrl('auth');
  }

}
