<div *ngIf="message" class="content">
    <div  class="wrapper">
        <div class="header">
            <div class="head full-flex">
                <p class="topic flex"><span class="field-name">Subject: </span>{{message.title}}</p>
                <p class="date">{{message.date}}</p>
            </div>
            <p class="item flex mb-2">
                <span class="field-name"> Receipt:</span> <span class="bold pointer" (click)="openReceipt()">{{message.receiptNumber}}</span>
            </p>
            <p *ngIf="!isSent" class="item flex">
                <span class="field-name desktop"> From:</span> {{message.from}}
            </p>
            <p *ngIf="isSent" class="item flex">
                <span class="field-name desktop"> To:</span> {{message.to}}
            </p>
        </div>
        <div class="body">
            <textarea class="message-text" [innerHTML]="message.message" readonly></textarea>
            <div *ngIf="message.attachmentsList.length>0" class="attachments at-desktop">
                Attachments
                <div class="files flex mt-3">
                    <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5" *ngFor="let file of message.attachmentsList">
                        <div class="file dark">
                            <img  *ngIf='file.type=="image/jpeg" || file.type =="image/png"' [src]="file.imgLink" alt="">
                            <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/Messages/pdf.png" alt="">
                            <img *ngIf='file.type!="image/jpeg"
                                    && file.type !="image/png"
                                    && file.type !="application/pdf"' src="../../../../assets/images/Messages/document.png" alt="">
                            <span>{{file.fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <app-attachments-mobile class="at-mobile" *ngIf="message.attachmentsList.length>0" [list]='message.attachmentsList'></app-attachments-mobile>
        </div>
    </div>
    <div class="bottom full-flex">
        <button (click)="archiveEvent.emit(message)" class="btn btn-gray full-flex mobile">
            <img src="../../../assets/images/Messages/trash.png" alt="">
            <span *ngIf="!isTrash">To trash</span>
            <span *ngIf="isTrash">From trash</span>
        </button>
        <button (click)="reply()" class="btn btn-full shadow full-flex ml-6">
            <img src="../../../../assets/images/Messages/reply.png" alt=""> Reply
        </button>
    </div>
</div>
<div *ngIf="!message" class="empty">
    <img src="../../../../assets/images/Messages/click-message2.png" alt="">
</div>
