import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Subject, Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthUserService, UserData } from "../@auth/auth-user.service";
import { Router } from "@angular/router";

@Injectable()
export class WsService extends Socket {
  get connected(): boolean {
    return this.ioSocket?.connected as boolean;
  }

  get disconnected(): boolean {
    return this.ioSocket?.disconnected as boolean;
  }

  get id(): string {
    return this.ioSocket?.id as string;
  }

  private userSubscription: Subscription = null;
  public userApp: UserData = null;

  public wsId: string;

  private clientNavigationEvents: Subject<ClientNavigatedEvent> =
    new Subject<ClientNavigatedEvent>();
  public $clientNavigationEvents = this.clientNavigationEvents.asObservable();
  public lastClientNavigationEvent: ClientNavigatedEvent = null;

  private entityDetailsEvents: Subject<EnteredDetailsEvent> =
    new Subject<EnteredDetailsEvent>();
  public $entityDetailsEvents = this.entityDetailsEvents.asObservable();
  public lastEntityDetailsEvents: EnteredDetailsEvent = null;

  private currencyDetailsEvents: Subject<CurrencyChangedEvent> =
    new Subject<CurrencyChangedEvent>();
  public $currencyDetailsEvents = this.currencyDetailsEvents.asObservable();
  public lastcurrencyDetailsEvents: CurrencyChangedEvent = null;

  public lastUrl = '';

  constructor(public authService: AuthUserService, private router: Router) {
    super({
      url: environment.wsUrl,
      options: {
        query: {
          userType: "dashboard",
        },
        autoConnect: false,
        transports: ["websocket", "polling"],
        upgrade: true,
      },
    });

    this.wsId = `${this.randomAlphaNumeric()}-${this.randomAlphaNumeric()}-${this.randomAlphaNumeric()}-${this.randomAlphaNumeric()}`;

    this.userApp = this.authService.getUser();
    this.userSubscription = this.authService.$user.subscribe((x) => {
      this.setAuthorization(x?.token, x?.uid);
      this.userApp = x;
    });

    this.$clientNavigationEvents.subscribe((event) => {
      this.lastClientNavigationEvent = event;
    });

    this.$entityDetailsEvents.subscribe((event) => {
      this.lastEntityDetailsEvents = event;
    });

    this.on("connection", () => {
      console.log("NEW CONNECTION");
    });

    this.on("navigate_to_receipt", (receiptDetails) => {
      const baseRoute =
        authService.getUserType() === "company" ? "publisher" : "reader";
      this.router.navigateByUrl(`/${baseRoute}/receipt/${receiptDetails.uid}`);
    });

    this.setCommonId(this.wsId);
    this.setAuthorization(this.userApp?.token, this.userApp?.uid);

    this.on(Events.ClientNavigated, (data: ClientNavigatedEvent) => {
      console.log(
        `[SOCKETS] - ${Events.ClientNavigated} DASHBOARD and data`,
        data,
        this.router.url
      );
      this.clientNavigationEvents.next(data);
    });

    this.on(Events.EntityDetails, (data: EnteredDetailsEvent) => {
      console.log(`[SOCKETS] - ${Events.EntityDetails} DASHBOARD`, data);
      this.entityDetailsEvents.next({ ...data });
    });

    this.on(Events.NewCommonIdClient, (data: NewClientEvent) => {
      console.log(`[SOCKETS] - ${Events.NewCommonIdClient} DASHBOARD`, data);
      this.emit('route_changed', this.lastUrl);
    });

    this.on(Events.CurrencyChanged, (data) => {
      console.log("CLIENT CURRENCY CHANGED", data);
      this.currencyDetailsEvents.next(data);
    });
  }

  setCommonId(id: string) {
    this.emit(Commands.CommonIdSet, id);
  }

  setAuthorization(token: string, userId: string) {
    this.emit(Commands.Authorized, token, userId);
  }

  routeChanged(route: string) {
    console.log("DASHBOARD ROUTE CHANGED");
    this.emit(Commands.RouteChanged, route);
  }

  connectToApi() {
    this.connect();
  }

  userUpdated() {
    this.emit(Commands.UserInfoFilled, {});
  }

  selectEntity(url: string, uid: string, entity: string) {
    const command: EnteredDetailsCommand = {
      url,
      uid,
      entity,
    };

    this.emit(Commands.DetailsEntered, command);
  }

  randomAlphaNumeric(): string {
    return Math.random().toString(36).slice(4);
  }
}

export interface EnteredDetailsCommand {
  url: string;
  entity: string;
  uid: string;
  id?: number;
}

export interface EnteredDetailsEvent extends EnteredDetailsCommand {
  clientType: LiveClientType;
}

export interface CurrencyChangedEvent {
  id: number;
}

export interface NewClientEvent {
  clientType: LiveClientType;
  sid: string;
}

export interface ClientNavigatedEvent {
  clientType: LiveClientType;
  url: string;
}

export enum Commands {
  Authorized = "authorized",
  CommonIdSet = "common_id_set",
  RouteChanged = "route_changed",
  ReceiptSelected = "receipt_selected",
  DetailsEntered = "details_entered",
  UserInfoFilled = "user_info_filled",
}

export enum Events {
  Connection = "connection",
  Disconnect = "disconnect",
  EntityDetails = "entity_details",
  NewCommonIdClient = "new_common_id_client",
  ClientNavigated = "client_navigated_to_page",
  CurrencyChanged = "client_currency_changed",
}

export enum LiveClientType {
  Dashboard = "Dashboard",
  Wallet = "Wallet",
}
