import { AuthUserService } from './../../@auth/auth-user.service';
import { ToastService } from './../../_shared/services/toast.service';
import { DictionariesService } from './../../_shared/dictionaries/dictionaries.service';
import { Injectable } from '@angular/core';
import { TooltipTypeReader, TooltipTypePublisher } from './tooltips.model';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  public tooltips$ = new ReplaySubject <TooltipTypeReader|TooltipTypePublisher> (1);

  constructor(private dictionariesService: DictionariesService,
              private toastService: ToastService,
              private authUserService: AuthUserService) {
    this.getTooltipsFromApi();
  }

  getTooltipsFromApi() {
    const subscription = new Subscription();
    subscription.add(this.dictionariesService.getTooltips(this.authUserService.getAccountType())
      .subscribe ((response: any) => {
        this.tooltips$.next(response.result);
        subscription.unsubscribe();
      }, (err) => {
        this.toastService.warning(err?.error?.errors[0]?.message);
    }));
  }

}
