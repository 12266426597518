import { AuthUserService } from "src/app/@auth/auth-user.service";
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnChanges,
  ViewChild,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { IOldPagination } from "src/app/_shared/interfaces/old-pagination.model";
import { MatPaginator } from "@angular/material/paginator";
import { convertPagination } from "src/app/_shared/utils";

@Component({
  selector: "app-select-table",
  templateUrl: "./select-table.component.html",
  styleUrls: ["./select-table.component.scss"],
})
export class SelectTableComponent implements OnInit, OnChanges {
  @ViewChild("empTbSortWithObject") sort = new MatSort();
  @ViewChild("paginator") paginator: MatPaginator;
  @Input() data: any = [];
  @Input() useBlueIcon: boolean = false;
  @Input() useNumbers: boolean;
  @Input() isLockTable: boolean;
  @Input() isEmptyImgHidden: boolean;
  @Input() oPagination: IOldPagination = {
    page: 0,
    pageSize: 0,
    totalRows: 0,
  };
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedRows: (event: any) => any;
  @Input() selectedRowsList = [];
  @Input() clearSelected: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  @Input() extraBtn: string;
  @Input() extraBtn2: string;
  @Input() selectOff: boolean;
  @Input() customHeight: number;
  @Input() paginationData;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  @Input() activeRowIndex: number;
  @Input() isCustomPaginationUsed: boolean;
  @Output() paginationUpdate = new EventEmitter();
  @Output() btnClicked = new EventEmitter();
  @Output() btnClicked2 = new EventEmitter();
  @Output() elementClicked = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  public dataSource: any;
  public displayedColumns: string[] = [];
  public pageFrom: number;
  public pageTo: number;
  public sortInfo = {};
  selection = new SelectionModel(true, []);
  public currency = "";
  @Input() tableName = "select-table";
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  constructor(private authUserService: AuthUserService, private changeDetectorRefs: ChangeDetectorRef) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit() {
    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }

  updatePagination(data, type) {
    this.paginationUpdate.emit({ data, type });
  }

  elementClick(columnName, row) {
    this.elementClicked.emit({ columnName, row });
  }

  textFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onMatSortChange() {
    if (this.isCustomPaginationUsed) {
      this.dataSource.sort = this.sort;
      console.log("DIR", this.sort.direction);
      if (this.sort.direction) {
        this.dataSource.data = this.dataSource.sortData(
          [...this.data],
          this.dataSource.sort
        );
      } else {
        this.dataSource.data = [...this.data];
      }

      Object.keys(this.sortInfo).forEach((p) => (this.sortInfo[p] = ""));
      this.sortInfo[this.sort.active] = this.sort.direction;
      console.log('THIS SORT INFO', this.sortInfo);
      this.createPaginationSlices();
      this.convertDataToPagination();
      return;
    }
    this.dataSource.sort = this.sort;
    Object.keys(this.sortInfo).forEach((p) => (this.sortInfo[p] = ""));
    this.sortInfo[this.sort.active] = this.sort.direction;
  }
  ngOnChanges() {
    this.clearSelected.subscribe((event: any) => {
      this.selection.clear();
    });
    const columns = [];
    if (!this.selectOff) {
      columns.push("select");
    }
    if (this.useNumbers) {
      columns.push("number");
    }
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    if (this.extraBtn2) {
      columns.push("btn2");
    }
    if (this.extraBtn) {
      columns.push("btn");
    }
    this.dataSource = new MatTableDataSource([...this.data]);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "productDescription": {
          if (typeof item[property] === "object")
            return item[property].productName;
          return item[property];
        }
        case "id": {
          if (item.sku) {
            return item.sku;
          }
          return item[property];
        }
        default:
          return item[property];
      }
    };
    this.displayedColumns = columns;
    columns.forEach((column) => {
      this.sortInfo[column] = "";
    });
    this.selection = new SelectionModel(true, [...this.selectedRowsList]);
    this.dataSource.paginator = this.paginator;
    !this.usePagination ? (this.dataSource.sort = this.sort) : null;
    if (this.isCustomPaginationUsed) {
      this.createPaginationSlices();
      this.convertDataToPagination();
    }

    this.changeDetectorRefs.detectChanges();
  }

  onCustomPaginationClick(iPageNumber: number) {
    this.oPagination.page = iPageNumber;
    this.createPaginationSlices();
    this.dataSource.data = this.dataSource.sortData(
      [...this.data],
      this.dataSource.sort
    );
    this.convertDataToPagination();
  }
  convertDataToPagination() {
    this.dataSource.data = this.dataSource.data.slice(
      this.pageFrom,
      this.pageTo
    );
  }
  createPaginationSlices() {
    const pag = convertPagination(this.oPagination);
    this.pageFrom = +pag.rowsFrom - 1;
    this.pageTo = +pag.rowsTo;
  }
}
