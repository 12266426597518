import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private httpClient: HttpClient) {}

  createTicket(userUid: string, message) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userUid}/tickets`, message);
  }

  getTicketList(userUid: string, category: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/tickets-list`,
    {params: {category, rowsFrom:'1', rowsTo:'100'}});
  }

  getTicket(ticketNumber: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/tickets/${ticketNumber}`);
  }

  changeStatus(ticketNumber: string, isOpen: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/tickets/${ticketNumber}`, isOpen);
  }

  changeReadStatus(ticketNumber: string, isRead: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/tickets-read/${ticketNumber}`, isRead);
  }

  getUnreadCount(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/tickets/count-unread`);
  }

}
