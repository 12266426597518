import { UsersService } from './../_shared/services/rest-services/users.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { of as observableOf, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const KEY = 'receiptium_auth_user';
export interface UserData {
  token: string;
  uid: string;
  login: string;
  firstName: string;
  surname: string;
  currency: string;
  typeOfUser: string;
  avatarUid: string;
  isCMS: boolean;
  isReader: any;
  isPublisher: any;
  integrationId: string;
  isAllFieldFilled: boolean;
}
@Injectable()
export class AuthUserService {
  private authenticated = false;
  private token = '';
  private userData: UserData = {
    token: '',
    uid: '',
    login: '',
    firstName: '',
    surname: '',
    currency: '',
    typeOfUser: '',
    avatarUid: '',
    integrationId: '',
    isCMS: false,
    isReader: false,
    isPublisher: false,
    isAllFieldFilled: false,
  };

  private userSubject = new Subject<UserData>();
  public $user = this.userSubject.asObservable();

  constructor(private authService: AuthService,
              public usersService: UsersService) {
    try {
      const user = JSON.parse(localStorage.getItem(KEY));
      this.setUser(user);
    } catch (error) {}
  }

  public login(data: any): Observable<object> {
    return this.authService.authenticate(data)
      .pipe(
        switchMap((result: any) => {
          const user = result.result;
          user.token = result.result.token;
          user.message = result.message;
          user.isAllFieldFilled = false;
          return this.setUser(user, true);
        })
      );
  }

  public loginByToken(token: string): Observable<object> {
    return this.authService.authenticateByToken(token)
      .pipe(
        switchMap((result: any) => {
          const user = result.result;
          user.token = result.result.token;
          user.message = result.message;
          user.isAllFieldFilled = false;
          return this.setUser(user, true);
        })
      );
  }

  private setUser(user: any, isAlreadyLogin?: boolean) {
    localStorage.setItem(KEY, JSON.stringify(user));
    this.token = user.token;
    this.userData = {
      token: user.token,
      uid: user.uid,
      login: user.login,
      firstName: user.firstName,
      surname: user.surname,
      currency: user.currency,
      typeOfUser: user.typeOfUser,
      avatarUid: user.avatarUid,
      isCMS: user.accountType === 'Admin',
      isPublisher: user.isPublisher,
      isReader: user.isReader,
      integrationId: user.integrationId,
      isAllFieldFilled: false
    };

    this.userSubject.next(this.userData);
    this.authenticated = true;
    return observableOf(user);
  }

  public checkIsAllFieldFilled() {
    const user = JSON.parse(localStorage.getItem(KEY));
    this.usersService.getUserDetailsCompliteStatus(this.userData.uid)
      .subscribe ((response: any) => {
        this.userData.isAllFieldFilled = response.isAllFieldFilled ? true : false;
        user.isAllFieldFilled = this.userData.isAllFieldFilled;
        localStorage.setItem(KEY, JSON.stringify(user));
    });
  }

  public getUser() {
    return this.userData;
  }

  public getUserType() {
    return this.userData.typeOfUser;
  }

  public getUserCurrency() {
    return this.userData.currency;
  }

  public logout() {
    localStorage.removeItem(KEY);
    this.authenticated = false;
    this.token = '';
    this.userSubject.next(null);
  }

  public getToken() {
    return this.token;
  }

  public isAdmin() {
    return this.userData.isCMS;
  }

  public isAuthenticated() {
    return this.authenticated;
  }

  public getUserUid() {
    return this.userData.uid;
  }

  public isPublisher() {
    return this.userData.isPublisher;
  }

  public getAccountType(): AccountTypeEnum  {
    if (this.userData?.isReader) {
      return AccountTypeEnum.READER;
    } else if (this.userData?.isPublisher) {
      return AccountTypeEnum.PUBLISHER;
    }
    return null;
  }

  public isAllFieldFilled() {
    return this.userData.isAllFieldFilled;
  }


}

export enum AccountTypeEnum {
  READER = 'Reader',
  PUBLISHER = 'Publisher'
}
