<div class="content">
    <button class="btn-icon close" (click)="this.dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h1  mat-dialog-title>
        <span *ngIf="!isReply">New ticket</span>
        <span *ngIf="isReply">Reply <img src="../../../../assets/images/Messages/reply-gray.svg" alt=""></span>
    </h1>
    <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content>
            <p>Subject</p>
            <mat-form-field class="title" floatLabel="never" appearance="outline">
                <textarea matInput
                    cdkTextareaAutosize
                    placeholder="Please add some subject"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"
                    formControlName ="title"
                    name="title">
                </textarea>
            </mat-form-field>
            <p>Message</p>
            <mat-form-field floatLabel="never" appearance="outline">
                <textarea matInput
                    cdkTextareaAutosize
                    placeholder="Please describe your problem"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="10"
                    cdkAutosizeMaxRows="15"
                    formControlName ="message"
                    name="message">
                </textarea>
            </mat-form-field>
        </div>
        <div mat-dialog-actions class="bottom full-flex" >
            <div class="attachments">
                <div class="files-list flex mr-3">
                    <div [matMenuTriggerFor]="menu"
                         #menuTrigger="matMenuTrigger"
                         [matMenuTriggerData]="{pickedFile:i}"
                         class="file flex ml-1 mb-1"
                         *ngFor="let file of files; index as i">
                        <mat-icon>description</mat-icon>
                        <span class="file-name">{{file.name}}</span> <span class="file-size">({{file.size*0.001 | number : '1.0-0'}} kb)</span>
                    </div>
                </div>
                <div class="add-btn"  (click)='clickInput()'>
                    <input  hidden
                            type="file"
                            #FileInput
                            (change)="fileSelected($event.target.files)"
                            name="files"
                            multiple>
                    <button class="btn btn-gray">
                    <img src="../../../../assets/images/Messages/attach.svg" alt="">  Add Attachment
                    </button>
                </div>
            </div>
            <button *ngIf="!isReply" (click)="send()" [disabled]='form.invalid' class="btn btn-green shadow ml-6">
                <img src="../../../../assets/images/Messages/send.svg" alt="">Create ticket
            </button>
            <button *ngIf="isReply" (click)="send()" [disabled]='form.invalid' class="btn btn-full shadow reply flex-center ml-6">
                <img src="../../../../assets/images/Messages/reply.png" alt="">Reply
            </button>
        </div>
    </form>
</div>

<mat-menu #menu="matMenu" yPosition="above">
    <ng-template matMenuContent let-indexOfFile="pickedFile">
        <div class="popover-container flex">
            <button class="btn-icon" (click)="deleteFile(indexOfFile)">
                <mat-icon>clear</mat-icon>
                <span>delete</span>
            </button>
        </div>
    </ng-template>
</mat-menu>
