<div class="content">
    <div class="header full-flex">
        <div class="left full-flex">
            <button class="btn-icon"><mat-icon>settings</mat-icon></button>
            <p>You have {{listCount}} tickets</p>
        </div>
        <div class="right full-flex">
            <button class="btn-icon"><mat-icon>first_page</mat-icon></button>
            <button class="btn-icon"><mat-icon class="arrow">arrow_left</mat-icon></button>
            <input class="flex-center" value="1" min="1" type="number">
            <button class="btn-icon"><mat-icon class="arrow">arrow_right</mat-icon></button>
            <button class="btn-icon"><mat-icon>last_page</mat-icon></button>
        </div>
    </div>
    <button (click)="newTicketEvent.emit()" class="btn btn-green shadow new-ticket">
        New Ticket
    </button>
    <div *ngIf="list.length>0" class="tickets-wrapp desktop">
        <div *ngFor="let item of list" class="ticket" [ngClass]="{'readed': item.isRead, 'replied': item.isReply, 'active' : (item.ticketNumber === (activeTicket? activeTicket.ticketNumber : null))}" (click)="clickItem(item)">
            <img *ngIf="item.isReply" class="reply" src="../../../../assets/images/Messages/reply-gray.svg" alt="">
            <div class="head full-flex">
                <p class="topic">Ticket# {{item.ticketNumber}}</p>
                <p class="date">{{item.sendDate}}</p>
            </div>
            <p class="subject">
                {{item.title}}
            </p>
        </div>
    </div>
    <div class="tickets-wrapp mobile" *ngIf="list.length>0">
        <sw-item-list
                *ngFor="let item of list"
                [inside]="item"
                [editTemplate]="replyTemplate"
                [trashTemplate]="closeTicketTemplate"
                (swClick)="clickItem(item)"
                (callback)="doAction($event, item)"
                [item-class]="'ticket-sw'"
                [show-mark]="false"
                [disable-mark]="isClosed?true:false"
                [customTemplate]="itemTemplate">
        </sw-item-list>
    </div>
    <ng-template #replyTemplate>
        <button class="btn btn-full full-flex shadow reply">
            <img src="../../../assets/images/Messages/reply.png" alt="">
        </button>
    </ng-template>
    <ng-template #closeTicketTemplate>
        <button class="btn btn-gray full-flex">
            <img src="../../../assets/images/Messages/close.svg" alt="">
        </button>
    </ng-template>
    <ng-template #itemTemplate let-item='item' let-id='id'>
        <div class="ticket" [ngClass]="{'readed': item.isRead, 'replied': item.isReply, 'active' : (item === activeTicket)}">
            <img *ngIf="item.isReply" class="reply" src="../../../../assets/images/Messages/reply-gray.svg" alt="">
            <div class="head full-flex">
                <p class="topic">Ticket# {{item.ticketNumber}}</p>
                <p class="date">{{item.sendDate}}</p>
            </div>
            <p class="subject">
                {{item.title}}
            </p>
        </div>
    </ng-template>
    <div *ngIf="list.length===0" class="empty">
        <img src="../../../../assets/images/Messages/ticket.png" alt="">
        <h1 >No tickets yet</h1>
    </div>
</div>