import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterModule, Routes } from "@angular/router";
import { PagesPublicComponent } from "./pages-public.component";
import { TransactionsComponent } from "./transaction/transactions/transactions.component";

const routes: Routes = [
  {
    path: "",
    component: PagesPublicComponent,
    children: [
      {
        path: "transactions",
        component: TransactionsComponent,
      },
      { path: '', redirectTo: 'transactions', pathMatch: 'full'}
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesPublicRoutingModule {}
