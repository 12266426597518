<pagination-template #p="paginationApi" class="content full-flex" [maxSize]='maxSize'
                     (pageChange)="pageChange.emit($event)" [id]="id">

        <button [disabled]="p.isFirstPage()" class="btn-square gray left" (click)="p.previous()">
          <img src="../../../assets/images/New design/left-arrow.svg" alt="left arrow">
        </button>

        <ng-container *ngIf="useNumbers">
          <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
            <button class="btn-square" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              <span>{{ page.label }}</span>
            </button>
            <button class="btn-square active" *ngIf="p.getCurrent() === page.value">
              <span>{{ page.label }}</span>
            </button>
        </div>
        </ng-container>

        <button [disabled]="p.isLastPage()" class="btn-square gray right"  (click)="p.next()">
          <img src="../../../assets/images/New design/right-arrow.svg" alt="left arrow">
        </button>

</pagination-template>
