import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { Router } from '@angular/router';
import { MessagesService } from './../../../_shared/services/rest-services/messages.service';
import { Subscription } from 'rxjs';
import { AvatarsService } from './../../../_shared/services/rest-services/avatars.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { getServerLink } from 'src/app/_shared/utils';

@Component({
  selector: 'app-read-message',
  templateUrl: './read-message.component.html',
  styleUrls: ['./read-message.component.scss']
})
export class ReadMessageComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  @Input() message: any;
  @Input() isSent: boolean;
  @Input() isTrash: boolean;
  @Output() replyEvent = new EventEmitter();
  @Output() archiveEvent = new EventEmitter();
  public isPublisher: boolean;

  constructor(public avatarsService: AvatarsService,
              public router: Router,
              private authUserService: AuthUserService,
              private messagesService: MessagesService) { }

  ngOnInit(): void {
    this.isPublisher = this.authUserService.isPublisher();
  }

  reply() {
    this.replyEvent.emit();
  }

  openReceipt() {
    if (!this.isPublisher) {
      this.router.navigate(['reader/receipt', this.message.receiptUid]);
    } else {
      this.router.navigate(['publisher/receipt', this.message.receiptUid]);
    }
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.messagesService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      })
    );
  }


}
