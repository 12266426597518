<app-page class="page">
    <app-page-header class="header">
        <span class="title" [ngClass]="{'hide': activeTicket}">{{pageName}}</span>
        <div class="flex mobile" [ngClass]="{'hide': !activeTicket}">
            <button (click)="activeTicket=''" class="btn-icon">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <span class="title">All Tickets</span>
        </div>
        <div class="btn-wrapp">
            <ng-container *ngIf="activeTicket&&selectedIndexTab===0">
                <button (click)="reply()" class="btn btn-full shadow full-flex ml-6">
                    <img src="../../../../assets/images/Messages/reply.png" alt=""> Reply
                </button>
                <button (click)="closeTicket()" class="btn btn-gray mr-5 ml-5">Close Ticket</button>
            </ng-container>
            <button (click)="newTicket()" class="btn btn-green shadow">New Ticket</button>
        </div>
    </app-page-header>
    <app-page-body class="page-body">
        <mat-tab-group [selectedIndex]='selectedIndexTab' (selectedIndexChange)="selectedIndex($event)" class="cards-menu">
            <mat-tab>
                <ng-template class="tab" mat-tab-label>
                    <span>Open Tickets</span>
                    <div *ngIf="unreadCount>0"  class="count flex-center">{{unreadCount}}</div>
                </ng-template>
                <aside class="grid-wrapper grid-gap-0">
                    <div class="box start-1 end-5">
                        <app-support-left-menu [ngClass]="{'hide': activeTicket}" (closeEvent)='closeTicket($event)' (replyEvent)='reply($event)' (newTicketEvent)='newTicket()' (clickItemEvent)='openTicket($event, !$event.isRead)' [activeTicket]='activeTicket' [list]='ticketsList' [listCount]='ticketsCount'></app-support-left-menu>
                    </div>
                    <div class="box start-5 end-13">
                        <app-read-ticket [ngClass]="{'hide': !activeTicket}" (replyEvent)='reply()' (closeEvent)='closeTicket()' [ticket]='activeTicket'></app-read-ticket>
                    </div>
                </aside>
            </mat-tab>
            <mat-tab>
                <ng-template class="tab" mat-tab-label>
                    <span>Closed Tickets</span>
                </ng-template>
                <aside class="grid-wrapper">
                    <div class="box start-1 end-5">
                        <app-support-left-menu [ngClass]="{'hide': activeTicket}" [isClosed]='true' (closeEvent)='closeTicket($event)' (replyEvent)='reply($event)' (newTicketEvent)='newTicket()'  (clickItemEvent)='openTicket($event, !$event.isRead)' [activeTicket]='activeTicket' [list]='ticketsList' [listCount]='ticketsCount'></app-support-left-menu>
                    </div>
                    <div class="box start-5 end-13">
                        <app-read-ticket [ngClass]="{'hide': !activeTicket}" (replyEvent)='reply()' (closeEvent)='closeTicket()' [isClosed]='true' [ticket]='activeTicket'></app-read-ticket>
                    </div>
                </aside>
            </mat-tab>
        </mat-tab-group>
    </app-page-body>
</app-page>
