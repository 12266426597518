import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUserHeaderReaderInfo(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/header-info/${uid}`);
  }
  getHeaderPublisherInfo(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/header-publisher-info/${uid}`);
  }

  getUserData(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/getuserDetails/${uid}`);
  }

  getUserPrivacy(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/terms-and-privacy`);
  }

  updateUserPrivacy(userUid: string, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/terms-and-privacy`, data);
  }

  getUserDetailsCompliteStatus(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/is-user-details-complited`);
  }

  getQRCode(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${uid}/qrCode`);
  }

  getBarcode(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${uid}/barcode`);
  }

  setUserData(uid: string, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/userDetails/${uid}`, data);
  }

  getUserNotes(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/notes`);
  }

  saveUserNote(userUid: string, noteUid: string, note: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/notes/${noteUid}`, note);
  }

  deleteUserNote(userUid: string, noteUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/users/${userUid}/notes/${noteUid}`);
  }

  addUserNote(userUid: string, note: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userUid}/notes`, note);
  }

  setUserAvatar(uid: string, avatar: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${uid}/avatar-user`, avatar);
  }

  setCompanyAvatar(uid: string, avatar: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${uid}/avatar-user?company=true`, avatar);
  }

  checkUsernameValidation(userUid: string, username: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/check-username/${userUid}?username=${username}`);
  }

  getBranchList(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/publisher/${userUid}/branches`);
  }

  addBrach(userUid: string, branch: any) {
    return this.httpClient.post(`${environment.apiUrl}/publisher/${userUid}/branches`, branch);
  }

  editBrach(branchUid: string, branch: any) {
    return this.httpClient.put(`${environment.apiUrl}/publisher/branches/${branchUid}`, branch);
  }

  deleteBrach(branchUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/publisher/branches/${branchUid}`);
  }

  getUsersList(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/publisher/${userUid}/workers`);
  }

  addUser(userUid: string, user: any) {
    return this.httpClient.post(`${environment.apiUrl}/publisher/${userUid}/workers`, user);
  }

  editUser(workerUid: string, user: any) {
    return this.httpClient.put(`${environment.apiUrl}/publisher/workers/${workerUid}`, user);
  }

  deleteUser(workerUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/publisher/workers/${workerUid}`);
  }

  getPublisherStores(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/dashboard/store-location-publisher`);
  }

  editPrimaryEmail(userUid: string, data: {newPrimaryEmail: string}) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/update-primary-email`, data);
  }

  editSecondaryEmail(userUid: string, data: {newSecondaryEmail: string}) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/update-secondary-email`, data);
  }

}
