import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AuthUserService } from "src/app/@auth/auth-user.service";
import { CurrencyService } from "src/app/_services/currency.service";
import { IOldPagination } from "src/app/_shared/interfaces/old-pagination.model";
import { HttpTransactionsService } from "src/app/_shared/services/http-services/public/http-transactions.service";
import { convertPagination } from "src/app/_shared/utils";
import { ILinearHorizontalChartData } from "../../components/linear-chart-card/linear-chart-card.component";
import { chartDaily, chartMonthly, chartYearly } from "./sampledata";
const DEBOUNCE_TIME = 800;
@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  //Upper Widget
  txIn: number = 0;
  txOut: number = 0;
  txTotal: number = 0;
  currCurrencyName: string = "";
  currCurrencyId: number;
  walletBalance: string = "0";
  subscriptions: number = 0;
  subscribtionsRGBP: number = 0;
  //Filters
  defaultDateTo = new Date();
  defaultDateFrom = new Date("01/01/2019");

  searchChanged: Subject<boolean> = new Subject<boolean>();
  searchString: string = "";
  dateTo: Date = new Date();
  dateFrom: Date = new Date("01/01/2019");
  txDesc: string = "";
  txId: number | string = "";
  txCode: number | string = "";
  amount: number | string = "";
  currAmount: number | string = "";
  txFees: number | string = "";
  from: string = "";
  to: string = "";
  //tableThings
  isEmptyRes: boolean = false;
  data: Transaction[] = [];
  tableList: Transaction[] = [];
  showAdvancedFilters: boolean = false;
  isLoading: boolean = true;
  oPagination: IOldPagination = {
    page: 1,
    pageSize: 10,
    totalRows: 0,
  };
  //Chart
  chartSelectorValue: string = "monthly";
  chartData: ILinearHorizontalChartData[] = [];

  //Cols
  tableColumns = [
    { fieldName: "invoiceDate", title: "Date" },
    { fieldName: "paymentMethod", title: "Tx Desc" },
    { fieldName: "txType", title: "Tx Type" },
    {
      fieldName: "tx",
      title: "Tx",
      extraValue: "customValue",
      valuePrepareFunction: (row) => {
        return row.amount > 0
          ? `<img src="../../../../assets/images/New design/bx-log-out-circle2.svg"/>`
          : `<img src="../../../../assets/images/New design/bx-log-out-circle.svg"/>`;
      },
    },
    { fieldName: "txId", title: "Tx ID" },
    {
      fieldName: "publicKeyFrom",
      title: "From",
      hoverWithName: true,
    },
    {
      fieldName: "publicKeyTo",
      title: "To",
      hoverWithName: true,
    },
    {
      fieldName: "amount",
      title: "Amount",
      extraValue: "customValue",
      valuePrepareFunction: (row) => {
        const currency =
          row.amount < 0 ? row.assetFromCurrency : row.assetToCurrency;
        return `<p class="table-amount-cell">${row.amountToDisplay} <span class="highlight-currency-blue">${currency}</span></p>`;
      },
    },
    {
      fieldName: "amountCurrValue",
      title: `Amount (${this.currCurrencyName})`,
      extraValue: "customValue",
      valuePrepareFunction: (row) => {
        const currency =
          row.amount < 0 ? row.assetFromCurrency : row.assetToCurrency;

        return `
        <p>${
          !!currency
            ? currency !== this.currCurrencyName
              ? Number(
                  this.calculateToCurr(row.amountToDisplay, currency)
                ).toFixed(2)
              : "-"
            : "-"
        }</p>
      `;
      },
    },
    { fieldName: "transactionFeeValue", title: "Tx Fee..." },
    {
      fieldName: "transactionReference",
      title: "Tx Ref",
      hoverWithName: true,
    },
    { fieldName: "txCode", title: "Tx Code" },
    {
      fieldName: "additionalInfo",
      extraValue: "customValue",
      title: " ",
      valuePrepareFunction: (row) => {
        return `
        <div class="ag-info-hover" style="30px;">
          <div class="info-sign">?</div>
          <div class="ag-info-hover-text ag-shadow-light">
            <h3>Additional Info</h3>
            <p class="ag-info-hover-date">${row.invoiceDate}, ${
          row.invoiceTime
        }</p>
            <div class="ag-info-cell">
              <b>From</b>: ${
                row.amount < 0 ? row.walletToName : row.walletFromName
              }<br/>
              <b>To</b>: ${
                row.amount < 0 ? row.walletFromName : row.walletToName
              }
            </div>
            <div class="ag-info-line"></div>
            <div class="ag-info-cell">
              <b>Network Fees</b>: ${row.networkFeeValue || "0"} ${
          this.currCurrencyName
        }<br/>
              <b>Transaction Fees</b>: ${row.transactionFeeValue || "0"} ${
          this.currCurrencyName
        }
            </div>
            <div class="ag-info-line"></div>
            <div class="ag-info-cell">
              <b>Total Amount</b>: ${row.transactionFeeValue || 0} ${
          this.currCurrencyName
        }<br/>
            </div>
          </div>
        </div>
      `;
      },
    },
  ];

  constructor(
    private publicTransactionsService: HttpTransactionsService,
    private currencyService: CurrencyService
  ) {
    this.searchChanged
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((model: boolean) => {
        this.load();
        this.loadSummary();
      });
  }

  ngOnInit(): void {
    this.load();
    this.loadSummary();
  }

  setCurrCurrency() {
    console.log("set curr currency");
    if (!this.currCurrencyName)
      this.subscription.add(
        this.currencyService.$currentCurrencyName.subscribe((currency) => {
          console.log("set curr currency 1", currency);
          this.currCurrencyName = currency;
          this.changeColumnCurrencyValues();
        })
      );
    this.subscription.add(
      this.currencyService.$currentCurrencyId.subscribe((currency) => {
        console.log("set curr currency 2", currency);
        this.currCurrencyId = +currency;
        this.loadSummary();
      })
    );

    this.currCurrencyName = this.currencyService.getCurrentName();
    //else default to RGBP
    console.log("pre defaulting", this.currCurrencyName, this.currCurrencyId);
    if (!this.currCurrencyName) {
      console.log("defaulting");
      this.currCurrencyName = "RGBP";
      this.currCurrencyId = 60;
    }
    this.changeColumnCurrencyValues();
    console.log("CURRENCY", this.currCurrencyName);
  }
  changeColumnCurrencyValues() {
    this.tableColumns = this.tableColumns.map((column) => {
      if (column.fieldName === "amountCurrValue") {
        column.title = `Amount(${this.currCurrencyName})`;
        return column;
      }
      return column;
    });
  }
  prepareChartData(responseData) {
    console.log("RESDATA", responseData);
    if (!responseData) return;
    const amount =
      this.chartSelectorValue === "yearly"
        ? 5
        : this.chartSelectorValue === "monthly"
        ? 12
        : 7;
    const slicedArr = responseData.slice(-amount);
    console.log("SLICED", slicedArr, amount);
    const newChartData = [
      {
        name: this.chartSelectorValue,
        series: slicedArr.map((entry) => {
          return {
            name: entry.name,
            value: entry.amount,
          };
        }),
      },
    ];
    this.chartData = newChartData;
    console.log("NEW CHART DATA", newChartData);
  }
  loadSummary() {
    const date = new Date();
    console.log("DATE", date.toISOString());
    // date.setDate(date.getDate() - 7);

    this.subscription.add(
      this.publicTransactionsService
        .getPublicTransactionsStatistics(
          this.createQueryParams() +
            "&count=30" +
            `&type=${this.chartSelectorValue}` +
            `&startDate=${date.toISOString().split("T")[0]}`
        )
        .subscribe((response: any) => {
          const result = response.result;
          console.log("STATISCTCS RESPONSE", response);

          // this.chartData = result.analytics;
          this.prepareChartData(result.analytics);
        })
    );
  }
  load(sortParams: string = "") {
    this.isLoading = true;
    this.tableList = [];
    this.subscription.add(
      this.publicTransactionsService
        .getPublicTransactionsInfo(sortParams, this.createQueryParams())
        .subscribe((response: TransactionResponse) => {
          console.log("INFO RESPONSE", response);
          this.data = response.result.list.map((x) => {
            if (x.paymentMethod === "internal") {
              if (x.amount < 0) {
                x.txType = "Send";
              } else {
                x.txType = "Receive";
              }
            } else {
              x.txType = "Buy";
            }

            const from = x.publicKeyFrom;
            const to = x.publicKeyTo;

            if (x.amount < 0) {
              x.publicKeyTo = from;
              x.publicKeyFrom = to;
            }

            x.amountToDisplay = Math.abs(x.amount);

            return x;
          });
          this.txIn = response.result.totalIn;
          this.txOut = response.result.totalOut;
          this.txTotal = response.result.totalTx;
          this.oPagination.totalRows = response.result.list.length;
          this.transactionsPageChange(1);
          this.setCurrCurrency();
          this.isLoading = false;
        })
    );
  }
  createQueryParams() {
    return `amount=${this.amount ?? ""}&paymentIdOrderBy=${
      this.txId ?? ""
    }&dateFrom=${
      this.dateFrom
        ? this.dateFrom.toISOString()
        : this.defaultDateFrom.toISOString()
    }&dateTo=${
      this.dateTo ? this.dateTo.toISOString() : this.defaultDateTo.toISOString()
    }&searchString=${this.searchString ?? ""}&from=${this.from ?? ""}&to=${
      this.to ?? ""
    }&txCode=${this.txCode ?? ""}&txDesc=${this.txDesc ?? ""}&txId=${
      this.txId ?? ""
    }&currAmount=${this.currAmount ?? ""}`;
  }
  transactionsPageChange(iPageNumber: number) {
    this.oPagination.page = iPageNumber;
    const pag = convertPagination(this.oPagination);
    this.tableList = [...this.data].slice(+pag.rowsFrom - 1, +pag.rowsTo);
  }
  sortChanged(queryString: string) {
    this.load(queryString);
  }

  calculateToCurr(amount: number, currencyFrom: string): number {
    if (!this.currCurrencyName) return;
    return (
      amount *
      this.currencyService.getExchangeRate(currencyFrom, this.currCurrencyName)
        .rate
    );
  }
  markAsChanged() {
    this.searchChanged.next(true);
  }
  toggleAdvancedFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters;
  }
  resetDatePicker() {
    this.dateFrom = this.defaultDateFrom;
    this.dateTo = this.defaultDateTo;
    this.load();
  }
  clearFilters() {
    this.searchString = "";
    this.txDesc = "";
    this.txId = "";
    this.txCode = "";
    this.amount = "";
    this.currAmount = "";
    this.txFees = "";
    this.from = "";
    this.to = "";
    this.resetDatePicker();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onChartSelectorClick(event) {
    this.chartSelectorValue = event.value;
    this.loadSummary();
  }
}
export interface Transaction {
  amount: number;
  amountToDisplay: number;

  transactionFeeValue: number | null;

  assetFromCurrency: string;
  assetToCurrency: string;

  invoiceDate: string;
  invoiceNumber: string;
  invoiceTime: string;

  paymentMethod: string;
  txType: string;
  smsTokenUid: string;
  status: string;
  transactionReference: string;

  walletFromName: string;
  walletFromUid: string;

  walletTo: string;
  walletToName: string;
  walletUid: string;

  publicKeyFrom: string;
  publicKeyTo: string;

  copiedFrom?: boolean;
  copiedTo?: boolean;

  copiedFromTimeout?: any;
  copiedToTimeout?: any;
}
export interface TransactionResponse {
  message: string;
  result: {
    list: Transaction[];
    totalIn: number;
    totalOut: number;
    totalTx: number;
    unpaidNumber: number;
  };
}
