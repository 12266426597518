<div *ngIf="isLoading && !bShowList" class="ag-flex-align-center">
  <app-loader></app-loader>
</div>

<div *ngIf="!isLoading && !bShowList" class="ag-flex-align-center">
  <app-empty></app-empty>
</div>

<div *ngIf="bShowList" >
  <div *ngIf="bShowList" class="m-scroll" (scroll)="onWindowScroll($event)" [@onSectionExpand]="isExpanded ? 'open' : 'close'">
    <ng-content select="[agList]"></ng-content>
    <mat-spinner [style.display]="isRollLoading? 'block' : 'none'" [diameter]="30" style="display: block; margin: 10px auto;"></mat-spinner>
  </div>
</div>

<atom-widget-button-expand [isExpanded]="isExpanded" (onExpandChange)="isExpanded = !isExpanded" [bShowButton]="bShowExpandButton"></atom-widget-button-expand>

