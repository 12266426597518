import { OldSortFilterPagination } from '../../_shared/interfaces/old-pagination.model';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, EventEmitter, OnChanges, ViewChild, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

const DEBOUNCE_TIME = 800;

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})

export class FilterTableComponent implements OnInit, OnChanges {

  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() dateFilterData;
  @Input() amountFilterData;
  @Input() ratingFilterData;
  @Input() paginationData: OldSortFilterPagination;
  @Input() autoInputData;
  @Input() amountSelect;
  @Input() autoInputSelect;
  @Input() filtersOff: boolean;
  @Input() filtersFlexStyle: boolean = true;
  @Input() selectedRows: (event: any) => any;
  @Input() isLoading: boolean;
  @Input() autoSelect: boolean;
  @Output() dateFilter = new EventEmitter();
  @Output() amountFilter = new EventEmitter();
  @Output() ratingFilter = new EventEmitter();
  @Output() autoInputFilter = new EventEmitter();
  @Output() paginationUpdate = new EventEmitter();
  @Output() anyUpdate = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions = [10, 20, 50, 100];
  public searchChanged: Subject<any> = new Subject<any>();
  public dataSource: any;
  public displayedColumns: string[] = [];
  selection = new SelectionModel(true, []);
  public ratingFilterCount = 0;
  public currency = '';

  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
    this.searchChanged
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((model: any) => {
        this.updatePagination(model, 'filter');
    });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    if (this.usePagination && !this.paginationData.pageSize) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }
  updatePagination(data, type) {
    if (type === 'sort') {
      switch (data.active) {
        case 'companyAvatarLink':
          data.active = 'company';
          break;
        case 'select':
          data.active = 'status';
          break;
      }
      this.paginationData.orderBy = data.active;
      this.paginationData.orderDirection = data.direction; 
      this.paginationData.pageIndex = 0;
    } else if (type === 'page') {
      this.paginationData.pageIndex = data-1;
      // Only for old format emits
      data = {
        length: this.paginationData.totalItems,
        pageIndex: this.paginationData.pageIndex,
        pageSize: this.paginationData.pageSize
      }
      console.log(data);
    } else if (type === 'filter') {
      this.paginationData.filterValue = data;
      this.paginationData.pageIndex = 0;
    }
    this.paginationUpdate.emit({data, type});
    this.anyUpdate.emit();
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  textFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.usePagination) {
      this.searchChanged.next(filterValue );
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  dateUpdate(event) {
    this.paginationData.pageIndex = 0;
    this.dateFilter.emit(this.dateFilterData);
    this.anyUpdate.emit();
  }

  amountFilterUpdate(event) {
    if (this.amountFilterData.useFilter || event.checked === false) {
      this.paginationData.pageIndex = 0;
      this.amountFilter.emit(this.amountFilterData);
      this.anyUpdate.emit();
    }
  }

  rateUpdate(event) {
    event.rating ? this.ratingFilterData.rating = event.rating : null;
    if (this.ratingFilterCount > 0 && (this.ratingFilterData.useFilter || event.checked === false)) {
      this.paginationData.pageIndex = 0;
      this.ratingFilter.emit(this.ratingFilterData);
      this.anyUpdate.emit();
    }
    this.ratingFilterCount++;
  }

  autoInputUpdate(event) {
    this.autoInputFilter.emit(event);
  }

  openLink(link) {
    window.open(link, '_blank');
  }

  filterPredicate() {
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = '';
        Object.values(obj).forEach((val) => {
          val = val.toString();
          if (typeof val !== 'object') {
            if (val.toString().length !== 36 && !val.toString().includes('http')) {
              returnVal = returnVal + ' ' + val;
            }
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };
      return listAsFlatString(order).includes(transformedFilter);
    };
  }

  toAutoSelect() {
    if (this.autoSelect) {
      this.selection = new SelectionModel(true, []);
      for (const row of this.data) {
        row.isSelected ? this.toggleRow(row) : null;
      }
    }
  }


  ngOnChanges() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.filterPredicate();
    this.toAutoSelect();
    this.displayedColumns = columns;
    !this.usePagination ? this.dataSource.sort = this.sort : null;
  }

}
