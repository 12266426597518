export const menuList = [
    // [
    //   {
    //     displayName: 'Dashboard',
    //     iconName: 'dashboard.svg',
    //     route: `dashboard`
    //   },
    //   {
    //     displayName: 'Profile',
    //     iconName: 'profile.svg',
    //     route: `my-profile`
    //   },
    //   {
    //     displayName: 'Contacts',
    //     iconName: 'contacts.svg',
    //     route: `my-profilez`,
    //     disabled: true
    //   },
    // ],
    // [
    //   {
    //     displayName: 'Receipts',
    //     iconName: 'receipts.svg',
    //     route: `my-receipts`
    //   },
    //   {
    //     displayName: 'Invoices',
    //     iconName: 'invoices.svg',
    //     route: `my-profilez`,
    //     disabled: true
    //   },
    //   {
    //     displayName: 'Expenses',
    //     iconName: 'expenses.svg',
    //     route: `expenses`
    //   },
    //   {
    //     displayName: 'Wallet',
    //     iconName: 'wallet.svg',
    //     route: `my-profilez`,
    //     disabled: true
    //   },
    //   {
    //     displayName: 'Reviews',
    //     iconName: 'reviews.svg',
    //     route: `reviews`,
    //     disabled: true
    //   },
    // ],
    // [
    //   {
    //     displayName: 'Messages',
    //     iconName: 'messages.svg',
    //     route: `messages`
    //   },
    //   {
    //     displayName: 'Settings',
    //     iconName: 'settings.svg',
    //     route: `settings`,
    //     disabled: true
    //   },
    // ],
    // [
    //   {
    //     displayName: 'Support',
    //     iconName: 'support.svg',
    //     route: `support`
    //   }
    // ]
  ];
  
  export const NAVIGATION_SIDE_NAV_PUBLIC = [
    // {
    //   displayName: 'Dashboard',
    //   iconName: 'dashboard',
    //   route: `dashboard`
    // },
    // {
    //   displayName: 'Profile',
    //   iconName: 'profile',
    //   route: `my-profile`
    // },
    {
      displayName: 'Transactions',
      iconName: 'transactions',
      route: `transactions`
    },
    // {
    //   displayName: 'Receipts',
    //   iconName: 'receipts',
    //   route: `receipt`
    // },
    // {
    //   displayName: 'Favorites',
    //   iconName: 'favourites',
    //   route: `favourites`
    // },
    // {
    //   displayName: 'Subscriptions',
    //   iconName: 'subscription',
    //   route: `subscriptions`
    // },
    // {
    //   displayName: 'Reviews & Ratings',
    //   iconName: 'ratings',
    //   route: `reviews`
    // },
    // {
    //   displayName: 'Messages',
    //   iconName: 'messages',
    //   route: `messages`
    // },
    // {
    //   displayName: 'Support',
    //   iconName: 'support',
    //   route: `support`
    // }
  ];
  