<div class="content attachments">
    Attachments
    <div class="files flex mt-3">
        <owl-carousel-o class="carousel-multi" id="carousel" [options]="desktopOptions" #owlCar>
            <ng-container *ngFor="let file of list">
                <ng-template [width]="140" carouselSlide>
                    <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5">
                        <div class="file dark">
                            <img  *ngIf='file.type=="image/jpeg" || file.type =="image/png"' [src]="file.imgLink" alt="">
                            <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/Messages/pdf.png" alt="">
                            <img *ngIf='file.type!="image/jpeg"
                                    && file.type !="image/png"
                                    && file.type !="application/pdf"' src="../../../../assets/images/Messages/document.png" alt="">
                            <span>{{file.fileName}}</span>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>