import { Pagination, PaginationRequest } from './../../../_models/api.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReceiptsService {
  constructor(private httpClient: HttpClient) {}
  // FOR PUBLISHERS
  getPublisherReceipts(userUid: string, login?: string, dateFrom?: string, dateTo?: string, amountFilter?: string, rowsFrom?: number, rowsTo? :number, orderBy?: string, orderDirection?: string, filterValue?: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/receipts-list-publisher?dateFrom=${dateFrom}&dateTo=${dateTo}&login=${login}&amountFilter=${amountFilter}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&filterValue=${filterValue}`);
  }

  getReadersList(publisherUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${publisherUid}/publishers/users-list`);
  }
  // FOR READERS
  getFullCompaniesList(uid: string, sortBy: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${uid}/full-company-list?sortBy=${sortBy}`);
  }
  getReviews(userUid: string, sortBy: string, rowsFrom: number, rowsTo: number, companyUid?: string) {
    const companyUidQ = companyUid ? companyUid : '';
    return this.httpClient.get(`${environment.apiUrl}/users/reviews/${userUid}?sortBy=${sortBy}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&companyUid=${companyUidQ}`);
  }
  getReceiptsInfoByCompany(uid: string, companyUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${uid}/overall-receipts/${companyUid}`);
  }
  getReceiptsListByCompany(uid: string, companyUid: string, dateFrom?: string, dateTo?: string, rowsFrom?: number, rowsTo? :number, orderBy?: string, orderDirection?: string, filterValue?: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${uid}/receipts-list/${companyUid}?dateFrom=${dateFrom}&dateTo=${dateTo}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&filterValue=${filterValue}`);
  }
  getMyReceiptsHeader(uid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/my-receipts-header/${uid}`);
  }
  getMyReceiptsList(uid: string, dateFrom?: string, dateTo?: string, amountFilter?: string, ratingFilter?: string, rowsFrom?: number, rowsTo? :number, orderBy?: string, orderDirection?: string, filterValue?: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/my-receipts/${uid}?dateFrom=${dateFrom}&dateTo=${dateTo}&amountFilter=${amountFilter}&ratingFilter=${ratingFilter}&rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}&filterValue=${filterValue}`);
  }
  getMyItemsList(userUid: string, rowsFrom: number, rowsTo :number, dateFrom?: string, dateTo?: string, orderBy?: string, orderDirection?: string, filterValue?: string) {
    const dateFromQ = dateFrom ? dateFrom : (formatDate(new Date(new Date(2000, 0, 1)), 'yyyy/MM/dd', 'en'));
    const dateToQ = dateTo ? dateTo : (formatDate(new Date, 'yyyy/MM/dd', 'en'));
    const orderByQ = orderBy ? orderBy : '';
    const orderDirectionQ = orderDirection ? orderDirection : '';
    const filterValueQ = filterValue ? filterValue : '';
    return this.httpClient.get(`${environment.apiUrl}/users/my-items/${userUid}?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&dateFrom=${dateFromQ}&dateTo=${dateToQ}&orderBy=${orderByQ}&orderDirection=${orderDirectionQ}&filterValue=${filterValueQ}`);
  }
  getReceiptInfo(receiptUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt-info/${receiptUid}`);
  }
  rateItem(userUid: string, itemDescriptionId: number, rating: any ) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/products/${itemDescriptionId}`, rating);
  }
  getReceipt(receiptUid: string, rowsFrom: number, rowsTo: number, orderBy: string, orderDirection: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt/${receiptUid}?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }
  getExpensedList(receiptUid: string, rowsFrom: number, rowsTo: number, orderBy: string, orderDirection: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt-expense/${receiptUid}?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }
  getLoyalityPoints(receiptUid: string, rowsFrom: number, rowsTo: number, orderBy: string, orderDirection: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/loyality/${receiptUid}?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }
  getPayments(receiptUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/payment/${receiptUid}`);
  }
  changeLockStatus(userUid: string, receiptUid: string, status: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${userUid}/receipt/${receiptUid}/lock`, status);
  }
  generateExpences(receiptUid: string, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/receipt/${receiptUid}/expense`, data);
  }
  getProductInfo(sku: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt/product/${sku}`);
  }
  getMyExpenses(userUid: string, dateFrom?: string, dateTo?: string, maxRowCount?: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/expenses?dateFrom=${dateFrom}&dateTo=${dateTo}&maxRowCount=${maxRowCount}`);
  }
  getWarrantyInuranceList(userUid: string, receiptUid: string, type: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/receipt/${receiptUid}/warranty-insurance?${type}=yes`);
  }
  getWarrantyInurancePackages(itemProtectionsUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt/warranty-insurance-action-info/${itemProtectionsUid}`);
  }
  getWarrantyInuranceActual(itemProtectionsUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/receipt/warranty-insurance-info/${itemProtectionsUid}`);
  }
  getMyPublishers(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/dashboard/store-location`);
  }
  getMostBoughtItems(paginationReq: PaginationRequest) {
    return this.httpClient.get(`${environment.apiUrl}/users/dashboard/most-bought-products`, {params: {...paginationReq}});
  }
  getProductRecall(rowsFrom: number, rowsTo: number, orderBy: string, orderDirection: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/products-recall?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }

}
