import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-chart-linear-horizontal',
  templateUrl: './chart-linear-horizontal.component.html',
  styleUrls: ['./chart-linear-horizontal.component.scss']
})
export class ChartLinearHorizontalComponent implements OnInit {


  // options
  @Input() data = [];
  @Input() legend: boolean = true;
  @Input() showLabels: boolean = true;
  @Input() animations: boolean = true;
  @Input() xAxis: boolean = true;
  @Input() yAxis: boolean = true;
  @Input() showYAxisLabel: boolean = true;
  @Input() showXAxisLabel: boolean = true;
  @Input() showGridLines: boolean = true;
  @Input() xAxisLabel: string = 'Year';
  @Input() yAxisLabel: string = 'Population';
  @Input() timeline: boolean = true;

  colorScheme = {
    domain: ['#43085A', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  constructor() { }

  ngOnInit(): void {
  }

}
