import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { onSectionExpand } from 'src/app/@animations/animations';

const scrollLimit = 0.8;

@Component({
  selector: 'atom-widget-list-scroll',
  templateUrl: './list-scroll.component.html',
  styleUrls: ['./list-scroll.component.scss'],
  animations: [onSectionExpand]

})
export class AtomListScrollComponent {

  isExpanded = false;
  @Input() isLoading = false;
  @Input() bShowExpandButton = true;
  @Input() bShowList = false;
  @Input() isRollLoading = false;

  @Output() onNewDataNeed = new EventEmitter<void>();

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const iScrollPos = $event.target.scrollTop/($event.target.scrollHeight - $event.target.offsetHeight);
    if(iScrollPos > 0.8) {
      this.onNewDataNeed.emit();
    }
  }

}
