<div class="chart">
  <div class="head full-flex" *ngIf="!isHeadHidden">
    <div class="title-info">
      <p>{{title}}</p>
      <p class="selected-period full-flex">{{selectValue}} <img class="ml-4" src="../../../assets/images/period.svg" alt=""></p>
    </div>
    <div *ngIf="average" class="average">
      <p>average</p>
      <div class="value">
        {{average}}
      </div>
    </div>
    <div class="select-wrapp gray-select flex">
      <p>{{selectName}}</p>
      <mat-form-field floatLabel="never" appearance="outline">
        <mat-select [(ngModel)]="selectValue" (selectionChange)='updatePeriod.emit($event.value)'>
          <mat-option *ngFor="let page of selectValues" [value]="page.id"> {{ page.groupNameFullName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="type ==='bar'">
    <ngx-charts-bar-vertical
      *ngIf="totalValue"
      [view]="view"
      [barMaxWidth]="1"
      [scheme]="scheme"
      [results]="data"
      [gradient]="false"
      [showGridLines]="showGridLines"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [showYAxisLabel]="showYAxisLabel"
      [barPadding]='barPadding'
      class="chart-bar"
      [legend]="legend">
      <ng-template let-model="model" #tooltipTemplate>
        <div class="tooltip">
          <span>{{title}}: </span>{{model.value}}
        </div>
      </ng-template>
    </ngx-charts-bar-vertical>
    <div *ngIf="!totalValue" class="empty">
      <img src="../../../assets/images/AdCreator/statisticIcon.png" alt="">
      <h1>No Statistics Yet</h1>
    </div>
  </ng-container>
  <ng-container *ngIf="type==='line'">
    <div class="total-stats flex-center">
      <div class="stat">
        <div class="name">users</div>
        <div class="value first">5243</div>
      </div>
      <div class="stat">
        <div class="name">users</div>
        <div class="value second">1300</div>
      </div>
    </div>
    <ngx-charts-line-chart
      [scheme]="{domain: ['#11b2ee','#007ede']}"
      [xAxis]="true"
      [yAxis]="false"
      [gradient]='true'
      [roundDomains]='true'
      [autoScale]="true"
      class="line-bar"
      [results]="data">
    </ngx-charts-line-chart>
  </ng-container>
</div>
