<mat-toolbar>
    <mat-toolbar-row>
      <div class="desktop-row full-flex ag-w-100">
        <a href="{{landingLink}}" class="logo flex" >
          <img src="../../../assets/images/logo.svg" alt="logo">
        </a>
        <div class="grid-wrapper ag-w-100">
          <div class="box start-1 end-4" style="display: inline-flex; align-items: center; justify-content: center">
            <!-- <atom-search [bDarkMode]="true" (onEnter)="toSearch($event)" (textChanged)="searchChanged.next($event)"></atom-search> -->
          </div>
          <div class="box start-6 end-13">
            <app-stats class="stats" [data]='stats' [theme]="'blue'" [useLastSeparator]='true'></app-stats>
          </div>
        </div>
        <div class="profile full-flex" style="justify-content: space-around;">
          <a [routerLink]="['my-profile']" class="flex-center">
            <!-- <app-image-viewer [imageData]="avatar"></app-image-viewer> -->
            <div class="user">
              <div>Welcome,</div>
              <div class="ag-t-blue ag-font-16 " style="cursor: default">User</div>
            </div>
          </a>
          <button (click)="returnToLogin()" class="btn-icon flex"><img src="../../../../assets/images/New design/bx-log-out-circle2.svg" alt="login button"></button>
        </div>
      </div>
      <div class="mobile-row full-flex ag-w-100">
        <a [routerLink]="['/auth']" class="flex">
          <img src="../../../assets/images/logo-mini-white.svg" alt="">
        </a>
        <div class="btn-wrapp">
          <!-- <button
            class="menu-btn"
            (click)="mobileMenuEvent.emit({login: login, avatar: avatar, stats: stats})"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button>
            <mat-icon style="color: #fff;" aria-label="Side nav toggle icon">menu</mat-icon>
          </button> -->
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  