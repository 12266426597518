import { AccountTypeEnum, AuthUserService } from './../../@auth/auth-user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DictionariesApiUrls } from './dictionaries.enum';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  constructor(private httpClient: HttpClient, private authService: AuthUserService) {}

  @Cacheable()
  getReferralTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.referralTypes}`);
  }

  @Cacheable()
  getUsersAccessLevel() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.accessLevel}`);
  }

  getTokenRate() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.tokenRate}`);
  }

  @Cacheable()
  getTooltips(accountType: AccountTypeEnum) {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.tooltips}`, {params: {accountType}})
  }

  getAdPages() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.adPages}`);
  }

  @Cacheable()
  getAdTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.adTypes}`);
  }

  @Cacheable()
  getMessagesCategories() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.messagesCategories}`);
  }

  @Cacheable()
  getGenders() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.genders}`);
  }

  @Cacheable()
  getTitles() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.titles}`);
  }

  @Cacheable()
  getCountries() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.countries}`);
  }

  @Cacheable()
  getIsHeadquarters() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.isHeadquarters}`);
  }

  @Cacheable()
  getContractTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.contractTypes}`);
  }

  @Cacheable()
  getProfessionalRoles() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.professionalRoles}`);
  }



  @Cacheable()
  getMaritalStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.maritalStatus}`);
  }

  @Cacheable()
  getStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.statuses}`);
  }

  @Cacheable()
  getUserStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.userStatuses}`);
  }

  @Cacheable()
  getEmployeeStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.employeeStatuses}`);
  }

  @Cacheable()
  getUserPermissions() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.userPermissions}`);
  }

  @Cacheable()
  getPermissions() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.permissions}`);
  }

  @Cacheable()
  getEventTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.eventTypes}`);
  }

  @Cacheable()
  getCourseStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.courseStatuses}`);
  }

  @Cacheable()
  getBreads() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.breads}`);
  }

  @Cacheable()
  getMealSizes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.mealSizes}`);
  }

  @Cacheable()
  getTaskCategories() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.taskCategories}`);
  }

  @Cacheable()
  getTaskPriorities() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.taskPriorities}`);
  }

  @Cacheable()
  getTaskStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.taskStatuses}`);
  }

  @Cacheable()
  getPayrollTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.payrollTypes}`);
  }

  @Cacheable()
  getResidencyStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.residencyStatuses}`);
  }

  @Cacheable()
  getP45Values() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.p45Values}`);
  }

  @Cacheable()
  getStudentLoanTypes() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.studentLoanTypes}`);
  }

  @Cacheable()
  getEmployeeStatements() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.employeeStatements}`);
  }

  @Cacheable()
  getPayrollStatuses() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.payrollStatuses}`);
  }

  getUserProfiles() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.userProfiles}`);
  }

  getDictionaryByName(name: string) {
    return this.httpClient.get(`${environment.apiUrl}/dictionaries?dictionaryFullName=${name}`);
  }

  getDictionaryByShortName(name: string) {
    return this.httpClient.get(`${environment.apiUrl}/dictionaries?dictionaryShortName=${name}`);
  }

  getExchangeRates() {
    return this.httpClient.get(`${environment.apiUrl}${DictionariesApiUrls.exchangeRates}`, {headers: {
      'x-access-token': `${this.authService.getToken()}`
    }});
  }
}
