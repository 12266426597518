import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-multiple-autocomplete-selector',
  templateUrl: './multiple-autocomplete-selector.component.html',
  styleUrls: ['./multiple-autocomplete-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultipleAutocompleteSelectorComponent)
    }
  ]
})
export class MultipleAutocompleteSelectorComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() noSelectAll: boolean;
  @Input() placeholder = '';
  @Input() values: any = [];
  @Input() bindLabel = 'login';
  @Input() isOnlyOneValue: boolean;
  @Input() secondBindLabel: string;
  @Output() valueChangedEvent = new EventEmitter();
  public selectAll = false;
  public val = [];
  public disabled = false;
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

  writeValue(value: any): void {
    this.val = value;
  }

  toggleSelectAll(value: boolean) {
    if (!this.disabled) {
      this.selectAll = value;
      if (value) {
        this.val = this.values;
      } else {
        this.val = [];
      }
      this.onChange(this.val);
    }
  }

  valueChanged(event) {
    this.onChange(event);
    this.valueChangedEvent.emit(event);
  }

}
