<form *ngIf="imageSize" [formGroup]='form' class="content">
    <div class="grid-wrapper section">
        <div class="box start-1 end-7">
            <p>Ad Layout
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                 (hardcode)
            </p>
            <mat-form-field floatLabel="never" appearance="outline">
                <mat-select [disabled]='true'  name="layout" formControlName='layout'>
                  <mat-option></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="box start-7 end-13"></div>
    </div>
    <div class="grid-wrapper section image-loader">
        <div class="box start-1 end-8">
            <img (click)="openImageConfigurator()" *ngIf="form.get('image').value" class="image" [src]="form.get('image').value" alt="">
            <div (click)="openImageConfigurator()"  *ngIf="!form.get('image').value" class="empty-image-wrapper flex">
                <div class="empty-image flex-center">
                    <img  class="add-photo" src="../../../../../assets/images/add_photo.svg" alt="">
                </div>
                <p>
                    Upload Image
                </p>
            </div>
        </div>
        <div class="box start-8 end-13">
            <p class="image-info">Lorem ipsum des dores forte psume des dores sum
                des dores forte psum dem des dores foperta ipsum es dores forte psum dem es tas amon eamone ta ipsum des dores forte psum dem des do lorem sento.
            </p>
        </div>
    </div>
    <div class="grid-wrapper section image-loader">
        <div class="box start-1 end-8">
            <img (click)="openLogoConfigurator()"  *ngIf="form.get('logo').value" class="image" [src]="form.get('logo').value" alt="">
            <div (click)="openLogoConfigurator()"  *ngIf="!form.get('logo').value" class="empty-image-wrapper flex">
                <div class="empty-image flex-center">
                    <img  class="add-photo" src="../../../../../assets/images/add_photo.svg" alt="">
                </div>
                <p>
                    Upload Logo
                </p>
            </div>
        </div>
        <div class="box start-8 end-13">
            <p class="image-info">Lorem ipsum des dores forte psume des dores sum
                des dores forte psum dem des dores foperta ipsum es dores forte psum dem es tas amon eamone ta ipsum des dores forte psum dem des do lorem sento.
            </p>
        </div>
    </div>
    <div class="section">
        <p>Heading
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </p>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput
                  placeholder="Please add some heading"
                  formControlName="heading"
                  name="heading">
        </mat-form-field>
        <p>Text
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </p>
        <mat-form-field disabled class="title" floatLabel="never" appearance="outline">
            <textarea matInput
                cdkTextareaAutosize
                placeholder="Please add some title"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="6"
                cdkAutosizeMaxRows="6"
                formControlName ="text"
                name="text">
            </textarea>
        </mat-form-field>
        <p>Add Price
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </p>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput
                  placeholder="Please add some price"
                  formControlName="price"
                  name="price">
        </mat-form-field>
    </div>
    
</form>