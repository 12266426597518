<app-page-header-with-border class="header">
  <div class="stats-wrapp">
    <app-stats class="head" [data]='headerData' [statsCount]='4'></app-stats>
  </div>
  <div class="sort-wrapp gray-select flex">
    <p>Target page: </p>
    <mat-form-field floatLabel="never" appearance="outline">
      <mat-select [(ngModel)]="targetPage" (selectionChange)='getData()'>
        <mat-option *ngFor="let page of targetPages" [value]="page.id"> {{ page.groupNameFullName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</app-page-header-with-border>
<div *ngIf="headerData" class="charts-wrapp">
  <div class="row full-flex">
    <div class="chart">
      <div class="cost">
        <h3>Average Per Session</h3>
        <div class="value flex-center">
          <div class="amount-wrapp flex">
            {{sessionsData.avgPerSession}} <span> {{currency}}</span>
          </div>
        </div>
      </div>
      <app-chart
        [data]='sessionsData.sessions'
        [totalValue]='sessionsData.totalSession'
        [average]='sessionsData.average'
        [title]="'Sessions'"
        [selectName]="'Period'"
        (updatePeriod)='getSessions($event)'
        [selectValues]='periods'>
      </app-chart>
    </div>
    <div class="chart">
      <div class="cost">
        <h3>Average Per Click</h3>
        <div class="value flex-center">
          <div class="amount-wrapp flex">
            {{clicksData.avgPerClick}} <span> {{currency}}</span>
          </div>
        </div>
      </div>
      <app-chart
        [data]='clicksData.clicks'
        [totalValue]='clicksData.totalClicks'
        [average]='clicksData.average'
        [title]="'Amount Of Clicks'"
        [selectName]="'Period'"
        (updatePeriod)='getClicks($event)'
        [selectValues]='periods'>
      </app-chart>
    </div>
  </div>
  <!-- <div class="row mt-5 full-flex">
    <div class="chart">
      <app-chart
        [type]="'line'"
        [data]='testData'
        [title]="'Amount Of Clicks'"
        [selectName]="'Period'"
        (updatePeriod)='getClicks($event)'
        [selectValues]='periods'>
      </app-chart>
    </div>
    <div class="chart">
      <app-chart
        [type]="'line'"
        [data]='testData'
        [title]="'Amount Of Clicks'"
        [selectName]="'Period'"
        (updatePeriod)='getClicks($event)'
        [selectValues]='periods'>
      </app-chart>
    </div>
  </div> -->
</div>
