import { SupportService } from './../../../_shared/services/rest-services/support.service';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';

const MAX_SIZE = 10 * 1024 * 1024; // 10MB

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss']
})
export class NewTicketComponent implements OnInit {
  @ViewChild('FileInput', { static: false }) FileInput: ElementRef;
  public userUid = '';
  public form: FormGroup;
  public subscription: Subscription;
  public files = [];
  public isReply: boolean;

  constructor(@Inject (MAT_DIALOG_DATA) public data: any,
              public toastService: ToastService,
              private authUserService: AuthUserService,
              private supportService: SupportService,
              public dialogRef: MatDialogRef<NewTicketComponent>) {
              }

  ngOnInit(): void {
    this.userUid = this.authUserService.getUserUid();
    this.createForm();
    if (this.data) {
      this.patchValue();
      this.data.reply ? this.isReply = true : this.isReply = false;
    }
  }

  createForm() {
    this.form = new FormGroup({
      title: new FormControl({value: null, disabled: this.data}, [Validators.required, Validators.maxLength(100)]),
      message: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
    });
  }

  patchValue() {
    this.form.patchValue({
      title: this.data.title,
      message: this.data.message ? this.data.message : ''
    });
  }

  send() {
    const fd = new FormData();
    if (this.isReply) {
      fd.append('replyUid', this.data.messageUid);
    }
    fd.append('title', this.form.get('title').value);
    fd.append('message', this.form.get('message').value);
    this.files.forEach((file: any) => {
      fd.append('files', file);
    });
    this.supportService.createTicket(this.userUid, fd)
      .subscribe((response: any) => {
        this.toastService.success(response.message);
        setTimeout(() => {
          this.dialogRef.close(true);
          this.files = [];
        }, 1000);
      },
      (err) => this.toastService.warning(err));
  }

  fileSelected(event) {
    for (const file of event) {
      if (file.size <= MAX_SIZE) {
        this.files.push(file);
      } else {
        this.toastService.warning(`Maximum file size exceed. Max file size is 10 MB.`);
      }
    }
  }

  clickInput() {
    this.FileInput.nativeElement.click();
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }


}
