<div
  class="content"
  [ngClass]="{ 'ng-fixed-table-container': isFixedLayoutTable }"
  [style.display]="isLoading || data.length == 0 ? 'flex' : 'block'"
>
  <table
    [hidden]="!(data.length > 0) || isLoading"
    mat-table
    matSort
    [dataSource]="dataSource"
    #empTbSortWithObject="matSort"
    (matSortChange)="onMatSortChange()"
    [ngClass]="{ 'ng-fixed-table': isFixedLayoutTable }"
  >
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.fieldName"
    >
      <!-- header -->
      <ng-container
        *ngIf="
          column.title === 'Expense' ||
          column.title === 'Category' ||
          column.title === 'Offers'
        "
      >
        <th mat-header-cell *matHeaderCellDef class="head-cell">
          <span>{{ column.title }}</span>
        </th>
      </ng-container>
      <ng-container
        *ngIf="
          column.title !== 'Expense' &&
          column.title !== 'Category' &&
          column.title !== 'Offers'
        "
      >
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="head-cell">
          <span>{{ column.title }}</span>
          <div class="table-arrow-wrapper">
            <div
              class="table-sort-up table-sort-up--empty"
              [ngClass]="{
                'table-sort-up--empty': sortInfo[column.fieldName] !== 'asc',
                'table-sort-up--full': sortInfo[column.fieldName] === 'asc'
              }"
            ></div>
            <div
              class="table-sort-down table-sort-down--empty"
              [ngClass]="{
                'table-sort-down--empty': sortInfo[column.fieldName] !== 'desc',
                'table-sort-down--full': sortInfo[column.fieldName] === 'desc'
              }"
            ></div>
          </div>
        </th>
      </ng-container>

      <!-- content -->
      <td
        [ngSwitch]="column.extraValue"
        class="row-cell"
        [ngClass]="{
          'offers-column': false,
          'ag-info-hover': column.hoverWithName
        }"
        mat-cell
        *matCellDef="let element"
      >
        <div *ngSwitchDefault>
          {{ element[column.fieldName] ? element[column.fieldName] : "—" }}

          <div
            class="ag-info-hover-text ag-shadow-light"
            style="
              width: auto;
              text-align: center;
              max-width: initial;
              left: 50%;
              top: 75%;
            "
            *ngIf="column.hoverWithName"
          >
            {{ element[column.fieldName] ? element[column.fieldName] : "—" }}
          </div>
        </div>
        <span *ngSwitchCase="'amount'" class="amount">
          {{ element[column.fieldName] ? element[column.fieldName] : "-" }}
          <ng-container *ngIf="element[column.fieldName]">{{
            currency
          }}</ng-container>
        </span>
        <span *ngSwitchCase="'tx'" class="avatar">
          <!-- <img
            *ngIf="element[column.fieldName]"
            [src]="element[column.fieldName]"
            alt="brand logo"
          /> -->
          {{ element[column.fieldName] }}
        </span>
        <span *ngSwitchCase="'rating'" class="rating">
          <star-rating
            #rating
            [showHalfStars]="true"
            [size]="'3px'"
            [readOnly]="true"
            [starType]="'svg'"
            [rating]="element[column.fieldName]"
          ></star-rating>
        </span>
        <span *ngSwitchCase="'rating-editable'" class="rating">
         <star-rating
           #rating
           (click)="
              column.extraValue == 'rating-editable'
                ? elementClick(column.fieldName, {
                    element: element,
                    rating: rating
                  })
                : null
            "
           [hoverEnabled]="true"
           [showHalfStars]="true"
           [readOnly]="
              column.extraValue == 'rating-editable'
                ? false
                : true
            "
           [starType]="'svg'"
           [rating]="element[column.fieldName]"
         ></star-rating>
        </span>
        <span *ngSwitchCase="'avatar'" class="avatar">
          <img
            *ngIf="element[column.fieldName]"
            [src]="element[column.fieldName]"
            alt="brand logo"
          />
          <span *ngIf="!element[column.fieldName]">-</span>
        </span>

        <span *ngSwitchCase="'product'" class="product flex align-center">
          <div class="product-image">
            <img
              *ngIf="
                !useOldIcon && element[column.fieldName].productDescriptionURL
              "
              [src]="element[column.fieldName].productDescriptionURL"
              alt="product image"
            />
            <img
              *ngIf="
                !useOldIcon && !element[column.fieldName].productDescriptionURL
              "
              src="../../../assets/images/New design/empty-product.svg"
              alt="empty product"
            />
            <img
              *ngIf="
                useOldIcon && !element[column.fieldName].productDescriptionURL
              "
              src="../../../../assets/images/Table/product-placeholder.png"
              alt="empty product"
            />
          </div>
          <span style="margin-left: 11px">{{
            element[column.fieldName].productName
              ? element[column.fieldName].productName
              : element.productName
          }}</span>
        </span>
        <span *ngSwitchCase="'checkbox'">
          <mat-checkbox
            (change)="selectChange($event)"
            [id]="element[selectedByColumn]"
            [checked]="
              selectedArray.indexOf(element[selectedByColumn]) > -1
                ? true
                : false
            "
          ></mat-checkbox>
        </span>
        <span *ngSwitchCase="'offers'">
          <a
            *ngIf="element[column.fieldName]"
            target="_blank"
            [href]="element['offersWebsite']"
            style="display: flex; padding: 2px 0"
          >
            <img
              [src]="element[column.fieldName]"
              class="m-offers"
              alt="Item offer image"
            />
          </a>
          <a
            *ngIf="!element[column.fieldName]"
            style="display: flex; padding: 2px 0"
          >
            <img
              class="m-offers"
              src="../../../../assets/images/AdPlaceholder/140x38.png"
              alt="Offer placeholder"
            />
          </a>
        </span>
        <span
          *ngSwitchCase="'customValue'"
          (click)="column.action ? column.action($event, element) : null"
          [innerHTML]="element ? column.valuePrepareFunction(element) : ''"
        ></span>
      </td>
      <!-- content -->
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="thinner"
    ></tr>
  </table>
  <app-loader *ngIf="isLoading"></app-loader>
  <app-empty *ngIf="data.length === 0 && !isLoading"></app-empty>
  <div
    class="bottom-content ag-flex-row-between align-center ag-mt-1"
    style="height: 50px; justify-content: center"
  >
    <div style="order: 2">
      <span
        *ngFor="
          let item of []
            | paginate
              : {
                  itemsPerPage: oPagination.pageSize,
                  currentPage: oPagination.page,
                  totalItems: oPagination.totalRows,
                  id: tableName
                }
        "
      ></span>
      <app-paginator
        *ngIf="!isLoading && data.length < oPagination.totalRows"
        (pageChange)="pageChange.emit($event)"
        [id]="tableName"
      ></app-paginator>
    </div>
    <ng-content></ng-content>
  </div>
</div>
