import { ReaderGuard } from "./@auth/reader.guard";
import { PublisherGuard } from "./@auth/publisher.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AuthGuard } from "./@auth/auth.guard";

const routes: Routes = [
  {
    path: "public",
    loadChildren: () =>
      import("./pages-public/pages-public.module").then(
        (m) => m.PagesPublicModule
      ),
  },
  {
    path: "publisher",
    canActivate: [AuthGuard, PublisherGuard],
    loadChildren: () =>
      import("./pages-publisher/pages-publisher.module").then(
        (m) => m.PagesPublisherModule
      ),
  },
  {
    path: "reader",
    canActivate: [AuthGuard, ReaderGuard],
    loadChildren: () =>
      import("./pages-reader/pages-reader.module").then(
        (m) => m.PagesReaderModule
      ),
  },

  { path: "", redirectTo: "publisher", pathMatch: "full" },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  // { path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
  scrollPositionRestoration: "top",
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
