import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {
    transform(value: any, isPublisher: boolean) {
      let res = [];
      if (isPublisher) {
        res = value.filter(x => x.category != 'Receipts');
      } else {
        res = value;
      }
      return res;
    }
}
