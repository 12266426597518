// Height

export function isSmallMobile() {
    return window.matchMedia('(max-height: 660px)').matches ? true : false;
}

export function isShort() {
    return window.matchMedia('(max-height: 800px)').matches ? true : false;
}

// Width

export function isMobile() {
    return window.matchMedia('(max-width: 575px)').matches ? true : false;
}

export function isTab() {
    return window.matchMedia('(max-width: 768px)').matches ? true : false;
}

export function isSmall() {
    return window.matchMedia('(max-width: 800px)').matches ? true : false;
}

export function isLess1100() {
    return window.matchMedia('(max-width: 1100px)').matches ? true : false;
}

export function isLaptop() {
    return window.matchMedia('(max-width: 1400px)').matches ? true : false;
}

export function isMediumDesktop() {
    return window.matchMedia('(max-width: 1800px)').matches ? true : false;
}
