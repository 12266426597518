import { TooltipTypeReader } from 'src/app/_components/tooltip/tooltips.model';
import { HttpReceiptService } from './../../_shared/services/http-services/http-receipts.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { environment } from 'src/environments/environment';

const PER_PAGE  = 5;

@Component({
  selector: 'widget-my-receipts',
  templateUrl: './my-receipts.component.html',
  styleUrls: ['./my-receipts.component.scss'],
})
export class WidgetMyReceiptsComponent implements OnInit {

  sCurrency = ''
  bLoading = false;
  aReceiptsList = [];
  iCountAllReceipts = 0;
  oReceiptsSummary: ReceiptsSummary = {nAvarageAmount: null, nTotalAmount: null, nTotalItems: null};
  amountSelect = [
    '100', '500', '1000'
  ];
  amountFilter: string;
  ratingFilter: number;
  dateFilterData: DateFilter;
  textFilter = '';
  aSkipFilter = [];
  bIsSmallSize = false;
  isRollLoading = false;

  public TooltipTypeReader = TooltipTypeReader;

  private sbBackendAnswer: Subscription = new Subscription();

  constructor(private receiptsService: HttpReceiptService, private authUserService: AuthUserService) {
    this.sbBackendAnswer.add(
      this.receiptsService.getUserReceiptsHeader().subscribe( (oData: object) => {
        if('result'in oData) {
          this.oReceiptsSummary.nAvarageAmount = Number(oData['result'].avarageAmount)
          this.oReceiptsSummary.nTotalAmount = Number(oData['result'].totalAmount)
          this.oReceiptsSummary.nTotalItems = Number(oData['result'].totalItems)
        }
      })
    )

    this.sCurrency = this.authUserService.getUserCurrency();
    this.clearAmount();
    this.clearRate();
    this.clearDate();
  }

  ngOnDestroy() {
    this.sbBackendAnswer.unsubscribe()
  }

  ngOnInit(): void {
    this.getData()
  }

  getNewData() {
    this.getData(true)
  }

  getData(bNewData = false) {
    if(this.bLoading) { return null}
    if(bNewData) { this.aReceiptsList = [] }
    const dStaticDateFrom = environment.sFormsStaticDateFrom ? new Date(environment.sFormsStaticDateFrom) : new Date(Date.now() - 31536e6)
    const iLastRow = this.aReceiptsList.length;
    const dateFrom = (this.aSkipFilter.indexOf('date') > -1) ? dStaticDateFrom : this.dateFilterData.dateFrom;
    const dateTo = (this.aSkipFilter.indexOf('date') > -1) ? new Date() : this.dateFilterData.dateTo;
    const amountFilter = (this.aSkipFilter.indexOf('amount') > -1) ? null : this.amountFilter.toString();
    const ratingFilter = (this.aSkipFilter.indexOf('rating') > -1) ? null : this.ratingFilter.toString();
    const rowsFrom = iLastRow + 1;
    const rowsTo = iLastRow + PER_PAGE;
    const filterValue = this.textFilter;
    this.bLoading = true;
    this.sbBackendAnswer
    .add(this.receiptsService.getUserReceiptsList({
      dateFrom,
      dateTo,
      amountFilter,
      ratingFilter,
      rowsFrom,
      rowsTo,
      filterValue
      })
      .subscribe ((response: any) => {
        this.iCountAllReceipts = response.result.length;
        this.aReceiptsList = [...this.aReceiptsList, ...response.result.receiptsList];
        this.bLoading = false;
        this.isRollLoading = false;
        }, () => {
          this.bLoading = false;
          this.isRollLoading = false;
        }));
  }

  amountUpdate() {
    this.aSkipFilter = this.aSkipFilter.filter(v => v !== 'amount')
    this.getNewData();
  }

  private clearAmount() {
    this.amountFilter = this.amountSelect[0];
    this.aSkipFilter.push('amount')
  }

  private skipAmountAndUpdate() {
    this.aSkipFilter.push('amount');
    this.getNewData();
  }

  switchAmountFilterAndUpdate() {
    if(this.aSkipFilter.indexOf('amount') === -1) {
      this.skipAmountAndUpdate();
    } else {
      this.amountUpdate();
    }
  }

  rateUpdate(e) {
    if(e.rating > 0){
      this.ratingFilter = e.rating
    }
    this.aSkipFilter = this.aSkipFilter.filter(v => v !== 'rating')
    this.getNewData()
  }

  private clearRate() {
    this.ratingFilter = 0
    this.aSkipFilter.push('rating')
  }

  private skipRateAndUpdate() {
    this.aSkipFilter.push('rating');
    this.getNewData();
  }

  switchRateAndUpdate() {
    if(this.aSkipFilter.indexOf('rating') === -1) {
      this.skipRateAndUpdate()
    } else {
      this.rateUpdate({})
    }
  }

  dateUpdate() {
    if(this.dateFilterData.dateTo !== null) {
      this.aSkipFilter = this.aSkipFilter.filter(v => v !== 'date')
      this.getNewData()
    }
  }

  private clearDate() {
    const dStaticDateFrom = environment.sFormsStaticDateFrom ? new Date(environment.sFormsStaticDateFrom) : new Date(Date.now() - 31536e6)
    this.aSkipFilter.push('date')
    this.dateFilterData = { dateFrom: dStaticDateFrom, dateTo: new Date() }
  }

  private skipDateAndUpdate() {
    this.aSkipFilter.push('date');
    this.getNewData();
  }

  switchDateAndUpdate() {
    if(this.aSkipFilter.indexOf('date') === -1) {
      this.skipDateAndUpdate();
    } else {
      this.dateUpdate();
    }
  }

  textUpdate(sText: string) {
    this.textFilter = sText
    this.getNewData()
  }

  onResized(e) {
    this.bIsSmallSize  = e.newWidth > 500 ? false : true;
  }

  onListScroll() {
    if(this.iCountAllReceipts <= this.aReceiptsList.length) { return null; }
    this.isRollLoading = true;
    this.getData()
  }

}


interface ReceiptsSummary {
  nAvarageAmount: number;
  nTotalAmount: number;
  nTotalItems: number;
}

interface DateFilter {
  dateFrom: Date;
  dateTo: Date;
}
