import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-one-reviews-rating',
  templateUrl: './one-reviews-rating.component.html',
  styleUrls: ['./one-reviews-rating.component.scss']
})
export class OneReviewsRatingComponent {

  @Input() oReview;

}
