import { HttpReceiptService } from 'src/app/_shared/services/http-services/http-receipts.service';
import { HttpShoppingListService } from './../../../_shared/services/http-services/http-shopping-list.service';
import { Pagination } from './../../../_models/api.model';
import { WindowsSizeService } from 'src/app/_shared/services/window-size.service';
import { itemsColumns, shoppingListColumns, savedShoppingListColumns, ordersColumns } from './master-shopping-list-columns';
import { ShopingListService } from './../../../_shared/services/rest-services/shoping-list.service';
import { ToastService } from './../../../_shared/services/toast.service';
import { Subscription, Subject } from 'rxjs';
import { ReceiptsService } from './../../../_shared/services/rest-services/receipts.service';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TooltipTypeReader } from 'src/app/_components/tooltip/tooltips.model';

const ITEMS_PER_PAGE = 4;

@Component({
  selector: 'app-master-shoppping-list',
  templateUrl: './master-shoppping-list.component.html',
  styleUrls: ['./master-shoppping-list.component.scss']
})
export class MasterShopppingListComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  public isMobile$ = new Subject();
  public isLoading = true;

  public itemsColumns = itemsColumns;
  public historicalItems = [];
  public itemsPagination = new Pagination(ITEMS_PER_PAGE);

  public shoppingListColumns = shoppingListColumns;
  public shoppingList = [];
  public shoppingListPagination = new Pagination(ITEMS_PER_PAGE);

  public savedShoppingListColumns = savedShoppingListColumns;
  public savedShoppingList = [];
  public savedShoppingListPagination = new Pagination(ITEMS_PER_PAGE);

  public ordersColumns = ordersColumns;
  public orders = [];
  public ordersPagination = new Pagination(ITEMS_PER_PAGE);

  public TooltipTypeReader = TooltipTypeReader;
  public selectedIndex = 0;


  constructor(private httpReceiptService: HttpReceiptService,
              private httpShoppingListService: HttpShoppingListService,
              private toastService: ToastService) {
                this.isMobile$ = WindowsSizeService.isMobile$;
  }

  ngOnInit(): void {
    this.getHistoricalItems();
    this.getShoppingList();
    this.getSavedShoppingLists();
    this.getSavedOrdersLists();
  }

  getHistoricalItems() {
    this.isLoading = true;
    const rowsFrom = Number(this.itemsPagination.itemsPerPage) * Number(this.itemsPagination.currentPage) - Number(this.itemsPagination.itemsPerPage) +1;
    const rowsTo = Number(this.itemsPagination.itemsPerPage) * Number(this.itemsPagination.currentPage);
    this.subscription.add(this.httpReceiptService.getUserItemsList(rowsFrom.toString(), rowsTo.toString())
      .subscribe ((response: any) => {
        this.historicalItems = response.result.productList;
        this.itemsPagination.totalItems = response.result.length;
        this.isLoading = false;
        }, (err) => {
          this.toastService.warning(err.error.errors[0].message);
    }));
  }

  getShoppingList() {
    this.isLoading = true;
    this.subscription.add(this.httpShoppingListService.getShopingList(this.shoppingListPagination.request())
      .subscribe ((response: any) => {
        this.shoppingList = response.result.productList;
        this.shoppingListPagination.totalItems = response.result.totalItems;
        this.isLoading = false;
    }, (err) => {
      this.toastService.warning(err.error.errors[0].message);
    }));
  }

  getSavedShoppingLists() {
    this.isLoading = true;
    this.subscription.add(this.httpShoppingListService.getSavedShopingLists(false, this.savedShoppingListPagination.request())
    .subscribe ((response: any) => {
      this.savedShoppingList = response.result.titleList;
      this.savedShoppingListPagination.totalItems = response.result.totalItems;
      this.isLoading = false;
    }, (err) => {
      this.toastService.warning(err.error.errors[0].message)
    }));
  }

  getSavedOrdersLists() {
    this.isLoading = true;
    this.subscription.add(this.httpShoppingListService.getSavedShopingLists(true, this.ordersPagination.request())
    .subscribe ((response: any) => {
      this.orders = response.result.titleList;
      this.ordersPagination.totalItems = response.result.totalItems;
      this.isLoading = false;
    }, (err) => {
      this.toastService.warning(err.error.errors[0].message)
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
