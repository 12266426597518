<aside class="ag-flex-row-between">
  <div class="item" *ngFor="let item of data">
      <div class="container_summary">
        <div class="desc">
          <div class="label">{{item.fieldName}}</div>
          <img class="icon" [src]="'/assets/images/Menu/Upper/' + item.icon">
        </div>

        <div class="value">
          {{item.value}}
          <span *ngIf="item.isAmount && item.value" class="currency">{{currency}}</span>
          <span *ngIf="item.isToken && item.value" class="currency">RCPT</span>
        </div>
      </div>
  </div>
</aside>
