import { AccountTypeEnum, AuthUserService } from './../../@auth/auth-user.service';
import { TooltipTypePublisher, TooltipTypeReader } from './../tooltip/tooltips.model';
import { HttpProfileService } from './../../_shared/services/http-services/http-profile.service';
import { MenuService } from 'src/app/_shared/services/menu.service';
import { Subscription, forkJoin } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-checkout-id',
  templateUrl: './checkout-id.component.html',
  styleUrls: ['./checkout-id.component.scss']
})
export class CheckoutIdComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  public accountType: AccountTypeEnum; 
  public checkOutData = new CheckOutData();
  public isLoading = true;
  public isAllProfileFieldsComplited = false;
  public profileLink: string;
  public isImagesLoading: boolean;

  public tooltip: TooltipTypePublisher | TooltipTypeReader;

  constructor(private httpProfileService: HttpProfileService,
              private menuService: MenuService,
              private authUserService: AuthUserService) {}

  ngOnInit(): void {
    this.checkAccountType();
    this.listenOnDataUpdate();
    this.getUserCompiteStatus();
  }


  checkAccountType() {
    this.accountType = this.authUserService.getAccountType();
    if (this.accountType === AccountTypeEnum.READER) {
      this.tooltip = TooltipTypeReader.CHECKOUT_ID;
      this.profileLink = '/reader/my-profile'
    } else if (this.accountType === AccountTypeEnum.PUBLISHER) {
      this.tooltip = TooltipTypePublisher.CHECKOUT_ID;
      this.profileLink = '/publisher/my-profile'
    }
  }

  listenOnDataUpdate() {
    this.subscription.add(this.menuService.reloadHeader$
      .subscribe (() => {
        if (!this.isAllProfileFieldsComplited) {
          this.getUserCompiteStatus();
        } else {
          this.getCodes();
        }
      }
    ));
  }

  getUserCompiteStatus() {
    this.subscription.add(this.httpProfileService.getUserDetailsCompliteStatus()
    .subscribe ((response: any) => {
      response.isAllFieldFilled ? this.getCodes() : this.isLoading = false;
    }));
  }

  getCodes() {
    this.isAllProfileFieldsComplited = true;
    this.subscription.add(forkJoin([
      this.httpProfileService.getBarcode(),
      this.httpProfileService.getQRCode()])
        .subscribe((res: any[])=> {
          this.isImagesLoading = true;
          this.isLoading = false;
          this.checkOutData = {
            barcode: res[0].link,
            receiptiumId: res[0].readerId,
            login: res[0].login,
            qrCode: res[1].link
          }
          setTimeout(()=> this.isLoading = false, 300);
        }))
  }

  onImgCodesLoaded() {
    this.isImagesLoading = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

class CheckOutData {
  public qrCode: string;
  public barcode: string;
  public login: string;
  public receiptiumId: string;
}