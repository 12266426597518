import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-one-recall',
  templateUrl: './one-recall.component.html',
  styleUrls: ['./one-recall.component.scss']
})
export class OneRecallComponent  {

  @Input() oRecall;

}
