export const DictionariesApiUrls = {
  tokenRate: '/dictionaries/words?dictionaryName=currency',
  referralTypes: '/dictionaries/words?dictionaryName=referral',
  adPages: '/dictionaries/words?dictionaryName=target%20page',
  adTypes: '/dictionaries/words?dictionaryName=ad%20type',
  genders: '/dictionaries/words?dictionaryName=gender',
  titles: '/dictionaries/words?dictionaryName=title',
  countries: '/dictionaries/words/country',
  isHeadquarters: '/dictionaries/words?dictionaryName=isHeadquarters',
  messagesCategories: '/dictionaries?dictionaryFullName=Message%20category',
  contractTypes: '/dictionaries?dictionaryFullName=Contract%20types',
  professionalRoles: '/dictionaries/professionalRoles',
  maritalStatus: '/dictionaries?dictionaryFullName=Marital%20staus',
  statuses: '/dictionaries?dictionaryFullName=Common%20names',
  userStatuses: '/dictionaries?dictionaryFullName=Users%20statuses',
  employeeStatuses: '/dictionaries?dictionaryFullName=Employee%20status',
  userPermissions: '/dictionaries?dictionaryFullName=User%20permissions%20-%20CH',
  eventTypes: '/dictionaries?dictionaryFullName=Contract%20events',
  courseStatuses: '/dictionaries?dictionaryFullName=Training%20statuses',
  mealSizes: '/dictionaries?dictionaryFullName=Meal%20Size',
  breads: '/dictionaries?dictionaryFullName=Bread',
  taskPriorities: '/dictionaries?dictionaryFullName=Tasks%20priority',
  taskCategories: '/dictionaries?dictionaryFullName=Tasks%20category',
  taskStatuses: '/dictionaries?dictionaryFullName=Tasks%20status',
  payrollTypes: '/dictionaries?dictionaryFullName=Payroll%20Adjustment%20Types',
  residencyStatuses: '/dictionaries?dictionaryFullName=Residency%20status',
  p45Values: '/dictionaries?dictionaryFullName=P45',
  studentLoanTypes: '/dictionaries?dictionaryFullName=Student%20loan%20type',
  employeeStatements: '/dictionaries?dictionaryFullName=Employee%20statement',
  userProfiles: '/dictionaries?dictionaryFullName=User%20profiles',
  permissions: '/dictionaries?dictionaryFullName=User%20permissions%20-%20NEW',
  payrollStatuses: '/dictionaries?dictionaryFullName=Payroll%20adjustment%20stauses',
  accessLevel: '/dictionaries?dictionaryFullName=User%20account%20types',
  tooltips: '/general/tooltips',
  exchangeRates: '/wallet/exchange'
};

