<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content [formGroup]="form">
  <p [innerHTML]="message"></p>
  <form [formGroup]="form" *ngIf="deleteInput">
    <p>Type DELETE to delete this record:</p>
    <mat-form-field floatLabel="never" appearance="outline">
      <mat-label></mat-label>
      <input matInput placeholder="type DELETE" formControlName="confirmation">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="!hideOkButton">
    <button *ngIf="deleteInput"
    class="mat-raised-button mat-primary"
      [disabled]="!isFormValid(form)"
      (click)="ok()">
      {{okLabel}}
    </button>
    <button *ngIf="!deleteInput" class="mat-raised-button mat-primary" (click)="ok()">{{okLabel}}</button>
  </ng-container>
  <button class="mat-raised-button mat-warn" (click)="cancel()">{{cancelLabel}}</button>
</mat-dialog-actions>
