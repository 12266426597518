import { DictionariesService } from './../../../_shared/dictionaries/dictionaries.service';
import { Subscription } from 'rxjs';
import { AdEventService } from './../_services/ad-event.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import html2canvas from 'html2canvas';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ad-preview',
  templateUrl: './ad-preview.component.html',
  styleUrls: ['./ad-preview.component.scss']
})
export class AdPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('imageDiv') imageDiv: ElementRef;
  @ViewChildren('carouselDivs') carouselDivs: QueryList<AdPreviewComponent>;

  public subscription = new Subscription();
  public generateSubscription = new Subscription();
  public finalBlob = [];
  public activeSlide = 0;
  public safeUrl;
  public backImage: any = '';
  public adTypes: any = {
    image: '',
    video: '',
    carousel: ''
  };
  public mainParams: any = {
    adType: '',
    page: '',
    size: ''
  };
  public specParams: any;

  constructor(public adEventService: AdEventService,
              public sanitizer: DomSanitizer,
              public dictionariesService: DictionariesService) {
                this.subscription.add(this.dictionariesService.getAdTypes()
                .subscribe ((res: any) => {
                  this.adTypes.image = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'image');
                  this.adTypes.video = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'video');
                  this.adTypes.carousel = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'carousel');
              }));
              }

  ngOnInit(): void {
    this.subscription.add(this.adEventService.mainForm$
      .subscribe ((form: any) => {
        this.mainParams = form;
    }));
    this.subscription.add(this.adEventService.childForm$
      .subscribe ((form: any) => {
        this.specParams = form;
        this.generateSubscription.unsubscribe();
        switch (this.mainParams.adType) {
          case this.adTypes.image.id:
            this.imageCreator();
            break;
          case this.adTypes.carousel.id:
            this.carouselCreator();
            break;
          case this.adTypes.video.id:
            this.videoCreator();
            break;
        }
    }));
  }

  imageCreator() {
    this.backImage = this.specParams.image;
    this.generateSubscription = new Subscription();
    this.generateSubscription.add(this.adEventService.getFinalBlobEvent$
      .subscribe (() => {
        this.generateImageBlob();
    }));
  }

  carouselCreator() {
    this.generateSubscription = new Subscription();
    this.generateSubscription.add(this.adEventService.getFinalBlobEvent$
      .subscribe (() => {
        this.generateCarouselBlobs();
    }));
  }

  videoCreator() {
    if (this.specParams.video) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.specParams.video);
      const link = 'https://www.youtube.com/embed/' + this.safeUrl.changingThisBreaksApplicationSecurity.split('watch?v=')[1];
      this.safeUrl.changingThisBreaksApplicationSecurity = link;
    }
  }

  generateImageBlob() {
    html2canvas(this.imageDiv.nativeElement).then((canvas) => {
        canvas.toBlob((blob) => {
          this.finalBlob.push(blob);
          this.adEventService.sendFinalBlob(this.finalBlob);
          this.finalBlob = [];
        }, 'image/png');
    });
  }

  generateCarouselBlobs() {
    this.finalBlob = [];
    const divs: any = this.carouselDivs.toArray();
    for (let i = 0; i < divs.length; i++) {
      html2canvas(divs[i].nativeElement).then((canvas) => {
        canvas.toBlob((blob) => {
          this.finalBlob.push(blob);
          if ( i + 1 >= divs.length) {
            this.adEventService.sendFinalBlob(this.finalBlob)
            this.finalBlob = [];
          }
        }, `image/png`);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.generateSubscription.unsubscribe();
  }
}
