import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  constructor(private httpClient: HttpClient) {}

  getAdSizes() {
    return this.httpClient.get(`${environment.apiUrl}/general/advertising-size`);
  }

  getTargetPages(width: string, height: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/advertising-page-list/${width}/${height}`);
  }

  toPromoteAd(userUid: string, data: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userUid}/ads`, data);
  }

  getAd(userUid: string, pageId: number) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/ads/${pageId}`);
  }

  adclick(userUid: string, adsUid: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userUid}/ads-click`, adsUid);
  }

  getAnalitics(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/ads/analitics`);
  }

  getAnaliticsDetails(userUid: string, adUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/ads/${adUid}/details-analitics`);
  }

  getAnaliticsCharts(userUid: string, period: string, advertisingId: number, page: number ) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/ads/analitics-graphs?period=${period}&advertisingId=${advertisingId}&page=${page}`);
  }



}
