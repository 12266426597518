import { AuthUserService } from './../../../@auth/auth-user.service';
import { Subscription } from 'rxjs';
import { AdService } from './../../../_shared/services/rest-services/ad.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['../ad-history/ad-history.component.scss']
})
export class HistoryDetailsComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  @Input() adData;
  public analiticsData = [];
  public columns = [
    {
      fieldName: 'fullName',
      title: 'Who Saw',
    },
    {
      fieldName: 'viewData',
      title: 'When Saw',
    },
    {
      fieldName: 'isClick',
      title: 'Is Clicked',
    },
    {
      fieldName: 'duration',
      title: 'Time to click (sec)',
    },
    {
      fieldName: 'targetPage',
      title: 'TargetPage',
    }
  ];

  constructor(public adService: AdService,
              public authUserService: AuthUserService) { }

  ngOnInit(): void {
    // this.analiticsData = this.invoicesTestData;
    this.subscription.add(this.adService.getAnaliticsDetails(this.authUserService.getUserUid(), this.adData.uid)
      .subscribe ((response: any) => {
        this.analiticsData = response.result.list;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
