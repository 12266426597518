import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-linear-chart-card",
  templateUrl: "./linear-chart-card.component.html",
  styleUrls: ["./linear-chart-card.component.scss"],
})
export class LinearChartCardComponent implements OnInit {
  @Input() data: ILinearHorizontalChartData;
  @Input() btnSelectorList: { name: string; value: string }[] = [];
  @Input() selectorValue: string = "";
  @Output() selectorBtnClicked = new EventEmitter<{
    name?: string;
    value: string;
  }>();
  constructor() {}

  ngOnInit(): void {}

  onSelectorBtnClick(event) {
    this.selectorBtnClicked.emit({
      name: event.target.name,
      value: event.target.value,
    });
    this.selectorValue = event.target.value;
  }
}
export interface ILinearHorizontalChartDataEntity {
  name: string;
  value: number;
}
export interface ILinearHorizontalChartData {
  name: string;
  series: { name: string; value: number }[];
}
