<!--<mat-toolbar>-->
<!--    <mat-toolbar-row>-->
<!--      <div class="desktop-row full-flex">-->
<!--        <a href="{{landindLink}}" class="logo">-->
<!--          <img src="../../../assets/images/logo.svg" alt="logo">-->
<!--        </a>-->
<!--        <div class="ag-flex-row-stretch">-->
<!--          <div class="m-welcome">-->
<!--            <div class="welcome">-->
<!--              Welcome-->
<!--              <span>{{user.login}}</span><span>, {{user.companyName}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="m-search">-->
<!--            <atom-search [bDarkMode]="true" (onEnter)="toSearch($event)" (textChanged)="searchChanged.next($event)"></atom-search>-->
<!--          </div>-->
<!--          <div class="m-id">-->
<!--            <button [routerLink]="['publisher-id']"  class="btn btn-full publisher-id">-->
<!--              <span>RECEIPTIUM ID</span>-->
<!--              <mat-icon>arrow_right_alt</mat-icon>-->
<!--            </button>-->
<!--          </div>-->

<!--          <div>-->

<!--            <div class="divider">-->
<!--              <div class="container_summary">-->
<!--                <div class="desc">-->
<!--                  <div class="label"> Tokens Value </div>-->
<!--                  <img class="icon" [src]="'/assets/images/Menu/Upper/total_tokens.svg'">-->
<!--                </div>-->

<!--                <div class="value">-->
<!--                  {{user.amount}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="divider">-->
<!--              <div class="container_summary">-->
<!--                <div class="desc">-->
<!--                  <div class="label"> Total Readers </div>-->
<!--                  <img class="icon" [src]="'/assets/images/Menu/Upper/readers.svg'">-->
<!--                </div>-->

<!--                <div class="value">-->
<!--                  {{user.totalReaders}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="divider">-->
<!--              <div class="container_summary">-->
<!--                <div class="desc">-->
<!--                  <div class="label"> Total Items </div>-->
<!--                  <img class="icon" [src]="'/assets/images/Menu/Upper/items.svg'">-->
<!--                </div>-->

<!--                <div class="value">-->
<!--                  {{user.totalItems}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--              <div class="divider last">-->
<!--                <div class="container_summary">-->
<!--                  <div class="desc">-->
<!--                    <div class="label"> Total Items </div>-->
<!--                    <img class="icon" [src]="'/assets/images/Menu/Upper/total_spend.svg'">-->
<!--                  </div>-->

<!--                  <div class="value">-->
<!--                    {{user.totalSpend}} {{currency}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; <div class="grid-wrapper">-->
<!--          <div class="box start-1 end-4">-->
<!--            <div class="welcome">-->
<!--              Welcome-->
<!--              <span>{{user.login}}</span><span>, {{user.companyName}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box start-4 end-7">-->
<!--            <atom-search [bDarkMode]="true" (onEnter)="toSearch($event)" (textChanged)="searchChanged.next($event)"></atom-search>-->
<!--          </div>-->
<!--          <div class="box start-7 end-9">-->
<!--            <button [routerLink]="['publisher-id']"  class="btn btn-full publisher-id">-->
<!--              <span>RECEIPTIUM ID</span>-->
<!--              <mat-icon>arrow_right_alt</mat-icon>-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="box start-9 end-10">-->
<!--            <div class="divider">-->
<!--              <div class="wrapp text-right">-->
<!--                <div class="count">-->
<!--                  {{user.amount}}-->
<!--                </div>-->
<!--                <div class="description">-->
<!--                  Tokens Value-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box start-10 end-11">-->
<!--            <div class="divider">-->
<!--              <div class="wrapp text-right">-->
<!--                <div class="count">-->
<!--                  {{user.totalReaders}}-->
<!--                </div>-->
<!--                <div class="description">-->
<!--                  Total Readers-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box start-11 end-12">-->
<!--            <div class="divider">-->
<!--              <div class="wrapp text-right">-->
<!--                <div class="count">-->
<!--                  {{user.totalItems}}-->
<!--                </div>-->
<!--                <div class="description">-->
<!--                  Total Items-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="box start-12 end-13">-->
<!--              <div class="divider last">-->
<!--                  <div class="wrapp">-->
<!--                      <div class="count">-->
<!--                          {{user.totalSpend}}-->
<!--                      </div>-->
<!--                      <div class="description">-->
<!--                          Total Spend-->
<!--                      </div>-->
<!--                  </div>-->
<!--                  <span class="description valute">{{currency}}</span>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>         &ndash;&gt;-->
<!--        <div class="right full-flex">-->
<!--          <a [routerLink]="['my-profile']" class="flex-center">-->
<!--            <app-image-viewer *ngIf="!avatarImage"></app-image-viewer>-->
<!--            <img *ngIf="avatarImage" [src]='avatarImage' alt="">-->
<!--            <span>My profile</span>-->
<!--          </a>-->
<!--          <button (click)="logout()" class="btn-icon"><img src="../../../assets/images/logout.svg" alt=""></button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="mobile-row">-->
<!--        <img src="../../../assets/images/logo-mini-white.svg" alt="">-->
<!--        <div class="btn-wrapp">-->
<!--          <button-->
<!--            class="menu-btn"-->
<!--            (click)="mobileMenuEvent.emit({user: user, avatarImage: avatarImage})"-->
<!--            type="button"-->
<!--            aria-label="Toggle sidenav"-->
<!--            mat-icon-button>-->
<!--            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-toolbar-row>-->
<!--  </mat-toolbar>-->


<mat-toolbar>
  <mat-toolbar-row>
    <div class="desktop-row full-flex ag-w-100">
      <a href="{{landingLink}}" class="logo flex" >
        <img src="../../../assets/images/logo.svg" alt="logo">
      </a>
      <div class="grid-wrapper ag-w-100">
        <div class="box start-1 end-4" style="display: inline-flex; align-items: center; justify-content: center">
          <atom-search [bDarkMode]="true" (onEnter)="toSearch($event)" (textChanged)="searchChanged.next($event)"></atom-search>
        </div>
        <div class="box start-6 end-13">
          <app-stats class="stats" [data]='stats' [theme]="'blue'" [useLastSeparator]='true'></app-stats>
        </div>
      </div>
      <div class="profile full-flex" style="justify-content: space-around;">
        <a [routerLink]="['my-profile']" class="flex-center">
          <app-image-viewer [imageData]="avatar"></app-image-viewer>
          <div class="user">
            <div>Welcome,</div>
            <div class="ag-t-blue ag-font-16 ">{{login}}</div>
          </div>
        </a>
        <button (click)="logout()" class="btn-icon flex"><img src="../../../assets/images/New design/log-out.svg" alt="logout button"></button>
      </div>
    </div>
    <div class="mobile-row full-flex ag-w-100">
      <a [routerLink]="['/reader/dashboard']" class="flex">
        <img src="../../../assets/images/logo-mini-white.svg" alt="">
      </a>
      <div class="btn-wrapp">
        <button
          class="menu-btn"
          (click)="mobileMenuEvent.emit({login: login, avatar: avatar, stats: stats})"
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button>
          <mat-icon style="color: #fff;" aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
