import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private httpClient: HttpClient) {
  }

  toSearchReader(userUid, words: any) {
    return this.httpClient.post(`${environment.apiUrl}/general/${userUid}/search-reader`, words);
  }

  toSearchPublisher(userUid, words: any) {
    return this.httpClient.post(`${environment.apiUrl}/general/${userUid}/search-publisher`, words);
  }

}
