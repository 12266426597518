<div class="content flex-column">
    <button class="btn-icon close" (click)="this.dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h2>{{data.question}}</h2>
    <p *ngIf="data.text">{{data.text}}</p>
    <div class="bottom flex">
        <button (click)="dialogRef.close()" class="btn btn-red mr-3">{{data.no}}</button>
        <button (click)="dialogRef.close(true)" class="btn btn-green shadow ml-3">{{data.yes}}</button>
    </div>
</div>