import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-filters-mobile',
  templateUrl: './filters-mobile.component.html',
  styleUrls: ['./filters-mobile.component.scss']
})
export class FiltersMobileComponent implements OnInit {
  public columns = [];
  public maxColumns = 3;

  constructor(@Inject (MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FiltersMobileComponent>) {
                this.columns = this.data.columns;
                this.maxColumns = this.data.maxColumns;
              }


  ngOnInit(): void {
  }

  isValid()  {
    const usedColumns = [];
    this.columns.forEach(column => {
      if (column.toUseMobile) usedColumns.push(column);
    });
    if (usedColumns.length > 0 && usedColumns.length < this.maxColumns + 1) {
      return true;
    }
    return false;
  }

}
