import { TooltipTypeReader } from 'src/app/_components/tooltip/tooltips.model';
import { ToastService } from './../../_shared/services/toast.service';
import { Subscription } from 'rxjs';
import { ReceiptsService } from './../../_shared/services/rest-services/receipts.service';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { Component, OnChanges, Input, OnInit } from '@angular/core';

const loadTimeOut = 1000;

@Component({
  selector: 'app-reviews-rating',
  templateUrl: './reviews-rating.component.html',
  styleUrls: ['./reviews-rating.component.scss'],
})

export class ReviewsRatingComponent implements OnChanges, OnInit {

  @Input() companyUid = '';

  public subscription = new Subscription();
  public list = [];
  public pagination = {
    from: 1,
    to: 10,
    totalRows: 10,
  }
  public isLoading: boolean;
  public isRollLoading: boolean;
  public userUid = '';
  public actualSort = 'lastAdded';
  public sortList = [
    {viewValue: 'Last Added', value: 'lastAdded'},
    {viewValue: 'Location ASC', value: 'locationASC'},
    {viewValue: 'Location DESC', value: 'locationDESC'},
    {viewValue: 'Rate ASC', value: 'rateASC'},
    {viewValue: 'Rate DESC', value: 'rateDESC'}
  ];

  public TooltipTypeReader = TooltipTypeReader;

  constructor(private authUserService: AuthUserService,
              private receiptsService: ReceiptsService,
              private toastService: ToastService) {
    this.userUid = this.authUserService.getUserUid();
  }

  ngOnInit(): void {
    this.getList(this.actualSort);
  }

  ngOnChanges(): void {
    // this.getList(this.actualSort);
  }

  getList(sort) {
    this.isLoading = true;
    //console.log('new Get', this.pagination.from, this.pagination.to);
    this.actualSort = sort;
    this.pagination.from = 1;
    this.pagination.to = 10;
    this.subscription.add(this.receiptsService.getReviews(this.userUid, this.actualSort, this.pagination.from, this.pagination.to, this.companyUid)
      .subscribe ((response: any) => {
        this.list = response.result.riviewList;
        this.pagination.totalRows = response.result.totalRows;
        this.isLoading = false;
        }, (err) => {
          this.toastService.warning(err.error.errors[0].message);
    }));
  }

  rollList() {
    this.subscription.add(this.receiptsService.getReviews(this.userUid, this.actualSort, this.pagination.from, this.pagination.to, this.companyUid)
    .subscribe ((response: any) => {
      this.list = [...this.list, ...response.result.riviewList];
      setTimeout(()=> this.isRollLoading = false, loadTimeOut)
      //console.log(this.list);
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }


  onWindowScroll() {
    if(!this.isRollLoading && this.pagination.to < this.pagination.totalRows) {
      this.isRollLoading = true;
      this.pagination.from += 10;
      this.pagination.to += 10;
      this.rollList();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
