export const itemsColumns = [
    {
      fieldName: 'companyAvatarLink',
      title: 'Brand',
      extraValue: 'avatar',
      toUseMobile: true
    },
    {
      fieldName: 'location',
      title: 'Location',
    },
    {
      fieldName: 'productDescription',
      title: 'Product',
      extraValue: 'product',
      toUseMobile: true
    },
    {
      fieldName: 'price',
      title: 'Price',
      extraValue: 'amount',
      toUseMobile: true
    },
    {
      fieldName: 'rating',
      title: 'Rating',
      extraValue: 'rating'
    }
];

export const shoppingListColumns = [
    {
      fieldName: 'companyAvatarLink',
      title: 'Brand',
      extraValue: 'avatar',
      toUseMobile: true
    },
    {
      fieldName: 'productName',
      title: 'Item',
      toUseMobile: true
    },
    {
      fieldName: 'barCodeUrl',
      title: 'Bar Code',
      extraValue: 'avatar',
    },
    {
      fieldName: 'quantity',
      title: 'QTY',
      toUseMobile: true
    },
    {
      fieldName: 'price',
      title: 'Price',
      extraValue: 'amount',
    }
];

export const savedShoppingListColumns = [
  {
    fieldName: 'shoppingListId',
    title: 'Shopping List#',
    toUseMobile: true
  },
  {
    fieldName: 'title',
    title: 'Title',
    toUseMobile: true
  },
  {
    fieldName: 'date',
    title: 'Date',
    toUseMobile: true
  },
];

export const ordersColumns = [
  {
    fieldName: 'shoppingListId',
    title: 'Order#',
    toUseMobile: true
  },
  {
    fieldName: 'title',
    title: 'Title',
    toUseMobile: true
  },
  {
    fieldName: 'date',
    title: 'Date',
    toUseMobile: true
  },
];