import { debounceTime } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { UsersService } from './../../_shared/services/rest-services/users.service';
import { CheckOutComponent } from './../../_dialogs/check-out/check-out.component';
import { MatDialog } from '@angular/material/dialog';
import { AvatarsService } from 'src/app/_shared/services/rest-services/avatars.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService } from 'src/app/_shared/services/menu.service';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { Subscription, Subject } from 'rxjs';
import {HttpProfileService} from '../../_shared/services/http-services/http-profile.service';

const DEBOUNCE_TIME = 800;

@Component({
  selector: 'app-header-publisher',
  templateUrl: './header-publisher.component.html',
  styleUrls: ['./header-publisher.component.scss']
})
export class HeaderPublisherComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  public searchChanged: Subject<any> = new Subject<any>();
  @Output() mobileMenuEvent = new EventEmitter();
  public login = '';
  public avatar = '';
  public stats = [];
  public landingLink = environment.landing;
  public currency = '';

  constructor(private router: Router,
              private menuService: MenuService,
              private httpProfileService: HttpProfileService,
              private authUserService: AuthUserService,
              private avatarsService: AvatarsService,
              private usersService: UsersService) {
      this.currency = this.authUserService.getUserCurrency();
    this.searchChanged
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((data: any) => {
        this.toSearch(data);
      });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (decodeURIComponent(event.url) === '/publisher') {
          this.menuService.selectedPageUrl = '/publisher/dashboard';
        } else {
          this.menuService.selectedPageUrl = decodeURIComponent(event.url);
        }
      }
    });
  }

  ngOnInit() {
    const uid = this.authUserService.getUserUid();
    this.getUserHeaderInfo(uid);
    this.subscription.add(this.menuService.reloadHeader$
      .subscribe ((response: any) => {
          console.log('test');
          this.getUserHeaderInfo(uid);
        }
      ));
  }

  getUserHeaderInfo(uid: string) {
    this.subscription.add(this.usersService.getHeaderPublisherInfo(uid)
      .subscribe ((response: any) => {
          this.login = response.result.login;

          this.stats = [];
          this.stats.push({fieldName: 'Total Tokens',  icon: 'total_tokens.svg',  value: 123, isToken: true, });
          this.stats.push({fieldName: 'Total Spend',   icon: 'total_spend.svg', value: response.result.totalSpend, isAmount: true, });
          this.stats.push({fieldName: 'Readers', icon: 'publishers.svg', value: response.result.totalReaders});
          this.stats.push({fieldName: 'My Items',      icon: 'items.svg', value: response.result.totalItems});
          if (response.result.companyAvatarUid) {
            this.loadAvatar(response.result.companyAvatarUid);
          }
        }
      ));
  }

  loadAvatar(avatarId: any) {
    this.avatarsService.getAvatar(avatarId)
      .subscribe((response: any) => {
        this.avatar = response.link;
      });
  }

  toSearch(searchData) {
    searchData ? this.router.navigate(['reader/search'],  {queryParams: {searchData}}) : null;
  }

  logout() {
    this.authUserService.logout();
    this.router.navigateByUrl('auth');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



  // private subscription: Subscription = new Subscription();
  // public searchChanged: Subject<any> = new Subject<any>();
  // @Output() mobileMenuEvent = new EventEmitter();
  // public user = {
  //   login: '',
  //   companyName: '',
  //   totalReaders: '',
  //   totalItems: '',
  //   totalSpend: '',
  //   amount: '',
  //   companyAvatarUid: ''
  // };
  // public avatarImage = null;
  // public landindLink = environment.landing;
  // public currency = '';
  //
  // constructor(private router: Router,
  //             private dialog: MatDialog,
  //             private menuService: MenuService,
  //             private usersService: UsersService,
  //             private avatarsService: AvatarsService,
  //             private authUserService: AuthUserService) {
  //   this.router.events.subscribe((event: any) => {
  //   this.currency = this.authUserService.getUserCurrency();
  //   this.searchChanged
  //     .pipe(debounceTime(DEBOUNCE_TIME))
  //     .subscribe((data: any) => {
  //       this.toSearch(data);
  //   });
  //   if (event instanceof NavigationEnd) {
  //     if (decodeURIComponent(event.url) === '/') {
  //       this.menuService.selectedPageUrl = '/publisher/dashboard';
  //     } else {
  //       this.menuService.selectedPageUrl = decodeURIComponent(event.url);
  //     }
  //   }
  //   });
  // }
  //
  // ngOnInit() {
  //   const uid = this.authUserService.getUserUid();
  //   this.getUserHeaderInfo(uid);
  //   this.subscription.add(this.menuService.reloadHeader$
  //     .subscribe ((response: any) => {
  //       console.log('reload');
  //       this.getUserHeaderInfo(uid);
  //       }
  //   ));
  // }
  //
  // getUserHeaderInfo(uid) {
  //   this.subscription.add(this.usersService.getHeaderPublisherInfo(uid)
  //     .subscribe ((response: any) => {
  //       this.user = response.result;
  //       if (this.user.companyAvatarUid) {
  //         this.loadAvatar(this.user.companyAvatarUid);
  //       }
  //     }
  //   ));
  // }
  //
  // loadAvatar(avatarId: any) {
  //   this.avatarsService.getAvatar(avatarId)
  //     .subscribe((response: any) => {
  //       this.avatarImage = response.link;
  //     });
  // }
  //
  //
  //
  // goToDashboard() {
  //   this.router.navigate(['/']);
  // }
  //
  // logout() {
  //   this.authUserService.logout();
  //   this.router.navigateByUrl('auth');
  // }
  //
  // toSearch(searchData) {
  //   this.router.navigate(['publisher/search'],  {queryParams: {searchData}});
  // }
  //
  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
