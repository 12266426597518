// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  beta: false,
  sFormsStaticDateFrom: "01/01/2019 ",
  //apiUrl: 'http://localhost:3020/api',
  apiUrl: "https://papi.receiptium.com/api",
  assetsUrl: "https://papi.receiptium.com/assets/",
  walletUrl: 'https://wallet.receiptium.com/#',
  //  walletUrl: "http://localhost:4401/#",
  wsUrl: "wss://papi.receiptium.com",
  landing: "http://pdash.receiptium.com",

  VERSION: "1.0.0",
  googleMapsApiKey: 'AIzaSyCESX2MJ_cWA4XUBheDSwi96Ud7WkEQXOE'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

