import { DateFilter, DateFilterRequest } from './../../../_models/api.model';
import { SortFilterPaginationRequest } from './../../interfaces/old-pagination.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { formatDate } from '@angular/common';
import { PaginationRequest } from 'src/app/_models/api.model';

@Injectable({
  providedIn: 'root'
})
export class HttpReceiptService {

  backendApiUrl = ''

  constructor(private httpClient: HttpClient, private authUserService: AuthUserService) {
    this.backendApiUrl = environment.apiUrl;
  }

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  getUserReceiptsHeader() {
    return this.httpClient.get(this.backendApiUrl+'/users/my-receipts-header/'+this.userUid);
  }

  getUserReceiptsList({dateFrom = new Date(), dateTo = new Date(), amountFilter = '', ratingFilter = '', rowsFrom = 1, rowsTo = 100, orderBy = 'receiptMonthDays', orderDirection = '', filterValue = ''}) {
    return this.httpClient.get(this.backendApiUrl+'/users/my-receipts/'+this.userUid, {
      params: {dateFrom: this.convertDateForBackend(dateFrom), dateTo: this.convertDateForBackend(dateTo) ,amountFilter, ratingFilter, rowsFrom: rowsFrom.toString(), rowsTo: rowsTo.toString(), orderBy, orderDirection, filterValue }
    });
  }

  rateItem(itemDescriptionId: number, rating: any ) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/products/${itemDescriptionId}`, rating);
  }

  getUserItemsList(rowsFrom: string, rowsTo :string, dateFrom?: string, dateTo?: string, orderBy?: string, orderDirection?: string, filterValue?: string) {
    const params = {
      rowsFrom, rowsTo,
      dateFrom: dateFrom ? dateFrom : (formatDate(new Date(new Date(2000, 0, 1)), 'yyyy/MM/dd', 'en')),
      dateTo: dateTo ? dateTo : (formatDate(new Date, 'yyyy/MM/dd', 'en')),
      orderBy: orderBy ? orderBy : '',
      orderDirection: orderDirection ? orderDirection : '',
      filterValue: filterValue ? filterValue : ''
    }
    return this.httpClient.get(`${environment.apiUrl}/users/my-items/${this.userUid}`, {params});
  }

  getUserExpensedReceiptList({receiptUid = '', rowsFrom = '1', rowsTo = '10', orderBy = '', orderDirection = ''}) {
    return this.httpClient.get(this.backendApiUrl + '/users/receipt-expense/' + receiptUid, {
      params: {rowsFrom, rowsTo, orderBy, orderDirection  }
    });
  }

  getMyExpenses(sortFilterPaginationRequest: SortFilterPaginationRequest, dateFilter: DateFilterRequest) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/expenses`, {
      params: {...sortFilterPaginationRequest, ...dateFilter}
    });
  }

  getUserReceipt({receiptUid = '', rowsFrom = '1', rowsTo = '100', orderBy = '', orderDirection = ''}) {
    return this.httpClient.get(this.backendApiUrl+'/users/receipt/' + receiptUid, {
      params: {rowsFrom, rowsTo, orderBy, orderDirection  }
    });
  }

  getUnifiedReceipts() {
    return this.httpClient.get(this.backendApiUrl + `/users/unified/receipts?filterValue=&amountFilter=100000&dateFrom=${new Date(environment.sFormsStaticDateFrom).toISOString()}&dateTo=${new Date((new Date().getTime() + 60 * 60 * 24)).toISOString()}&rowsFrom=1&rowsTo=700&orderBy=date&orderDirection=desc`);
  }

  getUserReceiptLoyalityPoints({receiptUid = '', rowsFrom = '1', rowsTo = '1', orderBy = '', orderDirection = ''}) {
    return this.httpClient.get(this.backendApiUrl+'/users/loyality/' + receiptUid, {
      params: {rowsFrom, rowsTo, orderBy, orderDirection  }
    });
  }

  getUserCurrentReceiptInfo(receiptUid: string) {
    return this.httpClient.get(`${this.backendApiUrl}/users/${this.userUid}/current-receipt-info`, {
      params: { currentUid: receiptUid }
    });
  }

  getReceiptInfo(receiptUid: string) {
    return this.httpClient.get(`${this.backendApiUrl}/users/receipt-info/${receiptUid}`);
  }

  receiptChangeLockStatus({receiptUid = '', setLock = true, password = ''}) {
    return this.httpClient.put(`${this.backendApiUrl}/users/${this.userUid}/receipt/${receiptUid}/lock`, {
      isLock: setLock,
      password
    });
  }

  receiptGenerateExpences(receiptUid: string, itemsIdList) {
    return this.httpClient.put(`${this.backendApiUrl}/users/receipt/${receiptUid}/expense`, {
      itemsIdList
    });
  }

  getReceiptOverallRating(receiptUid: string) {
    return this.httpClient.get(this.backendApiUrl+'/users/' + this.userUid + '/rate-receipt/' + receiptUid);
  }

  setReceiptOverallRating(receiptUid: string, iNewRating: number) {
    return this.httpClient.post(this.backendApiUrl+'/users/' + this.userUid + '/rate-receipt/' + receiptUid, {
      rating: iNewRating
    });
  }

  // Dashboard
  getTotalSpendByPublisher(sortBy: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/company-list?sortBy=${sortBy}`);
  }
  getDetailedReport() {
    return this.httpClient.get(this.backendApiUrl+'/users/' + this.userUid + '/detail-raport');
  }
  getProductRecall(rowsFrom: number, rowsTo: number, orderBy = 'startDate', orderDirection = 'desc') {
    return this.httpClient.get(`${environment.apiUrl}/users/products-recall?rowsFrom=${rowsFrom}&rowsTo=${rowsTo}&orderBy=${orderBy}&orderDirection=${orderDirection}`);
  }
  getMostBoughtItems(paginationReq: PaginationRequest) {
    return this.httpClient.get(`${environment.apiUrl}/users/dashboard/most-bought-products`, {params: {...paginationReq}});
  }
  getRequestReceipts() {
    return this.httpClient.get(`${environment.apiUrl}/publisher/${this.userUid}/others`);
  }
  sendRequestReceipt(companyList: number[]) {
    return this.httpClient.post(`${environment.apiUrl}/publisher/${this.userUid}/send-notification`, {companyList});
  }

  private convertDateForBackend(date) {
    const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return dateUTC.toJSON().slice(0,10).replace(/-/g,'/')
  }
}
