import { ToastService } from 'src/app/_shared/services/toast.service';
import { HttpReceiptService } from 'src/app/_shared/services/http-services/http-receipts.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { unlockReceipt } from 'src/app/_shared/logic/receipts-logic';

@Component({
  selector: 'app-one-receipt',
  templateUrl: './one-receipt.component.html',
  styleUrls: ['./one-receipt.component.scss']
})
export class OneReceiptComponent {

  @Input() oReceipt;
  @Input() sCurrency;
  @Input() bIsSmallSize = false;
  @Input() bHideBorder = false;

  constructor(private router: Router,
              private dialog: MatDialog,
              private httpReceiptService: HttpReceiptService,
              private toastService: ToastService) {}

  // goToReceipt(sReceiptUid: string) {
  //   this.router.navigate(['reader/receipt', sReceiptUid])
  // }

  openReceipt() {
    if (this.oReceipt.status !== 'lock') {
      this.router.navigate(['reader/receipt', this.oReceipt.receiptUid])
    } else {
      unlockReceipt(this.oReceipt.receiptUid, this.dialog, this.httpReceiptService)
        .then(()=> {
          this.oReceipt.status = 'open'
          this.openReceipt();
        })
        .catch(err=> {
          if (err) {
            this.toastService.warning(err);
          }
        })
    }
  }
}
