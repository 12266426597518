<div class="content">
    <app-filter-table
        *ngIf="analiticsData.length>0"
        class="analitics-table"
        [filtersOff]='true'
        [data]="analiticsData"
        [onRowClick]="openDetails"
        [columns]="columns">
    </app-filter-table>
    <div *ngIf="analiticsData.length==0" class="empty">
        <img src="../../.././../assets/images/AdCreator/adIcon.png" alt="">
        <h1>No Ads Yet</h1>
    </div>
</div>
