import { formatDate } from '@angular/common';
import { PublisherTopUpComponent } from './../../../_dialogs/publisher-top-up/publisher-top-up.component';
import { ConfirmDialogComponent } from './../../../_dialogs/confirm-dialog/confirm-dialog.component';
import { AdConfirmComponent } from './../_dialogs/ad-confirm/ad-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { DictionariesService } from './../../../_shared/dictionaries/dictionaries.service';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { ToastService } from './../../../_shared/services/toast.service';
import { AdEventService } from './../_services/ad-event.service';
import { Subscription } from 'rxjs';
import { AdService } from './../../../_shared/services/rest-services/ad.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ad-creator',
  templateUrl: './ad-creator.component.html',
  styleUrls: ['./ad-creator.component.scss']
})
export class AdCreatorComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  @Output() sentEvent = new EventEmitter();
  public form: FormGroup;
  public sent: boolean;
  public childForm;
  public childFormValidate: boolean;
  public today = new Date();
  public durationDays: any;
  public emptyBlob = new Blob([''], {type: `image/png`});
  public dailyBudgetTest = 100;
  public adTypes: any = {
      image: '',
      video: '',
      carousele: ''
    };
  public adSizes = [];
  public targetPages = [];
  public isLoad: boolean;

  constructor(private adService: AdService,
              public dialog: MatDialog,
              private authUserService: AuthUserService,
              public toastService: ToastService,
              public dictionariesService: DictionariesService,
              public adEventService: AdEventService) {
                this.subscription.add(this.adEventService.childForm$
                  .subscribe ((form: any) => {
                    this.childForm = form;
                }));
                this.subscription.add(this.adEventService.childFormValid$
                  .subscribe ((status: any) => {
                    this.childFormValidate = status;
                }));
                this.subscription.add(this.dictionariesService.getAdTypes()
                  .subscribe ((res: any) => {
                    this.adTypes.image = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'image');
                    this.adTypes.video = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'video');
                    this.adTypes.carousel = res.result.wordsFromDictionary.find(type => type.groupNameFullName === 'carousel');
                }));
              }

  ngOnInit(): void {
    this.createForm();
    this.getAdSizes();
  }

  createForm() {
    this.form = new FormGroup({
      adType: new FormControl (null, [Validators.required]),
      size: new FormControl (null, [Validators.required]),
      page: new FormControl (null, [Validators.required]),
      url: new FormControl (null, [Validators.required]),
      targetGroups: new FormControl (null),
      fromDate: new FormControl (null, [Validators.required]),
      toDate: new FormControl (null, [Validators.required]),
    });
    // dayDuration
    this.subscription.add(this.form.get('fromDate')
    .valueChanges
    .subscribe(fromDate => {
      this.durationDays = fromDate && this.form.get('toDate').value ?
        ((this.form.get('toDate').value - fromDate) / 1000 / 60 / 60 / 24) + 1 : null;
    }));
    this.subscription.add(this.form.get('toDate')
      .valueChanges
      .subscribe(toDate => {
        this.durationDays = toDate && this.form.get('fromDate').value  ?
          ((toDate - this.form.get('fromDate').value) / 1000 / 60 / 60 / 24) + 1 : null;
    }));
    this.subscription.add(this.form.get('size')
      .valueChanges.subscribe((size: any) => {
      if (size) {
        this.form.get('page').patchValue(null);
        this.getTargetPages(size);
      }
    }));
    this.subscription.add(this.form.get('adType')
      .valueChanges.subscribe((size: any) => {
        this.adEventService.updateChildForm('');
    }));
    // Form update
    this.subscription.add(this.form.valueChanges
      .subscribe(value => {
        this.adEventService.updateMainForm(value);
    }));
  }

  getAdSizes() {
    this.subscription.add(this.adService.getAdSizes()
    .subscribe ((response: any) => {
      this.adSizes = response.result.sizeList;
    }));
  }

  getTargetPages(size) {
    this.subscription.add(this.adService.getTargetPages(size.width, size.height)
    .subscribe ((response: any) => {
      this.targetPages = response.result.pageList;
    }));
  }

  get adType() {
    return this.form.get('adType');
  }

  createImageData()  {
    const img = {
      heading: this.childForm.heading,
      text : this.childForm.text,
      price : this.childForm.price
    };
    return img;
  }

  createCarouselData() {
    const img = [];
    this.childForm.slides.forEach(slide => {
      img.push({
        price: slide.price,
        text: slide.text,
        heading: slide.heading
      });
    });
    const carousel = {
      duration: 3000,
      img
    };
    return(carousel);
  }

  createVideoData() {
    const video = {
      link: this.childForm.video
    };
    return (video);
  }

  generatePromote() {
    const generateSubscription = new Subscription();
    this.isLoad = true;
    const fd = new FormData();
    fd.append('typeId', this.adType.value);
    fd.append('url', this.form.get('url').value);
    fd.append('width', this.form.get('size').value.width);
    fd.append('height', this.form.get('size').value.height);
    fd.append('targetPageId', this.form.get('page').value);
    fd.append('durationStart', formatDate(this.form.get('fromDate').value, 'yyyy/MM/dd', 'en'));
    fd.append('durationEnd', formatDate(this.form.get('toDate').value, 'yyyy/MM/dd', 'en'));
    fd.append('currency',  'GPB');
    fd.append('dailyBudget', this.dailyBudgetTest.toString());
    switch (this.adType.value) {
      case this.adTypes.image.id:
        fd.append('img', JSON.stringify(this.createImageData()));
        this.adEventService.getFinalBlobEvent$.next();
        generateSubscription.add(this.adEventService.finalBlob$
          .subscribe ((finalBlob: Blob) => {
            fd.append('files', finalBlob[0]);
            fd.append('files', this.childForm.imageBlob ? this.childForm.imageBlob : this.emptyBlob);
            fd.append('files', this.childForm.logoBlob ? this.childForm.logoBlob : this.emptyBlob);
            this.toPromote(fd);
            generateSubscription.unsubscribe();
        }));
        break;
      case this.adTypes.carousel.id:
        fd.append('carousel', JSON.stringify( this.createCarouselData()));
        this.adEventService.getFinalBlobEvent$.next();
        generateSubscription.add(this.adEventService.finalBlob$
          .subscribe ((finalBlob: []) => {
            for (let i = 0; i < finalBlob.length; i++) {
              fd.append('files', finalBlob[i]);
              fd.append('files', this.childForm.slides[i].imageBlob ? this.childForm.slides[i].imageBlob : this.emptyBlob);
              fd.append('files', this.childForm.logoBlob ? this.childForm.logoBlob : this.emptyBlob);
            }
            this.toPromote(fd);
            generateSubscription.unsubscribe();
        }));
        break;
      case this.adTypes.video.id:
        fd.append('video', JSON.stringify( this.createVideoData()));
        this.toPromote(fd);
        break;
    }
  }

  toPromote(fd) {
    this.subscription.add(this.adService.toPromoteAd(this.authUserService.getUserUid(), fd)
      .subscribe ((response: any) => {
        this.isLoad = false;
        this.adConfirm();
        }, (err: any) => {
          this.isLoad = false;
          const error = err.error.errors[0].message;
          error.includes('enough') ? this.recharge(error.split(" ")[1]) :
            this.toastService.warning(error);
    }));
  }

  recharge(amount) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      backdropClass: 'dialog-back',
      width: '500px',
      data: {
        question: 'Insufficient Tokens!',
        text: "You're missing " + amount + " Tokens to start this Ad.",
        yes: 'Top Up',
        no: 'Cancel'
      }
    });
    this.subscription.add(confirmDialog.afterClosed()
      .subscribe(result => {
        if (result) {
          this.dialog.open(PublisherTopUpComponent, {
            disableClose: true,
            backdropClass: 'dialog-back',
            width: '500px',
            data: {amount}
          });
        }
    }));
  }

  adConfirm() {
    const confirmDialog = this.dialog.open(AdConfirmComponent, {
      disableClose: false,
      backdropClass: 'dialog-back',
      width: '280px'
    });
    setTimeout(() => {
      this.sentEvent.emit();
    }, 500);
  }

  toClearForm() {
    this.adEventService.updateMainForm('');
    this.adEventService.updateChildForm('');
    this.createForm();
    this.sent = true;
    setTimeout(() => {
      this.sent = false;
    }, 1);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
