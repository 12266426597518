<div class="content">
  <div class="head">
    <input *ngIf="isSafari" nbInput #filterFake class="safari-focus" fullWidth matInput name="filter">
    <div class="ag-flex-row-between ag-flex-align-center">
      <a [routerLink]="['my-profile']"  class="ag-flex-align-center">
        <app-image-viewer [imageData]="user?.avatar"></app-image-viewer>
        <div class="user-info ml-4">
          <p>Welcome,</p>
          <div class="ag-t-blue ag-font-16">{{user?.login}}</div>
        </div>
      </a>
      <button (click)="toggleEvent.emit()" class="close"><mat-icon>close</mat-icon></button>
    </div>
    <app-stats-mobile class="mt-5" [list]='user?.stats' [size]="'sm'" [theme]="'blue'"></app-stats-mobile>
  </div>
  <div class="menu-categories mt-1">
    <aside class="category" *ngFor="let category of menuList">
      <button  class="menu-item ag-flex-align-center" [disabled]='category.disabled' [routerLink]="[category.route]"
        [ngClass]="{'active': menuService.selectedPageUrl.includes(category.route)}" (click)="toggleEvent.emit()">
        <svg-icon class="ag-flex-row"
                  src="../../../../assets/images/New menu/Readers/{{category.iconName}}">
        </svg-icon>
        <p style="margin-left: 10px;" class="ag-font-16">{{category.displayName}}</p>
        <div *ngIf="category.displayName.includes('Messages')&&unreadMessages>0 || category.displayName.includes('Support')&&unreadSupport>0"
                class="red-point">
        </div>
      </button>
    </aside>
    <!-- Log out -->
    <aside class="category">
      <button class="menu-item ag-flex-align-center" (click)="logout()">
        <svg-icon class="ag-flex-row logout"
                  src="../../../../assets/images/New design/log-out.svg">
        </svg-icon>
        <p style="margin-left: 16px;" class="ag-font-16">Log Out</p>
      </button>
    </aside>
  </div>
  <div class="bottom mt-5" style="max-width: 330px;">
    <atom-search [bDarkMode]="true" (onEnter)="toSearch($event)"></atom-search>
    <app-menu-footer class="footer"></app-menu-footer>
  </div>
</div>
