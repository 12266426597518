import { Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "app-chart1d-horizontal",
  templateUrl: "./chart1d-horizontal.component.html",
  styleUrls: ["./chart1d-horizontal.component.scss"],
})
export class Chart1dHorizontalComponent implements OnInit {
  @Input() data: any;
  @Input() average;
  @Input() title: string;
  @Input() selectName: string;
  @Input() selectValues = [];
  @Input() type = "bar";
  @Input() totalValue = 0;
  @Input() yAxis: boolean = true;
  @Input() xAxis: boolean = true;
  @Input() showYAxisLabel: boolean = false;
  @Input() showGridLines: boolean = true;
  @Input() scheme: { domain: string[] } = { domain: ["#44678a"] };
  @Input() barPadding: number = 30;
  @Input() isHeadHidden: boolean = false;
  @Input() view: number[] | undefined = undefined;
  @Input() legend: boolean = false;
  @Input() activeEntries: any;
  customColors: [{ name: string; value: string }] | [] = [];
  constructor() {}

  ngOnInit(): void {
    //Creates a version of activeEntires which allows us to change the color
    this.customColors = this.activeEntries.map((entry) => {
      return { name: entry.name, value: entry.customColor };
    });
  }
}
