import { FormGroup } from '@angular/forms';
import { BasePageComponent } from 'src/app/pages-reader/base-page/pase-page.component';
import { Component, OnInit, ViewChild, ElementRef, HostListener, HostBinding, AfterViewInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-manage-ads',
  templateUrl: './manage-ads.component.html',
  styleUrls: ['./manage-ads.component.scss']
})
export class ManageAdsComponent extends BasePageComponent implements OnInit {
  public activeTab = 0;
  public analiticsDetailsData;

  constructor(public el: ElementRef,
              public location: Location) {
    super('Manage Advertisment');
  }

  ngOnInit(): void {}

  changeActualTab(event) {
    this.activeTab = event;
    this.analiticsDetailsData = null;
  }

}
