import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'atom-widget-button-expand',
  templateUrl: './button-expand.component.html',
  styleUrls: ['./button-expand.component.scss']
})
export class AtomButtonExpandComponent  {

  @Input() isExpanded = false;
  @Input() bShowButton = true;
  @Output() onExpandChange = new EventEmitter<void>();

}
