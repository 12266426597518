import { DictionariesService } from './../../../_shared/dictionaries/dictionaries.service';
import { ToastService } from './../../../_shared/services/toast.service';
import { Subscription } from 'rxjs';
import { AdService } from './../../../_shared/services/rest-services/ad.service';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  public userUid: string;
  public targetPage: any;
  public targetPages = [];
  public currency = '';
  public periods = [
    {groupNameFullName: 'Year', id: 'year'},
    {groupNameFullName: 'Month', id: 'month'},
    {groupNameFullName: 'Week', id: 'week'},
    {groupNameFullName: 'Day', id: 'day'},
  ];
  public headerData = [];
  public totalSession;
  public sessionsData = {
    sessions: [],
    average: '',
    avgPerSession: '',
    period: '',
    totalSession: 0
  };
  public clicksData = {
    clicks: [],
    average: '',
    avgPerClick: '',
    period: '',
    totalClicks: 0
  };

  constructor(public toastService: ToastService,
              public dictionariesService: DictionariesService,
              private authUserService: AuthUserService,
              private adService: AdService) {
                this.currency = this.authUserService.getUserCurrency();
                this.userUid = this.authUserService.getUserUid();
                this.sessionsData.period = this.periods[0].id;
                this.clicksData.period = this.periods[0].id;
                this.subscription.add(this.dictionariesService.getAdPages()
                  .subscribe ((response: any) => {
                    const targetPage = {
                      id: -1,
                      groupNameFullName: 'All Pages'
                    };
                    this.targetPage = targetPage.id;
                    this.targetPages = response.result.wordsFromDictionary;
                    this.targetPages.unshift(targetPage);
                    this.getData();
                }));
              }

  ngOnInit(): void {
  }

  getData() {
    this.getSessions();
    this.getClicks();
  }

  getSessions(period?: any) {
    period ? this.sessionsData.period = period : null;
    this.subscription.add(this.adService.getAnaliticsCharts(this.userUid, this.sessionsData.period, this.targetPage, 0)
      .subscribe ((response: any) => {
        this.sessionsData.sessions = response.result.sessions;
        this.sessionsData.average = response.result.sessionAvg;
        this.sessionsData.avgPerSession = response.result.avgPerSession;
        this.sessionsData.totalSession =  response.result.totalSession;
        this.setHeaderData(response.result)
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }

  getClicks(period?: any) {
    period ? this.clicksData.period = period : null;
    this.subscription.add(this.adService.getAnaliticsCharts(this.userUid, this.clicksData.period, this.targetPage, 0)
      .subscribe ((response: any) => {
        this.clicksData.clicks = response.result.clicks;
        this.clicksData.average = response.result.clickAvg;
        this.clicksData.avgPerClick = response.result.avgPerClick;
        this.clicksData.totalClicks = response.result.totalClicks;
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }

  setHeaderData(data) {
    this.headerData.push({fieldName: 'Total Ads', value: data.totalAds});
    this.headerData.push({fieldName: 'Total Clicks', value: data.totalClicks});
    this.headerData.push({fieldName: 'Total Session', value: data.totalSession});
    this.headerData.push({fieldName: 'Total Spend', isAmount: true,  value: data.totalSpent});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
