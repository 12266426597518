<header style="margin: 1rem auto; width: 430px">
  <h3>Wallet <app-tooltip [key]="'wallet'"></app-tooltip></h3>
</header>

<iframe
  *ngIf="urlForIframe"
  style="
    width: 430px;
    height: 600px;
    border: none;
    display: inline;
    z-index: 100;
  "
  [src]="urlForIframe"
  class="wallet-iframe"
></iframe>
