import { TooltipTypeReader } from 'src/app/_components/tooltip/tooltips.model';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { HttpReceiptService } from 'src/app/_shared/services/http-services/http-receipts.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

const PER_PAGE  = 5;

@Component({
  selector: 'widget-product-recall',
  templateUrl: './product-recall.component.html',
  styleUrls: ['./product-recall.component.scss'],
})
export class WidgetProductRecallComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  bLoading = false;
  isRollLoading = false
  iCountAllRecall = 0;
  aRecallList = [];

  public TooltipTypeReader = TooltipTypeReader;


  constructor(private httpReceiptService: HttpReceiptService,
              private toastService: ToastService) {
    this.getData();
  }

  ngOnInit(): void {}

  getData() {
    if(this.bLoading) { return null }
    const iLastRow = this.aRecallList.length;
    const rowsFrom = iLastRow + 1;
    const rowsTo = iLastRow + PER_PAGE;
    this.bLoading = true;
    this.subscription.add(this.httpReceiptService.getProductRecall(rowsFrom, rowsTo) 
      .subscribe ((response: any) => {
        this.aRecallList = [...this.aRecallList, ...response.result.list];
        this.iCountAllRecall = response.result.totalRows;
        this.bLoading = false;
        this.isRollLoading = false;
    }, (err) => {
      this.bLoading = false;
      this.isRollLoading = false;
      this.toastService.warning(err.error.errors[0].message);
    }));

  }

  onListScroll() {
    if(this.iCountAllRecall <= this.aRecallList.length) { return null; }
    this.isRollLoading = true;
    this.getData()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
