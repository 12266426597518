<div class="ag-pop-up" style="text-align: left;">
    <button class="btn-icon close" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h1>Filters</h1>
    <p class="mt-1">Choose only {{maxColumns}} filters</p>
    <div class="items-wrapp">
        <div *ngFor="let column of columns" class="item full-flex">
            <span>{{column.title}}</span>
            <mat-checkbox [disabled]='column.extraValue==="select"||column.extraValue=="strikethrough"||column.extraValue==="checkbox"' (change)="column.toUseMobile? column.toUseMobile = false : column.toUseMobile = true"
                           [checked]='column.toUseMobile'></mat-checkbox>
        </div>
    </div>
    <div class="bottom full-flex mt-5">
        <button (click)="dialogRef.close()" class="btn btn-gray">Cancel</button>
        <button (click)="dialogRef.close(columns)" [disabled]='!isValid()' class="btn btn-green">Accept</button>
    </div>
</div>