import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class AdEventService {

public mainForm$ = new Subject<any>();
public childForm$ = new Subject<any>();
public childFormValid$ = new Subject<any>();
public getFinalBlobEvent$ = new Subject<any>();
public finalBlob$ = new Subject();

  updateMainForm(form: any) {
    this.mainForm$.next(form);
  }

  updateChildForm(form: any) {
    this.childForm$.next(form);
  }
  updateChildFormValidation(status: boolean) {
    this.childFormValid$.next(status);
  }

  generateFinalBlob() {
    this.getFinalBlobEvent$.next();
  }

  sendFinalBlob(finalBlob) {
    this.finalBlob$.next(finalBlob);
  }

}
