import { ToastService } from './../../_shared/services/toast.service';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

const MAX_SIZE = 10 * 1024 * 1024; // 10MB

@Component({
  selector: 'app-add-attachments',
  templateUrl: './add-attachments.component.html',
  styleUrls: ['./add-attachments.component.scss']
})
export class AddAttachmentsComponent implements OnInit {
  @Output() filesUpdate = new EventEmitter();
  @ViewChild('FileInput', { static: false }) FileInput: ElementRef;
  public files = [];

  constructor(private toastService: ToastService) { }

  ngOnInit() {
  }

  fileSelected(event) {
    for (const file of event) {
      if (file.size <= MAX_SIZE) {
        this.files.push(file);
        this.filesUpdate.emit(this.files);
      } else {
        this.toastService.warning(`Maximum file size exceed. Max file size is 10 MB.`);
      }
    }
  }

  clickInput() {
    this.FileInput.nativeElement.click();
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }

}
