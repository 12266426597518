import { AuthUserService } from '../../../@auth/auth-user.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-support-left-menu',
  templateUrl: './support-left-menu.component.html',
  styleUrls: ['./support-left-menu.component.scss']
})
export class SupportLeftMenuComponent implements OnInit {
  @Input() list = [];
  @Input () listCount = 0;
  @Input () activeTicket;
  @Input () isClosed: boolean;
  @Output() clickItemEvent = new EventEmitter();
  @Output() replyEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() newTicketEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
  }

  clickItem(ticket) {
    this.clickItemEvent.emit(ticket);
  }

  doAction(event, item) {
    if (event.action === 'trash') {
      this.closeEvent.emit(item);
    }
    if (event.action === 'edit') {
      this.replyEvent.emit(item);
    }
  }

}
