import { IOldPagination } from 'src/app/_shared/interfaces/old-pagination.model';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { convertPagination } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class HttpSupportService {
  constructor(private httpClient: HttpClient,
              private authUserService: AuthUserService) {}

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  createTicket(message) {
    return this.httpClient.post(`${environment.apiUrl}/users/${this.userUid}/tickets`, message);
  }

  getTicketsByCategory(category: string, pagination: IOldPagination) {
    const pag = convertPagination(pagination)
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/tickets-list`,
    {params: {category, rowsFrom:pag.rowsFrom, rowsTo:pag.rowsTo}});
  }

  getTicket(ticketNumber: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/tickets/${ticketNumber}`);
  }

  closeTicket(ticketNumber: string, isOpen: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/tickets/${ticketNumber}`, isOpen);
  }

  changeReadStatus(ticketNumber: string, isRead: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/tickets-read/${ticketNumber}`, isRead);
  }

  getUnreadCount() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/tickets/count-unread`);
  }

  getTicketList(category: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/tickets-list`,
    {params: {category, rowsFrom:'1', rowsTo:'100'}});
  }

  changeStatus(ticketNumber: string, isOpen: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/tickets/${ticketNumber}`, isOpen);
  }

}
