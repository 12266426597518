<div *ngIf="feedback" class="content">
    <div  class="wrapper">
        <div class="header">
            <div class="head full-flex">
                <div class="left full-flex">
                    <p class="topic">Feedback on receipt No: <span class="ag-action" (click)="openReceipt()">{{feedback.receiptId}}</span></p>
                    <div class="stars"> <star-rating [showHalfStars]='true'  [readOnly]="true" [starType]="'svg'" [rating]="feedback.avarageRating"></star-rating></div>
                </div>
                <p class="date">{{feedback.sendDate}}</p>
            </div>
            <p class="from flex">
                <span class="field-name"> From:</span> {{feedback.from}}
            </p>
        </div>
        <div class="body">
            <div class="answers-wrapp mb-5">
                <div *ngFor="let row of feedback.questionList" class="row full-flex mb-2">
                    <p class="question">{{row.title}}</p>
                    <div class="rating"> <star-rating [showHalfStars]='true' [readOnly]="true" [starType]="'svg'" [rating]="row.rating"></star-rating></div>
                </div>
            </div>
            <ng-container *ngIf="feedback.message">
                <p class="comment">Comment:</p>
                <textarea class="message-text" [innerHTML]="feedback.message" readonly></textarea>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="!feedback" class="empty">
    <img src="../../../../assets/images/Messages/click-message2.png" alt="">
</div>
