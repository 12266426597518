import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() average;
  @Input() title: string;
  @Input() selectName: string;
  @Input() selectValues = [];
  @Input() type = 'bar';
  @Input() totalValue = 0;
  @Input() yAxis: boolean = true;
  @Input() xAxis: boolean = true;
  @Input() showYAxisLabel: boolean = false;
  @Input() showGridLines: boolean = true;
  @Input() scheme: {domain: string[]} = { domain: ['#44678a'] };
  @Input() barPadding: number = 30;
  @Input() isHeadHidden: boolean = false;
  @Input() view: number[] | undefined = undefined;
  @Input() legend: boolean = false;
  @Output() updatePeriod = new EventEmitter();
  public selectValue;

  constructor() { }

  ngOnInit(): void {
    if(this.selectValue && this.selectValue[0].id) this.selectValue = this.selectValues[0].id;
    setTimeout(()=>{console.log('data', this.data)},3000)
  }

  ngOnChanges() {
  }

}
