import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atom-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class AtomSearchComponent {

  text = ''

  @Output() textChanged = new EventEmitter<string>();
  @Output() onEnter = new EventEmitter<string>();
  @Input() bDarkMode: boolean = false;
  @Input() bGrayMode: boolean = false;

}
