import { Subscription } from 'rxjs';
import { TooltipService } from './tooltip.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() key = '';
  @Input() position = 'right';

  public tooltip: string;

  constructor(private tooltipService: TooltipService) { }

  ngOnInit(): void {
    this.tooltipService.tooltips$
      .subscribe(res => {
        this.tooltip = res[this.key];
      })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
