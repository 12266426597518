import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-chart2d-vertical",
  templateUrl: "./chart2d-vertical.component.html",
  styleUrls: ["./chart2d-vertical.component.scss"],
})
export class Chart2dVerticalComponent implements OnInit {
  @Input() data: any;
  @Input() average;
  @Input() title: string;
  @Input() selectName: string;
  @Input() selectValues = [];
  @Input() type = "bar";
  @Input() totalValue = 0;
  @Input() yAxis: boolean = true;
  @Input() xAxis: boolean = true;
  @Input() showYAxisLabel: boolean = false;
  @Input() showGridLines: boolean = true;
  @Input() scheme: { domain: string[] } = { domain: ["#44678a"] };
  @Input() barPadding: number = 30;
  @Input() isHeadHidden: boolean = false;
  @Input() view: number[] | undefined = undefined;
  @Input() legend: boolean = false;
  @Input() groupPadding: number = 16;
  constructor() {}

  ngOnInit(): void {}
}
