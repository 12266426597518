<section>
    <header>
        <h3>Reviews and Ratings <app-tooltip [key]="TooltipTypeReader.REVIEWS_AND_RATING"></app-tooltip></h3>
        <app-sort [actualSort]='actualSort' [sortList]='sortList' (sortUpdate)="getList($event)" bExpandOnLeftSide="true"></app-sort>
    </header>
    <div class="ag-box-v ag-box-rounding margin-button">
      <div  class="ag-bg-light"></div>
      <div class="ag-bg-light ag-t-dark ag-p-none ">
        <atom-widget-list-scroll (onNewDataNeed)="onWindowScroll()" [isLoading]="isLoading" [isRollLoading]="isRollLoading" [bShowExpandButton]="list.length > 3" [bShowList]="list.length > 0">
          <app-one-reviews-rating agList *ngFor="let oReview of list" [oReview]="oReview"></app-one-reviews-rating>
        </atom-widget-list-scroll>
      </div>
    </div>
</section>
