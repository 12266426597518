<div class="content full-flex">
    <p class="label">{{label}}</p>
    <div class="values full-flex">
        <mat-progress-bar
            class="bar black"
            [color]="'#00000'"
            [mode]="'determinate'"
            [value]="value/totalValue*100"
            [bufferValue]="100"
            [ngClass]="{'blue': i==0, 'orange': i==1, 'red': i==2, 'green': i==3, 'yellow': i==4}">
        </mat-progress-bar>
        <p>{{value}}</p>
    </div>
</div>