import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public termsLink = `${environment.assetsUrl}/terms_of_usage_and_privacy_policy.pdf`;

  constructor(private router: Router) {}


}
