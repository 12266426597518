import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TransactionsComponent } from "./transaction/transactions/transactions.component";
import { CustomMaterialModule } from "../@theme/custom-material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "../_components/components.module";

import { PagesPublicRoutingModule } from "./pages-public-routing.module";
import { ManageAdsModule } from "../pages-publisher/manage-ads/manage-ads.module";
import { MenuModule } from "../_widgets/menu/menu.module";
import { PagesPublicComponent } from './pages-public.component';
import { HeaderPublicComponent } from "../_widgets/menu/header-public/header-public.component";
import { LinearChartCardComponent } from './components/linear-chart-card/linear-chart-card.component';

@NgModule({
  declarations: [TransactionsComponent, PagesPublicComponent,  HeaderPublicComponent, LinearChartCardComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ComponentsModule,
    PagesPublicRoutingModule,
    ManageAdsModule,
    MenuModule,
   
  ],
  providers: []
})
export class PagesPublicModule {}
