import { Component, forwardRef, OnChanges, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
// import { NgxCroppieComponent } from 'ngx-croppie';
import {NgxCroppieComponent} from '../ngx-croppie/ngx-croppie.component';
import { CroppieOptions } from 'croppie';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { from } from 'rxjs';

const MAX_FILE_SIZE = 1048576; // 1 MB
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageEditorComponent),
      multi: true
    }
  ]
})
export class ImageEditorComponent implements OnChanges, ControlValueAccessor {
  @ViewChild('imageUpload', { static: false }) imageUpload: ElementRef;

  @Output()
  public saveImage = new EventEmitter();
  @Input()
  public imgCropToHeight = '120';
  @Input()
  public imgCropToWidth = '220';
  @Input()
  public imageData: any;
  @Input()
  public showSizeInfo = true;
  @Input()
  public imageName = 'Profile Image';
  @Input()
  upload: (event: any) => any;
  @Input()
  noEditBtn: boolean;
  @Input()
  imageOnly: boolean;
  @Input()
  useButtons = true;

  public image: any;
  public toSave: boolean;
  public testCropie;

  /* Our cropped image and the value of our image controller */
  public croppieImage;

  /* Options for the cropped image type and size */
  public outputoption = { type: 'blob', size: 'viewport' };

  /* Element to paint our form control */
  @ViewChild('ngxCroppie', { static: true })
  ngxCroppie: NgxCroppieComponent;

  constructor(private toastService: ToastService) {}

  ngOnChanges(changes: any) {
    this.loadImage();
    if (this.croppieImage) {
      return;
    }

    if (!changes.imageUrl) {
      return;
    }
    if (!changes.imageUrl.previousValue && changes.imageUrl.currentValue) {
      this.croppieImage = changes.imageUrl.currentValue;
      this.propagateChange(this.croppieImage);
    }
  }


  private loadImage() {
    if (this.imageData) {
      this.image = this.imageData;
    }
  }

  private convertImage(image) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      this.image = reader.result;
    };
  }

  /* Options for croppie, you can learn more about this here -> https://foliotek.github.io/Croppie/ */
  public get croppieOptions(): CroppieOptions {
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: parseInt(this.imgCropToWidth, 10),
      height: parseInt(this.imgCropToHeight, 10),
      type: 'square'
    };
    opts.boundary = {
      width: parseInt(this.imgCropToWidth, 10),
      height: parseInt(this.imgCropToHeight, 10),
    };
    opts.minZoom = 0.03;
    opts.maxZoom = 1;
    opts.enforceBoundary = false;
    opts.mouseWheelZoom = 'ctrl';
    return opts;
  }

  /* Event to be activated when you select an image */
  imageUploadEvent(evt: any) {
    this.toSave = true;
    if (!evt.target) {
      return;
    }
    if (!evt.target.files) {
      return;
    }

    if (evt.target.files.length !== 1) {
      return;
    }

    const file = evt.target.files[0];
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/jpg'
    ) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.toastService.warning('File is too big.');
    } else {
      const fr = new FileReader();
      fr.onloadend = loadEvent => {
        this.croppieImage = fr.result.toString();
      };
      fr.readAsDataURL(file);
    }

  }

  newImageResultFromCroppie(img: string) {
    this.croppieImage = img;
    this.propagateChange(this.croppieImage);
  }

  save() {
    this.toSave = false;

    this.convertImage(this.croppieImage);
    this.croppieImage = null;
  }

  /* Takes the value  */
  writeValue(value: any) {
    if (value !== undefined) {
      this.croppieImage = value;
      this.propagateChange(this.croppieImage);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
