import { Component, OnInit, Input } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel-viewer',
  templateUrl: './carousel-viewer.component.html',
  styleUrls: ['./carousel-viewer.component.scss']
})
export class CarouselViewerComponent implements OnInit {
  @Input() data;

  customOptions: OwlOptions = {
    navText: ['', ''],
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    smartSpeed: 1000,
    items: 1,
    loop: true,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 0,
    responsive: {
      0: {
          margin: 30
      },
      768: {
        margin: 0
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

}
