import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switcher',
  styleUrls: ['./switcher.component.scss'],
  templateUrl: './switcher.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwitcherComponent)
    }
  ]
})
export class SwitcherComponent {
  @Input() firstValue: any;
  @Input() secondValue: any;

  @Input() label: string;
  @Input() firstValueLabel: string;
  @Input() secondValueLabel: string;
  @Input() disabled = false;
  // avaiable values: bottom, left
  @Input() type = 'left';
  @Output() valueChange = new EventEmitter<any>();

  public val = false;
  public onChange(newVal: any) {}
  public onTouched(_?: any) {}
  public registerOnChange(fn: any): void { this.onChange = fn; }
  public registerOnTouched(fn: any): void { this.onTouched = fn; }
  public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }
  public writeValue(value: any): void {
    this.val = value;
  }

  public changeValue(event: any) {
    this.valueChange.emit(event.checked);
    this.onChange(event.checked);
    this.val = event.checked;
  }

}
