<div style="min-height: 220px; flex-direction: column;" class="table-wrapp" [style.display]="isLoading || data.length == 0 ? 'flex' : 'block'">
  <div (click)="openColumnFilter()" class="mobile-filter">
    Select Fields
  </div>
  <table [hidden]='isLoading||data.length===0' mat-table [dataSource]="dataSource" multiTemplateDataRows class="table w-100">
    <ng-container matColumnDef="{{column.fieldName}}" *ngFor="let column of columns">
      <th class="head-cell ag-strong" mat-header-cell *matHeaderCellDef>
            {{column.title}}
      </th>
      <td class="row-cell"  mat-cell *matCellDef="let element">
        <ng-container *ngTemplateOutlet="tdContent; context:{element:element, column: column}"></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td class="expand-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="expand-wrapp" style="overflow: hidden; display: block;" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="expand-row ag-flex-row-between ag-flex-align-center" *ngFor="let row of countRows(expandedColumns.length); let rowIndex=index;"
                  [ngClass]="{'last': rowIndex===countRows(expandedColumns.length).length-1}" >
              <ng-container  *ngFor="let column of expandedColumns; let columnIndex=index">
                <div *ngIf="columnIndex>=perExpandRow*rowIndex && columnIndex<perExpandRow*(rowIndex+1)" class="row-td" [ngClass]="{'w-50': perExpandRow==2, 'w-100': expandedColumns.length%perExpandRow===1}">
                  <strong class="ag-font-11 mr-2">{{column.title}}: </strong>
                  <ng-container *ngTemplateOutlet="tdContent; context:{element:element, column: column}"></ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
    </ng-container>

    <ng-template #tdContent let-element="element" let-column="column">
      <ng-container [ngSwitch]="column.extraValue">
        <div *ngSwitchDefault class="text">
          {{element[column.fieldName] ? element[column.fieldName] :"—"}}
        </div>
        <div *ngSwitchCase="'amount'" class="amount" >
          {{element[column.fieldName] ? element[column.fieldName] : '-' }} <ng-container *ngIf="element[column.fieldName]">{{currency}}</ng-container>
        </div>
        <div *ngSwitchCase="'rating'" class="rating">
          <star-rating #rating [showHalfStars]="true" [size]="'3px'" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
        </div>
        <div *ngSwitchCase="'avatar'" class="avatar">
          <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt="brand logo">
          <div *ngIf="!element[column.fieldName]">-</div>
        </div>
        <div *ngSwitchCase="'product'" class="product flex align-center">
          <div class="product-image">
              <img *ngIf="element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="product image">
              <img *ngIf="!element[column.fieldName].productDescriptionURL" src="../../../../assets/images/Table/product-placeholder.png" alt="empty product">
          </div>
          <div style="margin-left: 11px;">{{element[column.fieldName].productName}}</div>
        </div>
        <div *ngSwitchCase="'checkbox'">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="selectChange($event)" [id]="element[selectedByColumn]" [checked]="(selectedArray.indexOf(element[selectedByColumn]) > -1) ? true : false"></mat-checkbox>
        </div>
        <div *ngSwitchCase="'offers'" style="display: flex;">
           <a *ngIf="element[column.fieldName]" target="_blank" [href]="element['offersWebsite']" style="display: flex;">
                <img  [src]="element[column.fieldName]"  class="m-offers"  alt="Item offer image">
              </a>
              <a *ngIf="!element[column.fieldName]" style="display: flex;">
                <img  class="m-offers" src="../../../../assets/images/AdPlaceholder/140x38.png" alt="Offer placeholder">
              </a>
        </div>
      </ng-container>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = (expandedElement !== element && !disableExpand) ? element : null">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" style="height: 0; border: none;"></tr>
  </table>

  <app-loader *ngIf="isLoading"></app-loader>
  <app-empty *ngIf="data.length===0 && !isLoading"></app-empty>
  <div class="mt-5">
    <span *ngFor="let item of [] | paginate: { itemsPerPage: oPagination.pageSize, currentPage: oPagination.page, totalItems: oPagination.totalRows,  id: tableName }"></span>
    <app-paginator *ngIf="!isLoading && data.length<oPagination.totalRows" (pageChange)='pageChange.emit($event)' [id]="tableName"></app-paginator>
  </div>
  <div class="ag-flex-row-between align-center bottom mt-5">
    <ng-content ></ng-content>
  </div>
</div>
