import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterCount'})
export class FilterCountPipe implements PipeTransform {
  transform(values: any = [], filter: string) {
    const filteredValues = values.filter((element: any) => {
      return element.role && element.role.includes(filter);
    });
    return filteredValues.length;
  }
}
