import { AuthUserService } from '../../../@auth/auth-user.service';
import { MessagesService } from '../../../_shared/services/rest-services/messages.service';
import { Subscription } from 'rxjs';
import { AvatarsService } from '../../../_shared/services/rest-services/avatars.service';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { getServerLink } from 'src/app/_shared/utils';

@Component({
  selector: 'app-read-ticket',
  templateUrl: './read-ticket.component.html',
  styleUrls: ['./read-ticket.component.scss']
})
export class ReadTicketComponent implements OnInit, OnChanges {
  @ViewChild('chatArea') private chatArea: ElementRef;
  private subscription: Subscription = new Subscription();
  @Input() ticket: any;
  @Input() isMessage: any;
  @Input() isSent: boolean;
  @Input() isClosed: boolean;
  @Output() replyEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor(public avatarsService: AvatarsService,
              private messagesService: MessagesService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  reply() {
    this.replyEvent.emit();
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.messagesService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      })
    );
  }

  scrollToBottom(): void {
    try {
      this.chatArea.nativeElement.scrollTop = this.chatArea.nativeElement.scrollHeight;
    } catch (err) { }
  }


}
