<div class="table-wrapp ag-flex-column-between">
  <table [hidden]='isLoading||data.length===0' mat-table [dataSource]="dataSource" multiTemplateDataRows class="table w-100">
    <ng-container matColumnDef="{{column.fieldName}}" *ngFor="let column of columns">
      <th class="head-cell ag-strong" mat-header-cell *matHeaderCellDef>
            {{column.title}}
      </th>
      <td class="row-cell"  mat-cell *matCellDef="let element">
        <ng-container *ngTemplateOutlet="tdContent; context:{element:element, column: column}"></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td class="expand-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="expand-wrapp" style="overflow: hidden; display: block;" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="expand-row ag-flex-row-between ag-flex-align-center" *ngFor="let row of countRows(expandedColumns.length); let rowIndex=index;"
                  [ngClass]="{'last': rowIndex===countRows(expandedColumns.length).length-1}" >
              <ng-container  *ngFor="let column of expandedColumns; let columnIndex=index">
                <div *ngIf="columnIndex>=perExpandRow*rowIndex && columnIndex<perExpandRow*(rowIndex+1)" class="row-td" [ngClass]="{'w-50': perExpandRow==2, 'w-100': expandedColumns.length%perExpandRow===1}">
                  <strong class="ag-font-11 mr-2">{{column.title}}: </strong>
                  <ng-container *ngTemplateOutlet="tdContent; context:{element:element, column: column}"></ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
    </ng-container>

    <ng-template #tdContent let-element="element" let-column="column">
      <ng-container [ngSwitch]="column.extraValue">
        <div *ngSwitchDefault class="text">
          {{element[column.fieldName] ? element[column.fieldName] :"—"}}
        </div>
        <div class="amount" *ngSwitchCase="'amount'">
            {{element[column.fieldName] ? element[column.fieldName] : '-' }} <ng-container *ngIf="element[column.fieldName]">{{currency}}</ng-container>
        </div>
        <div class="rating" *ngSwitchCase="'rating'">
            <star-rating #rating [showHalfStars]="true" [size]="'3px'" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
        </div>
        <div class="avatar" *ngSwitchCase="'avatar'">
            <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt="brand logo">
            <span *ngIf="!element[column.fieldName]">-</span>
        </div>
        <div class="product flex align-center" *ngSwitchCase="'product'">
            <div class="product-image">
                <img *ngIf="element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="product image">
                <img *ngIf="!element[column.fieldName].productDescriptionURL" src="../../../assets/images/New design/empty-product.svg" alt="empty product">
            </div>
            <span style="margin-left: 11px;">{{element[column.fieldName].productName}}</span>
        </div>
      </ng-container>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = (expandedElement !== element && !disableExpand) ? element : null">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" style="height: 0;"></tr>
  </table>

  <app-loader *ngIf="isLoading"></app-loader>
  <ng-container *ngIf=" data.length>0 && pagination">
    <ng-container *ngFor="let row of [] | paginate: pagination"></ng-container>
    <app-paginator class="paginator" [id]="pagination.id" (pageChange)="onPageUpdate($event)"></app-paginator>
  </ng-container>
  <app-empty *ngIf="data.length===0 && !isLoading"></app-empty>
</div>
