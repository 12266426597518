import { AdEventService } from './../../_services/ad-event.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-video-ad',
  templateUrl: './video-ad.component.html',
  styleUrls: ['./video-ad.component.scss']
})
export class VideoAdComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  public form: FormGroup;
  @Input () videoSize: any;

  constructor(public adEventService: AdEventService) {
}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      video: new FormControl (null, [Validators.required, Validators.minLength(5)]),
    });
    // Form Validation update
    this.subscription.add(this.form.valueChanges
      .subscribe(value => {
        this.adEventService.updateChildForm(value);
    }));
    // Form Validation update
    this.subscription.add(this.form.statusChanges
      .subscribe(status => {
        this.adEventService.updateChildFormValidation(status === 'VALID' ? true : false);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
