import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-stats-mobile',
  templateUrl: './stats-mobile.component.html',
  styleUrls: ['./stats-mobile.component.scss']
})
export class StatsMobileComponent implements OnInit {
  @Input() list = [];
  @Input() theme = '';
  @Input() size = 'xl';
  public currency = '';
  desktopOptions: OwlOptions = {
    dots: false,
    autoWidth: true,
    nav: false,
    navText: ['', ''],
    margin: 10
  };
  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit(): void {
  }

}
