import { ImageConfiguratorComponent } from './../../../../_dialogs/image-configurator/image-configurator.component';
import { MatDialog } from '@angular/material/dialog';
import { AdEventService } from './../../_services/ad-event.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-image-ad',
  templateUrl: './image-ad.component.html',
  styleUrls: ['./image-ad.component.scss']
})
export class ImageAdComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  public form: FormGroup;
  @Input () imageSize: any;

  constructor(public adEventService: AdEventService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      layout: new FormControl (null),
      image: new FormControl (null),
      imageBlob: new FormControl (null),
      logo: new FormControl (null),
      logoBlob: new FormControl (null),
      heading: new FormControl (null, [Validators.maxLength(100)]),
      text: new FormControl (null, [ Validators.maxLength(300)]),
      price: new FormControl (null, [Validators.maxLength(20)]),
    });
    // Form Validation update
    this.subscription.add(this.form.valueChanges
      .subscribe(value => {
        this.adEventService.updateChildForm(value);
    }));
    // Form Validation update
    this.subscription.add(this.form.statusChanges
      .subscribe(status => {
        this.adEventService.updateChildFormValidation(status === 'VALID' ? true : false);
    }));
  }

  openImageConfigurator() {
    const imageDialog = this.dialog.open(ImageConfiguratorComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: this.imageSize.width > 550 ? this.imageSize.width + 52 + 'px' : '600px',
      data: this.imageSize
    });
    this.subscription.add(imageDialog.afterClosed()
    .subscribe(result => {
      if (result) {
        this.convertImage(result, false);
        this.form.get('imageBlob').patchValue(result);
      }
    }));
  }

  openLogoConfigurator() {
    const logoDialog = this.dialog.open(ImageConfiguratorComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '600px',
      data: {height: 200, width: 330}
    });
    this.subscription.add(logoDialog.afterClosed()
    .subscribe(result => {
      if (result) {
        this.convertImage(result, true);
        this.form.get('logoBlob').patchValue(result);
      }
    }));
  }

  private convertImage(image, isLogo: boolean) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      const result = reader.result;
      isLogo ?
        this.form.get('logo').patchValue(reader.result) :
        this.form.get('image').patchValue(reader.result);
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
