<div class="table-wrapp ag-flex-column-between">
    <table [hidden]="!(data.length>0) || isLoading"  #table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.fieldName">
            <!-- header -->
            <th mat-header-cell *matHeaderCellDef class="head-cell">
                <span>{{column.title}}</span>
            </th>
            <!-- content -->
            <td class="row-cell" [ngClass]="{'offers-column': false}"  mat-cell *matCellDef="let element">
                <div *ngIf="!column.extraValue">
                    {{element[column.fieldName] ? element[column.fieldName] :"—"}}
                </div>
                <span class="amount" *ngIf="column.extraValue=='amount'">
                    {{element[column.fieldName] ? element[column.fieldName] : '-' }} <ng-container *ngIf="element[column.fieldName]">{{currency}}</ng-container>
                </span>
                <span class="rating" *ngIf="column.extraValue=='rating'">
                    <star-rating #rating [showHalfStars]="true" [size]="'3px'" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                </span>
                <span class="avatar" *ngIf="column.extraValue=='avatar'">
                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt="brand logo">
                    <span *ngIf="!element[column.fieldName]">-</span>
                </span>
                <span class="product flex align-center" *ngIf="column.extraValue=='product'">
                    <div class="product-image">
                      <img *ngIf="!useOldIcon && element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="product image">
                      <img *ngIf="!useOldIcon && !element[column.fieldName].productDescriptionURL" src="../../../assets/images/New design/empty-product.svg" alt="empty product">
                      <img *ngIf="useOldIcon  && !element[column.fieldName].productDescriptionURL" src="../../../../assets/images/Table/product-placeholder.png" alt="empty product">
                  </div>
                    <span style="margin-left: 11px;">{{element[column.fieldName].productName}}</span>
                </span>
            </td>
            <!-- content -->
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
    </table>

    <app-loader *ngIf="isLoading"></app-loader>
    <ng-container *ngIf=" data.length>0 && pagination">
      <ng-container *ngFor="let row of [] | paginate: pagination"></ng-container>
      <app-paginator class="paginator" [id]="pagination.id" (pageChange)="onPageUpdate($event)"></app-paginator>
    </ng-container>
    <app-empty *ngIf="data.length===0 && !isLoading"></app-empty>
</div>
