import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() okLabel = 'OK';
  @Input() cancelLabel = 'Cancel';
  @Input() deleteInput = false;
  @Input() hideOkButton = false;
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<QuestionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.message = data.message;
    this.okLabel = data.okLabel || 'OK';
    this.cancelLabel = data.cancelLabel || 'Cancel';
    this.deleteInput = data.deleteInput;
    this.hideOkButton = data.hideOkButton;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = new FormGroup({
      confirmation: new FormControl(null, [Validators.required])
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(true);
  }

  isFormValid(form: FormGroup) {
    return form.get('confirmation').value === 'DELETE';
  }

}
