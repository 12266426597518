<div class="wrapper no-border">
    <div class="table-wrapp" [ngClass]="{'loading': isLoading}">
        <div class="filters-wrapp mb-2" *ngIf="!isLoading &&  data.length>0">
            <div (click)="openColumnFilter()" class="filter-wrapp">
                Select Fields
            </div>
        </div>
        <h2 *ngIf="listName">{{listName}}</h2>
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="table" [hidden]='isLoading || (data.length == 0 && hideIfEmpty)' (matSortChange)="updatePagination($event, 'sort')">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.fieldName">
                <!-- header -->
                <th class="table-head" mat-header-cell mat-sort-header *matHeaderCellDef> {{column.title}} </th>
                <!-- header -->
                <!-- content -->
                <td class='table-row' mat-cell *matCellDef="let element; let i = index" [ngClass]="{'amount-wrapp': column.extraValue=='amount'}">
                <span class="element" *ngIf="!column.valuePrepareFunction&&!column.extraValue">
                        {{element[column.fieldName] ? element[column.fieldName] : '-'}}
                </span>
                <div class="element blue" *ngIf="column.extraValue=='amount'" (clickOutside)='element.isEdit ?toCanceltoEditPrice($event, element) : null'>
                    <div [style.display]="element.isEdit? 'none' : 'block'" (click)="!element.isEdit ? toEditModePrice(element) : null">
                      {{element[column.fieldName]? element[column.fieldName] : ''}}
                      <button class="btn-icon flex-center" *ngIf="!element[column.fieldName]">
                        <mat-icon class="edit">edit</mat-icon>
                      </button>
                      <span *ngIf="element[column.fieldName]" class="valute">{{currency}}</span>
                    </div>
                    <div class="price-editor form flex" [style.display]="!element.isEdit? 'none' : 'flex'">
                      <mat-form-field class="textarea" floatLabel="never" appearance="outline">
                        <input  matInput type="number" [(ngModel)]="newPrice">
                      </mat-form-field>
                      <button class="btn-icon flex-center ml-1" (click)="changePrice(element); element.isEdit = false">
                        <mat-icon class="save">done</mat-icon>
                      </button>
                    </div>
                </div>
                <span  *ngIf="column.extraValue=='quantity'" class="element quantity ">
                    <div class="wrapper full-flex">
                        <button [disabled]='+element[column.fieldName]<2' (click)="changeQuantity(element, +element[column.fieldName]-1)" class="btn-icon"><img class="qty" src="../../../assets/images/Company-account/-.svg" alt=""></button>
                        {{element ? +element[column.fieldName] : ''}}
                        <button class="btn-icon" (click)="changeQuantity(element, +element[column.fieldName]+1)"><img class="qty" src="../../../assets/images/Company-account/+.svg" alt=""></button>
                    </div>
                </span>
                <span class="element"  *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
                <span class="element avatar" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                </span>
                <span [ngClass]="{'deleted': element.isStrike}" class="element strikethrough" (click)="toStrike(element)" *ngIf="column.extraValue=='strikethrough'&&element[column.fieldName]!=='new_custom_element'" >
                    <span class="item-name">{{element ? element[column.fieldName] : ''}}</span> 
                </span>
                </td>
                <!-- content -->
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
        </table>
        <mat-spinner [diameter]="100" [strokeWidth]='7' [style.display]="isLoading ? 'block' : 'none'"></mat-spinner>
        <div *ngIf="data.length===0 && hideIfEmpty" class="empty">
            <h1 >List is empty</h1>
        </div>
    </div>
    <ng-container *ngIf="!noEditable">
        <div class="new-item form flex-center">
        <form class="full-flex" (keyup.enter)="addItem($event)" [formGroup]="form" autocomplete="off">
        <mat-form-field class="textarea" floatLabel="never" appearance="outline">
            <textarea matInput
                (keydown.enter)="onKeydown($event)"
                cdkTextareaAutosize
                placeholder="Add new item.."
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                formControlName ="productName"
                cdkAutosizeMaxRows="2">
            </textarea>
        </mat-form-field>
        <button *ngIf="!noEditable" (click)="addItem($event)" [disabled]='form.invalid' class="btn-icon"><img src="../../../assets/images/Company-account/+.svg" alt=""></button>
        </form>
        </div>
    </ng-container>
    <mat-paginator
        *ngIf="usePagination"
        (page)="updatePagination($event, 'page')"
        class="pagination"
        [length]="totalRows"
        [pageSizeOptions]="paginationSizeOptions">
      </mat-paginator>
</div>
