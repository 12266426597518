<div class="content">
    <button class="btn-icon close" (click)="this.dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <form [formGroup]="form" autocomplete="on">
        <p class="input">This receipt is locked, please enter your password to unlock receipt.</p>
        <mat-form-field floatLabel="never" appearance="outline">
          <input  matInput
                  placeholder="Enter Your Password"
                  type="password"
                  formControlName ="password"
                  name="password">
        </mat-form-field>
        <button (click)="this.dialogRef.close(form.get('password').value)"
                type="submit" [disabled]='form.invalid'
                class="btn btn-full shadow">Unlock Receipt</button>
    </form>
</div>
