import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DictionariesService } from "../_shared/dictionaries/dictionaries.service";
import { WsService } from "./ws.service";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  private _choosenCurrencyId = new Subject<number>();
  private $choosenCurrencyId = this._choosenCurrencyId.asObservable();

  public selectedCurrency: number = null;
  private currentCurrencyNameSubject = new Subject<string>();
  public currentCurrencyName: string = "";
  public $currentCurrencyName = this.currentCurrencyNameSubject.asObservable();

  private currentCurrencyId = new Subject<string>();
  public $currentCurrencyId = this.currentCurrencyId.asObservable();

  public exchangeRates: any = [];

  get selectedCurrencyItem(): { label: string; value: number } {
    const curr = this.exchangeRates.find(
      (x: any) => x.currencyIdTo == this.selectedCurrency
    );
    return { label: curr?.currencyTo, value: curr?.currencyIdTo };
  }

  constructor(
    private wsService: WsService,
    private dictionaryService: DictionariesService
  ) {
    this.wsService.$currencyDetailsEvents.subscribe(({ id }) => {
      console.log('ws service currency id', id)
      this._choosenCurrencyId.next(id);
    });
    this.dictionaryService
      .getExchangeRates()
      .toPromise()
      .then((res: any) => {
        console.log("get exchange rates", res);
        this.exchangeRates = res.result.list.map((x: any) => {
          return {
            currencyIdFrom: x.currencyIdFrom,
            currencyIdTo: x.currencyIdTo,
            currencyFrom: x.currencyFrom,
            currencyTo: x.currencyTo,
            externalWalletFee: x.externalWalletFee,
            internalWalletFee: x.internalWalletFee,
            rate: x.rate,
          };
        });
      }).catch(err => console.warn('ERROR DURING EXCHANGE RATES FETCH', err));
    this.$choosenCurrencyId.subscribe((currency) => {
      this.selectedCurrency = this.exchangeRates.find(
        (x: any) => x.currencyIdTo == currency
      ).currencyIdTo;
      console.log(
        "selected currency",
        this.exchangeRates.find((x) => x.currencyIdTo === currency)
      );
      this.currentCurrencyNameSubject.next(
        this.exchangeRates.find((x) => x.currencyIdTo === currency).currencyTo
      );
      this.currentCurrencyName = this.exchangeRates.find(
        (x) => x.currencyIdTo === currency
      ).currencyTo;

      this.currentCurrencyId.next(
        this.exchangeRates.find((x) => x.currencyIdTo === currency).currencyIdTo
      );
    });
  }
  init(){
    console.log('CS initiated');
  }
  getCurrentName(): string {
    return this.currentCurrencyName;
  }
  getExchangeRate(currencyFrom: string, currencyTo: string): IExchangeRates {
    return this.exchangeRates.find(
      (rate) =>
        rate.currencyFrom === currencyFrom && rate.currencyTo === currencyTo
    );
  }
}

export interface IExchangeRates {
  currencyIdFrom: number;
  currencyIdTo: number;
  currencyFrom: string;
  currencyTo: string;
  externalWalletFee: number;
  internalWalletFee: number;
  rate: number;
}
