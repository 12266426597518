import { ImageEditorComponent } from './../../_components/image-editor/image-editor.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-configurator',
  templateUrl: './image-configurator.component.html',
  styleUrls: ['./image-configurator.component.scss']
})
export class ImageConfiguratorComponent implements OnInit {
  @ViewChild(ImageEditorComponent,  { static: false })
  public imageEditorComponent: ImageEditorComponent;
  public image: any;

  constructor(@Inject (MAT_DIALOG_DATA) public imageSize: any,
              public dialogRef: MatDialogRef<ImageConfiguratorComponent>) {
              }

  ngOnInit(): void {
  }

  save() {
    setTimeout(() => { this.dialogRef.close(this.image)}, 500);
  }

}
