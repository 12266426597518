import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @ViewChild('circleWrapp', { static: false }) circleWrapp: ElementRef;
  @Input() data: IPieChart[] = [];
  @Input() totalValue: number; 
  @Input() view: number[];
  @Input() hideLegend:boolean = false;
  @Input() customScheme: { domain:string[] } = {
    domain: ['#11B2EE', '#58FF26']
  };;
  @Input() fontForTotal: string = '60px'; 


  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{console.log('data pie', this.data)}, 2000)
    
  }
  

}

export interface IPieChart {
  name: string;
  value: number;
}