import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SortComponent implements OnInit {
  @ViewChild('matSelect') matSelect:any;
  @Input() color = 'blue';
  @Input() sortList: any = [];
  @Input() actualSort;
  @Input() bExpandOnLeftSide = false;

  @Output() sortUpdate = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

}
