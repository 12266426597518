import { FiltersMobileComponent } from './../../../_dialogs/filters-mobile/filters-mobile.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { IOldPagination } from '../../../_shared/interfaces/old-pagination.model';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-complex-table-mobile',
  templateUrl: './complex-table-mobile.component.html',
  styleUrls: ['./complex-table-mobile.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ComplexTableMobileComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input() data = [];
  @Input() columns: any = {};
  @Input() maxColumns = 3;
  @Input() isLoading = false;
  @Input() disableExpand: boolean;
  @Input() perExpandRow = 2;
  @Input() oPagination : IOldPagination = {
    page: 0,
    pageSize: 0,
    totalRows: 0,
  };
  @Output() pageChange = new EventEmitter();
  @Input() tableName = 'complex-table-select';
  @Input() selectedByColumn = 'id';
  @Input() selectedArray = [];
  @Output() selectArrayChange = new EventEmitter();

  dataSource: any;
  displayedColumns: string[] = [];
  expandedColumns = [];
  expandedElement;
  currency = '';

  constructor(private authUserService: AuthUserService,
              private dialog: MatDialog) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit() {
  }

  countRows(length: number) {
    if (length > 0) {
      const count = Math.ceil(length/this.perExpandRow);
      return Array.apply(null, {length: count}).map(Number.call, Number);
    } else {
      this.disableExpand = true;
      return [];
    }
  }

  selectChange(e) {
    if(e.checked) {
      this.selectedArray.push(e.source.id)
    } else {
      this.selectedArray = this.selectedArray.filter(v => v !== e.source.id)
    }
    this.selectArrayChange.emit(this.selectedArray)
  }

  openColumnFilter() {
    const filtersDialog = this.dialog.open(FiltersMobileComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '350px',
      data: {columns: this.columns, maxColumns: this.maxColumns},
    });
    this.subscription.add(filtersDialog.afterClosed()
      .subscribe(result => {
        this.tableInitialization();
    }));
  }

  ngOnChanges() {
    this.tableInitialization();
    this.expandedElement = null;
  }

  tableInitialization() {
    const columns = [];
    const expandedColumn = [];
    for (const column of this.columns) {
      column.toUseMobile ? columns.push(column.fieldName) : expandedColumn.push(column);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
    this.expandedColumns = expandedColumn;
    this.oPagination = this.oPagination;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
