<div class="attachments">
  <div class="files-list flex mr-3">
      <div [matMenuTriggerFor]="menu"
           #menuTrigger="matMenuTrigger"
           [matMenuTriggerData]="{pickedFile:i}"
           class="file flex ml-1 mb-1"
           *ngFor="let file of files; index as i">
          <mat-icon>description</mat-icon>
          <span class="file-name">{{file.name}}</span> <span class="file-size">({{file.size*0.001 | number : '1.0-0'}} kb)</span>
      </div>
  </div>
  <div class="add-btn" (click)='clickInput()'>
      <input  hidden
              type="file"
              #FileInput
              (change)="fileSelected($event.target.files)"
              name="files"
              multiple>
      <button class="btn btn-gray ag-btn-xl">
        <img src="../../../../assets/images/Messages/attach.svg" alt="">  Add Attachment
      </button>
  </div>
</div>


<mat-menu #menu="matMenu" yPosition="above">
  <ng-template matMenuContent let-indexOfFile="pickedFile">
      <div class="popover-container flex">
          <button class="btn-icon" (click)="deleteFile(indexOfFile)">
              <mat-icon>clear</mat-icon>
              <span>delete</span>
          </button>
      </div>
  </ng-template>
</mat-menu>
