<div class="ag-rte-container">
  <div class="ag-rte-comment">
    <span>Comment</span>
  </div>
  <div class="ag-rte-hoz-line"></div>
  <div class="ag-rte-toolbar">
    <div class="rte-btns">
      <button
        class="rte-btn"
        (click)="executeCommand('bold')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('bold') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/bold.svg"
          alt="bold"
          style="margin-bottom: 4px"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('italic')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('italic') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/italic.svg"
          alt="italic"
          style="margin-bottom: 2px"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('underline')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('underline') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/underline.svg"
          alt="underline"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('strikeThrough')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('strikeThrough') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/strikethrough.svg"
          alt="strikethrough"
          style="margin-bottom: 2px"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('insertUnorderedList')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('insertUnorderedList') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/unordered_list.svg"
          alt="unordered list"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('insertOrderedList')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('insertOrderedList')}"
      >
        <img
          src="../../../assets/images/Rich Text Editor/numbered_list.svg"
          alt="ordered list"
        />
      </button>
      <div class="rte-btn">
        <mat-form-field floatLabel="never">
          <mat-select
            [(ngModel)]="color"
            [ngStyle]="{ 'background-color': color }"
            (selectionChange)="executeCommand('foreColor', color)"
          >
            <mat-option value="#000"
              ><div class="ag-rte-color-block" style="background-color: #000">
                Color
              </div></mat-option
            >
            <mat-option value="#214263"
              ><div
                class="ag-rte-color-block"
                style="background-color: #214263"
              >
                Color
              </div></mat-option
            >
            <mat-option value="goldenrod"
              ><div
                class="ag-rte-color-block"
                style="background-color: goldenrod"
              >
                Color
              </div></mat-option
            >
            <mat-option value="lighcoral"
              ><div
                class="ag-rte-color-block"
                style="background-color: lightcoral"
              >
                Color
              </div></mat-option
            >
            <mat-option value="lighblue"
              ><div
                class="ag-rte-color-block"
                style="background-color: lightblue"
              >
                Color
              </div></mat-option
            >
            <mat-option value="chartreuse"
              ><div
                class="ag-rte-color-block"
                style="background-color: chartreuse"
              >
                Color
              </div></mat-option
            >
            <mat-option value="deeppink"
              ><div
                class="ag-rte-color-block"
                style="background-color: deeppink"
              >
                Color
              </div></mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <button
        class="rte-btn"
        (click)="executeCommand('justifyLeft')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('justifyLeft') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/align_left.svg"
          alt="justify left"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('justifyCenter')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('justifyCenter') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/align_center.svg"
          alt="justify center"
        />
      </button>
      <button
        class="rte-btn"
        (click)="executeCommand('justifyRight')"
        [ngClass]="{ 'rte-btn-highlight': activeCommands.includes('justifyRight') }"
      >
        <img
          src="../../../assets/images/Rich Text Editor/align_right.svg"
          alt="justify right"
        />
      </button>
      <!-- <button class="rte-btn">
        <img
          src="../../../assets/images/Rich Text Editor/attachment.svg"
          alt="attachment"
        />
      </button>
      <button
        class="rte-btn"
        (click)="
          executeCommand('createLink', 'prompt(`Enter a URL`, `http://`)')
        "
      >
        <img
          src="../../../assets/images/Rich Text Editor/link.svg"
          alt="add link"
        />
      </button> -->
      <button class="rte-btn" (click)="executeCommand('undo')">
        <img
          src="../../../assets/images/Rich Text Editor/undo.svg"
          alt="undo"
        />
      </button>
      <button class="rte-btn" (click)="executeCommand('redo')">
        <img
          src="../../../assets/images/Rich Text Editor/redo.svg"
          alt="redo"
        />
      </button>
    </div>
    <div class="rte-selects">
      <mat-form-field floatLabel="never">
        <mat-select
          [(ngModel)]="font"
          (selectionChange)="executeCommand('fontName', font)"
        >
          <mat-option value="Times New Roman">Times New Roman</mat-option>
          <mat-option value="Georgia">Georgia</mat-option>
          <mat-option value="Arial">Arial</mat-option>
          <mat-option value="Courier">Courier</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="never">
        <mat-select
          [(ngModel)]="fontSize"
          (selectionChange)="executeCommand('fontSize', fontSize + 'px')"
        >
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="ag-rte-hoz-line"></div>
  <iframe
    (mouseout)="updateText()"
    (load)="onIframeLoad(iframeEditor)"
    frameborder="0"
    class="ag-rte"
    #iframeEditor
  ></iframe>
</div>
