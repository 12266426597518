<div class="m-rec-one" [class.m-rec-border]="!bHideBorder">
  <div>{{oReceipt.shopLocation}}</div>
  <div class="ag-flex-row-between">
    <div style="flex-grow: 2" class="m-rec ag-action" (click)="openReceipt()">Receipt # {{oReceipt.receiptNumber}}</div>
    <div style="flex-grow: 1" class="ag-t-r">{{oReceipt.receiptMonthDays}}</div>
  </div>
  <div class="ag-flex-row-between">
    <div>
      <span class="m-value">{{oReceipt.value}}</span> <span class="ag-t-light m-skip-s">{{sCurrency}}</span>
      <br>
      <span class="ag-t-light">Amount</span>
    </div>
    <div class="ag-flex-row-center">
      <div>
        <svg-icon src="assets/images/Receipt/bx-credit-card.svg" [svgStyle]="{'width.px':20,'margin-right.px':15}" [svgClass]="oReceipt.paymentType === 'Card' ? 'ag-svg-active' : 'ag-svg-inactive'"></svg-icon>
        <svg-icon src="assets/images/Receipt/bx-money.svg" [svgStyle]="{'width.px':20}" [svgClass]="oReceipt.paymentType === 'Cash' ? 'ag-svg-active' : 'ag-svg-inactive'"></svg-icon>
        <br>
        <span class="ag-t-light">Payment type</span>
      </div>
    </div>
    <div class="ag-t-r">
      {{oReceipt.receiptHoursMinuter}}
    </div>
  </div>
  <div class="ag-flex-row-between">
    <div>
      <div class="m-rating">
        <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="oReceipt.companyRating" [size]="bIsSmallSize ? 'small' : 'medum'" ></star-rating>
      </div>
    </div>
    <div class="ag-t-c">
      <svg-icon src="assets/images/New design/bx-lock-open.svg" [svgStyle]="{'width.px':16}" [className]="oReceipt.status === 'open' ? 'ag-svg-active' : 'ag-svg-inactive'"></svg-icon>
    </div>
    <div class="ag-t-r">
      <img [src]="oReceipt.companyAvatarLink" alt="" class="m-avatar">
    </div>
  </div>
</div>

