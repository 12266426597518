import { TooltipTypeReader } from 'src/app/_components/tooltip/tooltips.model';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { Subscription } from 'rxjs';
import { HttpNotificationsService } from './../../_shared/services/http-services/http-notifications.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Pagination } from 'src/app/_models/api.model';

class Notification {
  companyName: string;
  imageLink: string;
  date: string;
  notification: string;
  isRead: boolean;
  id: string;
  websiteLink: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public notification = new Notification();
  public notifications: Notification[] = [];
  public currentIndex: number = 0;
  public totalUnread: number = 0;
  public pagination = new Pagination(10);

  public TooltipTypeReader = TooltipTypeReader;

  constructor(private httpNotificationsService: HttpNotificationsService,
              private toastService: ToastService) {
               }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.subscription.add(this.httpNotificationsService.getNotifications(this.pagination)
    .subscribe ((response: any) => {
      this.notifications = [...this.notifications, ...response.result.list];
      this.totalUnread = +response.result.totalUnread;
      this.pagination.totalItems = +response.result.totalItems;
      if (this.notifications.length > 0) {
        this.notification = this.notifications[this.currentIndex];
        if (!this.notification?.isRead) {
          this.readNotification(this.notification.id);
        }
      }
    }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }

  readNotification(id: string) {
    this.subscription.add(this.httpNotificationsService.readNotification(id)
      .subscribe ((response: any) => {
        this.totalUnread--;
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }
  
  next() {
    if (this.currentIndex < (this.notifications.length - 1) ) {
      this.currentIndex ++;
      this.notification = this.notifications[this.currentIndex];
      if (!this.notification.isRead) {
        this.readNotification(this.notification.id);
      }
    } else {
      this.currentIndex ++;
      this.pagination.currentPage++;
      this.getNotifications();
    }
  }

  prev() {
    this.currentIndex --;
    this.notification = this.notifications[this.currentIndex];
    if (!this.notification.isRead) {
      this.readNotification(this.notification.id);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
