import { Pagination, PaginationRequest } from './../../../_models/api.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpShoppingListService {

  constructor(private httpClient: HttpClient, private authUserService: AuthUserService) {}

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  getShopingList(paginationReq: PaginationRequest) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/shopping-list`, {params: {...paginationReq}});
  }
  saveShopingList( data: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${this.userUid}/save-shopping-list`, data);
  }
  getSavedShopingLists(isOrder: boolean, paginationReq: PaginationRequest) {
    const params = {isOrder: isOrder.toString(), ...paginationReq}
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/title-shopping-list`, {params});
  }
  deleteShopingList( shoppingListUid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/users/${this.userUid}/deleteShoppingList/${shoppingListUid}`);
  }
  loadShopingList( shoppingListUid: string) {
    return this.httpClient.patch(`${environment.apiUrl}/users/${this.userUid}/saved-shopping-list/${shoppingListUid}`, {});
  }
  addToShopingList( itemDescription: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${this.userUid}/shopping-list`, itemDescription);
  }
  strikeItem( itemId: number, custom?: boolean) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/strike-item/${itemId}?custom=${custom}`, {});
  }
  generateShopingList( itemsIdList: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/shopping-list`, itemsIdList);
  }
  clearShopingList( mode?: string) {
    const query = mode ? `?mode=${mode}` : '';
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/clear-shopping-list${query}`, {});
  }
  updateShopingElement( data) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/update-shopping-element`, data);
  }
  updateShopingList( shoppingListId: string) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/update-saved-shopping-list/${shoppingListId}`, {});
  }

}
