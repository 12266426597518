import { getServerLink } from 'src/app/_shared/utils';
import { MessagesService } from './../../../_shared/services/rest-services/messages.service';
import { Subscription } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-attachments-mobile',
  templateUrl: './attachments-mobile.component.html',
  styleUrls: ['./attachments-mobile.component.scss']
})
export class AttachmentsMobileComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  @Input() list = [];
  desktopOptions: OwlOptions = {
    dots: false,
    autoWidth: true,
    nav: false,
    navText: ['', ''],
    margin: 10
  };

  constructor(private messagesService: MessagesService) { }

  ngOnInit(): void {
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.messagesService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
