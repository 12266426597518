import { AuthUserService } from './../../../@auth/auth-user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private httpClient: HttpClient,
              private authUserService: AuthUserService) {}

  private get userUid() {
    return this.authUserService.getUserUid();
  }     

  getCompaniesList(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/messages/companies`);
  }

  getReceiptsList(userUid: string, companyUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/messages/receipts/${companyUid}`);
  }

  sendMessage(userUid: string, receiptUid: string, message: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${userUid}/messages/${receiptUid}`, message);
  }

  getMessagesByReceipt(userUid: string, receiptUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/messages?receiptUid=${receiptUid}`);
  }

  getMessagesByCategory(userUid: string, category: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/my-message-list?category=${category}`);
  }

  getUnreadCount(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/messages/count-unread`);
  }

  getMessage(messageUid) {
    return this.httpClient.get(`${environment.apiUrl}/users/message/${messageUid}`);
  }

  changeReadStatus(messageUid: string, isRead) {
    return this.httpClient.put(`${environment.apiUrl}/users/message-read/${messageUid}`, isRead);
  }

  changeArchiveStatus(messageUid: string, isArchive) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/message-archive/${messageUid}`, isArchive);
  }

  getFile(blobId: any) {
    return this.httpClient.get(`${environment.apiUrl}/general/blobs/${blobId}`);
  }

}
