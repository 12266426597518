import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpTransactionsService {
  backendApiUrl = "";
  constructor(private httpClient: HttpClient) {
    this.backendApiUrl = environment.apiUrl;
  }
  getPublicTransactionsInfo(sortParams: string = "", queryParams: string = "") {
    return this.httpClient.get(
      this.backendApiUrl + `/users/public-transactions?` + sortParams + '&' + queryParams
    );
  }
  getPublicTransactionsStatistics(queryParams: string = "") {
    return this.httpClient.get(
      this.backendApiUrl + `/statistics/public?` + queryParams
    );
  }
}
