import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { TransactionResponse } from "src/app/pages-reader/transaction/transactions/transactions.component";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private httpClient: HttpClient) {}

  toPay(userUid: string, payData: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/users/${userUid}/payments`,
      payData
    );
  }

  getBalance(userUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${userUid}/wallet`);
  }

  getPaymentStatus(walletUid: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/users/payment-transaction/${walletUid}`
    );
  }

  getPaymentsTransactions(userUid: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/users/payment-transaction/history/${userUid}`
    );
  }

  getInvoiceHistory(userUid: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/users/${userUid}/invoice-history`
    );
  }

  getTransactions(
    userUid: string,
    searchParams: string = "",
    queryParams: string = ""
  ) {
    return this.httpClient.get<TransactionResponse>(
      `${environment.apiUrl}/users/${userUid}/invoice-history-transaction-info?${searchParams}&${queryParams}`
    );
  }

  getTransactionsSummary(userUid: string, currencyId: number) {
    return this.httpClient.get(
      `${environment.apiUrl}/users/${userUid}/transactions/sum-up?currencyId=${currencyId}`
    );
  }
}
