<div class="wrapper">
    <h2 *ngIf="listName">{{listName}}</h2>
    <div #tableWrapp  [ngClass]="{'loading': isLoading}" class="table-wrapp">
      <table #table mat-table matSort [dataSource]="dataSource" [hidden]='isLoading || (data.length == 0 && hideIfEmpty)' (matSortChange)="updatePagination($event, 'sort')">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.fieldName">
          <!-- header -->
          <th mat-header-cell mat-sort-header *matHeaderCellDef
              class="header">
            <span>{{column.title}}</span>
          </th>
          <!-- header -->
          <!-- content -->
          <td mat-cell *matCellDef="let element" [ngClass]="{'amount-wrapp': column.extraValue=='amount'}">
            <span *ngIf="!column.valuePrepareFunction&&!column.extraValue"
                  (click)="column.action ? column.action($event, element) : null">
                    {{element[column.fieldName] ? element[column.fieldName] : '-'}}
            </span>
            <span *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
            <span class="avatar" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
             <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
            </span>
            <span [ngClass]="{'deleted': element.isStrike}" (click)="toStrike(element)" class="strikethrough" *ngIf="column.extraValue=='strikethrough'&&element[column.fieldName]!=='new_custom_element'" (click)="column.action ? column.action($event, element) : null">
              {{element ? element[column.fieldName] : ''}}
            </span>
            <div class="blue" *ngIf="column.extraValue=='amount'">
              <div *ngIf="!element.isEdit" (click)="!element.isEdit ? toEditModePrice(element) : null">
                {{element[column.fieldName]? element[column.fieldName] : ''}}
                <button class="btn-icon flex-center" *ngIf="!element[column.fieldName]">
                  <mat-icon class="edit">edit</mat-icon>
                </button>
                <span *ngIf="element[column.fieldName]" class="valute">{{currency}}</span>
              </div>
              <div class="price-editor form flex" *ngIf="element.isEdit">
                <mat-form-field class="textarea" floatLabel="never" appearance="outline">
                  <input #priceInput matInput type="number" [(ngModel)]="newPrice">
                </mat-form-field>
                <button class="btn-icon flex-center ml-2" (click)="element.isEdit = false">
                  <mat-icon class="cancel">close</mat-icon>
                </button>
                <button class="btn-icon flex-center ml-2" (click)="changePrice(element); element.isEdit = false">
                  <mat-icon class="save">done</mat-icon>
                </button>
              </div>
            </div>
            <span  *ngIf="column.extraValue=='quantity'" class="quantity full-flex">
              <button [disabled]='+element[column.fieldName]<2' (click)="changeQuantity(element, +element[column.fieldName]-1)" class="btn-icon"><img class="qty" src="../../../assets/images/Company-account/-.svg" alt=""></button>
              {{element ? +element[column.fieldName] : ''}}
              <button class="btn-icon" (click)="changeQuantity(element, +element[column.fieldName]+1)"><img class="qty" src="../../../assets/images/Company-account/+.svg" alt=""></button>
            </span>
          </td>
          <!-- content -->
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
      </table>
      <mat-spinner [diameter]="100" [strokeWidth]='7' [style.display]="isLoading ? 'block' : 'none'"></mat-spinner>
      <div *ngIf="data.length===0 && hideIfEmpty" class="empty">
        <h1 >List is empty</h1>
      </div>
    </div>
    <ng-container *ngIf="!noEditable">
        <div class="new-item form flex-center">
          <form class="full-flex" (keyup.enter)="addItem($event)" [formGroup]="form" autocomplete="off">
          <mat-form-field class="textarea" floatLabel="never" appearance="outline">
            <textarea matInput
                (keydown.enter)="onKeydown($event)"
                cdkTextareaAutosize
                placeholder="Add new item.."
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                formControlName ="productName"
                cdkAutosizeMaxRows="2">
            </textarea>
          </mat-form-field>
          <button *ngIf="!noEditable" (click)="addItem($event)" [disabled]='form.invalid' class="btn-icon"><img src="../../../assets/images/Company-account/+.svg" alt=""></button>
         </form>
        </div>
    </ng-container>
    <ng-container>
      <mat-paginator
        *ngIf="usePagination"
        (page)="updatePagination($event, 'page')"
        class="pagination"
        [length]="totalRows"
        [pageSizeOptions]="paginationSizeOptions">
      </mat-paginator>
    </ng-container>
  </div>
