import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthApiUrls } from './auth.enum';

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient) {}
  register(user: any) {
    return this.httpClient.post(`${environment.apiUrl}/users`, user);
  }
  emailVerify(key: any) {
    return this.httpClient.post(`${environment.apiUrl}/cmspublic/user`, key);
  }
  smsVerify(messageNumber: any, uid: any, mode: any) {
    return this.httpClient.post(`${environment.apiUrl}/cmspublic/phoneVerification/${uid}?mode=${mode}`, messageNumber);
  }

  resendSms({prevSmsUid = '', userLogin = ''}) {
    return this.httpClient.put(`${environment.apiUrl}/cmspublic/new-phone-token`, {smsUid: prevSmsUid, login: userLogin});
  }

  authenticate(user: any) {
    return this.httpClient.post(`${environment.apiUrl}/auth/login`, user);
  }

  authenticateByToken(token: any) {
    console.log("sdad", token)
    return this.httpClient.put(`${environment.apiUrl}/auth/login/refresh`, {}, {
      headers: {
        'x-access-token': token
      }
    });
  }

  resetPassword(data: any, uid: any) {
    return this.httpClient.put(`${environment.apiUrl}${AuthApiUrls.resetPassword}/${uid}`, data);
  }

  requestPassword(data: any) {
    return this.httpClient.post(`${environment.apiUrl}${AuthApiUrls.requestPassword}`, data);
  }
  getRegistrationStatement() {
    return this.httpClient.get(`${environment.apiUrl}/users/getRegistrationStatement`);
  }
}
