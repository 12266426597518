<form *ngIf="imageSize" [formGroup]='form' class="content">
    <div formArrayName='slides' *ngFor="let slide of slides.controls; let i=index">
        <ng-container  [formGroupName]='i'>
            <div class="grid-wrapper section no-border" [ngClass]="{'border-top': i!==0}">
                <div class="box start-1 end-7">
                    <p>Carousel Layout - Slider {{i+1}}
                        <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                         (hardcode)
                    </p>
                    <mat-form-field floatLabel="never" appearance="outline">
                        <mat-select [disabled]='true'  name="layout" formControlName='layout'>
                          <mat-option></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="box start-7 end-13">
                    <button (click)="deleteSlide(i)" *ngIf="i!==0" class="btn btn-gray ml-auto flex-center">
                        <span class="flex-center"><mat-icon>clear</mat-icon></span>
                        Delete Slider {{i+1}}
                    </button>
                </div>
            </div>
            <div class="slide-wrapp">
                <div class="grid-wrapper section image-loader">
                    <div class="box start-1 end-8">
                        <img (click)="openImageConfigurator(i)" *ngIf="slides.value[i].image" class="image" [src]="slides.value[i].image" alt="">
                        <div (click)="openImageConfigurator(i)"  *ngIf="!slides.value[i].image" class="empty-image-wrapper flex">
                            <div class="empty-image flex-center">
                                <img  class="add-photo" src="../../../../../assets/images/add_photo.svg" alt="">
                            </div>
                            <p>
                                Upload Image  {{i+1}}
                            </p>
                        </div>
                    </div>
                    <div class="box start-8 end-13">
                        <p class="image-info">Lorem ipsum des dores forte psume des dores sum
                            des dores forte psum dem des dores foperta ipsum es dores forte psum dem es tas amon eamone ta ipsum des dores forte psum dem des do lorem sento.
                        </p>
                    </div>
                </div>
                <div class="section no-border">
                    <p>Heading  {{i+1}}
                        <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                    </p>
                    <mat-form-field floatLabel="never" appearance="outline">
                      <input matInput
                              placeholder="Please add some heading"
                              formControlName="heading"
                              name="heading">
                    </mat-form-field>
                    <p>Text  {{i+1}}
                        <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                    </p>
                    <mat-form-field disabled class="title" floatLabel="never" appearance="outline">
                        <textarea matInput
                            cdkTextareaAutosize
                            placeholder="Please add some title"
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="6"
                            cdkAutosizeMaxRows="6"
                            formControlName ="text"
                            name="text">
                        </textarea>
                    </mat-form-field>
                    <p>Add Price  {{i+1}}
                        <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                    </p>
                    <mat-form-field floatLabel="never" appearance="outline">
                      <input matInput
                              placeholder="Please add some price"
                              formControlName="price"
                              name="price">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </div>
    <button (click)="addNewSlide()" class="btn btn-full shado ml-auto flex-center new">
        <span class="flex-center">+</span>
        Add New Slider
    </button>
    <div class="grid-wrapper section image-loader border-top">
        <div class="box start-1 end-8">
            <img (click)="openLogoConfigurator()"  *ngIf="form.get('logo').value" class="image" [src]="form.get('logo').value" alt="">
            <div (click)="openLogoConfigurator()"  *ngIf="!form.get('logo').value" class="empty-image-wrapper flex">
                <div class="empty-image flex-center">
                    <img  class="add-photo" src="../../../../../assets/images/add_photo.svg" alt="">
                </div>
                <p>
                    Upload Logo
                </p>
            </div>
        </div>
        <div class="box start-8 end-13">
            <p class="image-info">Lorem ipsum des dores forte psume des dores sum
                des dores forte psum dem des dores foperta ipsum es dores forte psum dem es tas amon eamone ta ipsum des dores forte psum dem des do lorem sento.
            </p>
        </div>
    </div>
</form>