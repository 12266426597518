import { environment } from 'src/environments/environment';
import { IOldPagination } from "./interfaces/old-pagination.model";

export function getServerLink(link: string) {
  if (environment?.production) {
    return 'https://' + link;
  } else {
    return 'http://' + link;
  }
}

export function convertPagination(pagination: IOldPagination) {
  const rowsTo = pagination.page * pagination.pageSize;
  const rowsFrom = rowsTo - pagination.pageSize + 1;
  return {rowsFrom: rowsFrom.toString(), rowsTo: rowsTo.toString() }
}

export function sum(arr: any[], key: string) {
  let sum = 0;
  arr.forEach(val => {
    if (val[key]) {
      sum+= val[key]
    }
  })
  return sum;
}
