<div class="wrapper filter-top" [ngClass]="{'no-border': filtersOff}">
  <div *ngIf="!filtersOff && filtersFlexStyle" class="head  ag-flex-row-stretch ag-flex-column-on-tablet">

    <div style="flex-grow: 1">
      <ng-container *ngTemplateOutlet="filterSearchNew"></ng-container>
    </div>

    <div class="ag-flex-row-stretch ag-flex-column-on-phone" style="flex-grow: 2">
      <div *ngIf="amountFilterData" style="flex-grow: 3">
        <ng-container *ngTemplateOutlet="filterAmountNew"></ng-container>
      </div>
      <div *ngIf="ratingFilterData" style="flex-grow: 3" >
        <ng-container *ngTemplateOutlet="filterRatingNew"></ng-container>
      </div>
      <div style="flex-grow: 5">
        <ng-container *ngTemplateOutlet="filterDateNew"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="!filtersOff && !filtersFlexStyle" class="head">
    <aside class="grid-wrapper head mb-3">

      <div class="box start-1" [ngClass]="{'end-5': !amountFilterData&&!ratingFilterData, 'end-4': amountFilterData&&(ratingFilterData||autoInputSelect)}">
        <ng-container *ngTemplateOutlet="filterSearch"></ng-container>
      </div>

      <div *ngIf="autoInputSelect" class="box start-4 end-7">
        <app-multiple-autocomplete-selector
          [values]="autoInputSelect"
          class="auto-input"
          [noSelectAll]='true'
          [isOnlyOneValue]='true'
          [label]="'Filter By Reader'"
          placeholder="Filter By Reader"
          (valueChangedEvent)='autoInputUpdate($event)'
          [(ngModel)]="autoInputData">
        </app-multiple-autocomplete-selector>
      </div>

      <div *ngIf="amountFilterData" class="box start-4 end-7" [ngClass]="{'start-7': autoInputSelect, 'end-10': autoInputSelect}">
        <ng-container *ngTemplateOutlet="filterAmount"></ng-container>
      </div>

      <div *ngIf="ratingFilterData" class="box start-7 end-10">
        <ng-container *ngTemplateOutlet="filterRating"></ng-container>
      </div>

      <div class="box start-9 end-13"  [ngClass]="{'start-9': !amountFilterData&&!ratingFilterData, 'start-10': amountFilterData&&(ratingFilterData||autoInputSelect)}">
        <ng-container *ngTemplateOutlet="filterDate"></ng-container>
      </div>

    </aside>
  </div>
  <div [ngClass]="{'loading': isLoading, 'use-pagination': usePagination, 'is-empty': data.length===0}" class="table-wrapp">
    <table [hidden]='isLoading||data.length===0' #table mat-table matSort (matSortChange)="updatePagination($event, 'sort')"
      [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.fieldName">
        <!-- header -->
        <th mat-header-cell mat-sort-header *matHeaderCellDef
            class="header">
          <span>{{column.title}}</span>
        </th>
        <!-- header -->
        <!-- content -->
        <td [ngClass]="{opacity: element.status==='lock', long: column.extraValue==='avatar'}" mat-cell *matCellDef="let element" (click)="onRowClick ? onRowClick(element) : ''">
          <span [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                            blueUnderline: ('blue-underline').includes(column.extraClass), longElement: ('longText').includes(column.extraClass)}"
                *ngIf="!column.valuePrepareFunction&&!column.extraValue"
                (click)="column.action ? column.action($event, element) : null">
                  {{element[column.fieldName] ? element[column.fieldName] : '-'}}
          </span>
          <span *ngIf="column.extraValue=='select'" (click)="column.action ? column.action($event, element) : null" >
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? toggleRow(element) : null"
              [checked]="selection.isSelected(element)"
              [aria-label]="checkboxLabel(element)">
            </mat-checkbox>
          </span>
          <span *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
          <span class="avatar" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
           <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
          </span>
          <span *ngIf="column.extraValue=='lock'" (click)="column.action ? column.action($event, element) : null"><img src="../../../assets/images/Table/{{element[column.fieldName]}}.svg" alt=""> </span>
          <span class="blue" *ngIf="column.extraValue=='amount'" (click)="column.action ? column.action($event, element) : null">  {{element[column.fieldName]? element[column.fieldName] : '-'}} <span *ngIf="element[column.fieldName]" class="valute">{{currency}}</span> </span>
          <span class="blue" *ngIf="column.extraValue=='tokens'" (click)="column.action ? column.action($event, element) : null">  {{element[column.fieldName]}} <span class="valute">Tokens</span> </span>
          <span *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
            <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
          </span>
          <span class="product-description" *ngIf="column.extraValue=='productDescription'" (click)="column.action ? column.action($event, element) : null">
            <div class="product-image">
              <img *ngIf="element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="">
              <img *ngIf="!element[column.fieldName].productDescriptionURL" src="../../../assets/images/Table/product-placeholder.png" alt="">
            </div>
            <span>{{element[column.fieldName].productName}}</span>
          </span>
          <span [ngClass]="{'deleted': element.isDeleted}" class="strikethrough" *ngIf="column.extraValue=='strikethrough'" (click)="column.action ? column.action($event, element) : null">
            {{element ? element[column.fieldName] : ''}}
          </span>
          <span [ngClass]="{'paid': element[column.fieldName]==='Paid', 'void': element[column.fieldName]==='Void', 'unpaid': element[column.fieldName]==='Unpaid'}"
                *ngIf="column.extraValue=='paymentStatus'" (click)="column.action ? column.action($event, element) : null">
            {{element ? element[column.fieldName] : ''}}
          </span>
          <span class="blue-underline" *ngIf="column.extraValue=='link'" (click)="openLink(element[column.fieldName])">  <span>Download PDF</span> </span>
        </td>
        <!-- content -->
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="thinner"></tr>
    </table>
    <div *ngIf="data.length===0&&!isLoading" class="empty">
      <img src="../../../../assets/images/Home/bag-empty-3.png" alt="">
      <h1 >List is empty</h1>
    </div>
    <mat-spinner [diameter]="100" [strokeWidth]='7' [style.display]="isLoading ? 'block' : 'none'"></mat-spinner>
  </div>

  <ng-container *ngIf="data.length>0 && usePagination">
    <ng-container *ngFor="let row of [] | paginate: {id: paginationData.id, itemsPerPage: paginationData.pageSize,currentPage: paginationData.getPage(), totalItems: (paginationData.totalItems || totalRows)  }"></ng-container>
    <app-paginator class="paginator" [id]="paginationData.id" (pageChange)="updatePagination($event, 'page')"></app-paginator>
  </ng-container>
</div>

<ng-template #filterSearchNew>
  <atom-search [bGrayMode]="true" (keyup)="textFilter($event)"></atom-search>
</ng-template>


<ng-template #filterAmountNew>
  <div class="amount-filter ag-flex-row f-phone-behavior">
    <div class="ag-flex-row">
      <mat-checkbox (change)='amountFilterUpdate($event)' [(ngModel)]="amountFilterData.useFilter"></mat-checkbox>
      <!-- <p>Filter By Amount: </p> -->
    </div>
    <div class="ag-flex-row-center">
      <mat-form-field floatLabel="never" appearance="outline">
        <span class="less"><</span>
        <input class="amount-input" (keyup.enter)="amountFilterUpdate($event)" type="text" matInput [(ngModel)]="amountFilterData.amount"  [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="amountFilterUpdate($event)" #auto="matAutocomplete">
          <mat-option *ngFor="let type of amountSelect" [value]="type">
            {{type}}
          </mat-option>
        </mat-autocomplete>
        <span class="currency">{{currency}}</span>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #filterRatingNew>
  <div class="dark-select amount-filter ag-flex-row f-phone-behavior">
    <div class="ag-flex-row">
      <mat-checkbox [(ngModel)]="ratingFilterData.useFilter" (change)='rateUpdate($event)'></mat-checkbox>
      <!-- <p>Filter By Rating:</p> -->
    </div>
    <div class="ag-flex-row-center">
      <div class="rating-wrapp">
        <star-rating [staticColor]="'blue'" [hoverEnabled]="true" (ratingChange)="rateUpdate($event)" [rating]="ratingFilterData.rating" [readOnly]='false' [starType]="'svg'" [space]="'between'" [size]="'medium'"></star-rating>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #filterDateNew>
  <div class="date-wrapp ag-flex-row f-phone-behavior" [ngClass]="{'flex-end': !ratingFilterData && !amountFilterData}">
    <div>
      <p>Filter By Date:</p>
    </div>
    <div class="inputs-wrapp ag-flex-row-center">
      <div class="inputs">
        <mat-form-field floatLabel="never" class="date-start" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
          <input matInput
                  placeholder="DD/MM/YY"
                  [matDatepicker]="dateStartFilter"
                  [max]="dateFilterData.dateTo"
                  [(ngModel)]="dateFilterData.dateFrom"
                  (dateInput)="dateUpdate($event)">
                  <mat-datepicker-toggle matSuffix [for]="dateStartFilter"></mat-datepicker-toggle>
                  <mat-datepicker #dateStartFilter startView="month"></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="never" class="date-end" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
          <input matInput
                  placeholder="DD/MM/YY"
                  [min]="dateFilterData.dateFrom"
                  [matDatepicker]="dateEndFilter"
                  [(ngModel)]="dateFilterData.dateTo"
                  (dateInput)="dateUpdate($event)">
                  <mat-datepicker-toggle matSuffix [for]="dateEndFilter"></mat-datepicker-toggle>
                  <mat-datepicker #dateEndFilter startView="month"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterSearch>
  <atom-search [bGrayMode]="true" (keyup)="textFilter($event)"></atom-search>
</ng-template>


<ng-template #filterAmount>
  <div class="dark-select flex amount-filter">
    <div class="flex-align">
      <mat-checkbox (change)='amountFilterUpdate($event)' [(ngModel)]="amountFilterData.useFilter"></mat-checkbox>
      <p>Filter By Amount: </p>
    </div>
    <mat-form-field floatLabel="never" appearance="outline">
      <span class="less"><</span>
      <input class="amount-input" (keyup.enter)="amountFilterUpdate($event)" type="text" matInput [(ngModel)]="amountFilterData.amount"  [matAutocomplete]="auto">
      <mat-autocomplete (optionSelected)="amountFilterUpdate($event)" #auto="matAutocomplete">
        <mat-option *ngFor="let type of amountSelect" [value]="type">
          {{type}}
        </mat-option>
      </mat-autocomplete>
      <span class="currency">{{currency}}</span>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #filterRating>
  <div class="dark-select flex amount-filter">
    <div class="flex-align">
      <mat-checkbox [(ngModel)]="ratingFilterData.useFilter" (change)='rateUpdate($event)'></mat-checkbox>
      <p>Filter By Rating:</p>
    </div>
    <div class="rating-wrapp">
      <star-rating [staticColor]="'white'" [hoverEnabled]="true" [showHalfStars]="true" (ratingChange)="rateUpdate($event)" [rating]="ratingFilterData.rating" [readOnly]='false' [starType]="'svg'" ></star-rating>
    </div>
  </div>
</ng-template>

<ng-template #filterDate>
  <div class="date-wrapp">
    <p>Filter By Date:</p>
    <div class="flex-align">
      <mat-form-field floatLabel="never" class="date-start" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
        <input matInput
                placeholder="DD/MM/YY"
                [matDatepicker]="dateStartFilter"
                [max]="dateFilterData.dateTo"
                [(ngModel)]="dateFilterData.dateFrom"
                (dateInput)="dateUpdate($event)">
                <mat-datepicker-toggle matSuffix [for]="dateStartFilter"></mat-datepicker-toggle>
                <mat-datepicker #dateStartFilter startView="month"></mat-datepicker>
      </mat-form-field>
      <mat-form-field floatLabel="never" class="date-end" [ngClass]="{'invalid': dateFilterData.dateFrom>dateFilterData.dateTo}" appearance="outline">
        <input matInput
                placeholder="DD/MM/YY"
                [min]="dateFilterData.dateFrom"
                [matDatepicker]="dateEndFilter"
                [(ngModel)]="dateFilterData.dateTo"
                (dateInput)="dateUpdate($event)">
                <mat-datepicker-toggle matSuffix [for]="dateEndFilter"></mat-datepicker-toggle>
                <mat-datepicker #dateEndFilter startView="month"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</ng-template>
