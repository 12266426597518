import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
registerLocaleData(localeEnGb);
import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import * as Hammer from 'hammerjs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomMaterialModule } from './@theme/custom-material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './@auth/auth.module';
import { AuthInterceptor } from './@auth/auth.interceptor';
import { MomentUtcDateAdapter } from './_utilities/moment-utc.adapter';
import { ClickOutsideModule } from 'ng-click-outside';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {SocketIoModule} from 'ngx-socket-io';
import {WsService} from './_services/ws.service';
import { PagesPublicModule } from './pages-public/pages-public.module';
import { PagesPublicRoutingModule } from './pages-public/pages-public-routing.module';

@Injectable()
export class GlobalHammerConfig extends HammerGestureConfig {
  // tslint:disable-next-line:no-angle-bracket-type-assertion
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pan: { direction: Hammer.DIRECTION_HORIZONTAL },
  };
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    MatDatepickerModule,
    CustomMaterialModule,
    MatNativeDateModule,
    MatSortModule,
    MatTooltipModule,
    LayoutModule,
    ClickOutsideModule,
    HammerModule,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SocketIoModule,
    PagesPublicModule,
    PagesPublicRoutingModule
  ],
  entryComponents: [
 ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GlobalHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true, closeOnNavigation: true } },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    WsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
