export interface IOldPagination {
  page: number,
  pageSize: number,
  totalRows: number
}

export class OldSortFilterPagination {
  constructor(itemsPerPage?: number) {
      if (itemsPerPage) {
          this.pageSize = itemsPerPage;
      }
  }

  pageIndex = 0;
  pageSize = 10;
  totalItems = 0;
  orderBy = '';
  orderDirection: SortFilterPaginationRequest['orderDirection'] = 'desc';
  filterValue = '';

  id = Math.trunc(Math.random()*1000);

  getPage() {
    return this.pageIndex+1;
  }
  newFormatRequest(): SortFilterPaginationRequest {
      return {
          currentPage: (this.pageIndex+1).toString(),
          itemsPerPage: this.pageSize.toString(),
          orderBy: this.orderBy,
          orderDirection: this.orderDirection,
          filter: this.filterValue
      }
  }
}

export interface SortFilterPaginationRequest {
  currentPage: string;
  itemsPerPage: string;
  orderBy: string;
  orderDirection: 'asc' | 'desc';
  filter: string;
}
