import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isLaptop, isLess1100, isSmall, isMobile, isMediumDesktop } from 'src/app/_services/helpers/detect-mobile';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowsSizeService {
  static isListened : boolean;
  static isMobile$ = new BehaviorSubject(isMobile());
  static isSmall$ = new BehaviorSubject(isSmall());
  static isLess1100$ = new BehaviorSubject(isLess1100());
  static isLaptop$ = new BehaviorSubject(isLaptop());
  static isMediumDesktop$ =  new BehaviorSubject(isMediumDesktop());

  constructor( public breakpointObserver: BreakpointObserver) {
    if (!WindowsSizeService.isListened) {
      this.listen();
      WindowsSizeService.isListened = true;
    }
  }


  listen() {
    this.breakpointObserver
      .observe(['(max-width: 576px)','(max-width: 800px)', '(max-width: 1100px)', '(max-width: 1400px)', '(max-width: 1800px)'])
      .subscribe((state: BreakpointState) => {
        WindowsSizeService.isMobile$.next(state.breakpoints['(max-width: 576px)']);
        WindowsSizeService.isSmall$.next(state.breakpoints['(max-width: 800px)']);
        WindowsSizeService.isLess1100$.next(state.breakpoints['(max-width: 1100px)']);
        WindowsSizeService.isLaptop$.next(state.breakpoints['(max-width: 1400px)']);
        WindowsSizeService.isMediumDesktop$.next(state.breakpoints['(max-width: 1800px)']);
    });
  }




}
