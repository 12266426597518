<div id="main-container" >
    <!-- Header  -->
    <app-header-public *ngIf="!(isMobile$ | async)" class="header desktop" style="z-index: -5;" ></app-header-public>
  
    <mat-sidenav-container class="main-sidenav-container">
      <!-- Left Menu -->
      <mat-sidenav class="desktop navigation-sidenav"  opened role="navigation" [mode]="'side'">
        <app-left-menu [menuList]='menuList'></app-left-menu>
      </mat-sidenav>
      <!--/ Left Menu -->
  
      <!-- Mobile Menu -->
      <mat-sidenav class="mobile mobile-menu" #sidenavMobile fixedInViewport="true" position=end  [opened]="false">
        <app-mobile-menu  *ngIf="(isMobile$ | async)" [user]='user' [menuList]='menuList' [unreadMessages]='unreadMessages'
                          [unreadSupport]='unreadSupport' (toggleEvent)='sidenavMobile.toggle()'>
  
        </app-mobile-menu>
      </mat-sidenav>
      <!--/ Mobile Menu  -->
  
      <!-- Page Content -->
      <mat-sidenav-content [ngClass]="{'bigger-margin': isMenuOpened}" [@onMainContentChange]="isMenuOpened ? 'open': 'close'" role="main" #content>
        <app-header-reader *ngIf="(isMobile$ | async)" (mobileMenuEvent)='mobileMenuEvent($event)' class="header mobile"></app-header-reader>
  
        <div style="display: flex" class="main-duo-container">
          <main class="main-content" style="width: calc(100% - 510px - 70px); display: inline-block">
  
  
  
            <router-outlet></router-outlet>
          </main>
          <div
          class="wallet-holder"
          style="
                display: inline-block;
                padding: 30px 40px;
                text-align: center;
                z-index: 10;
                position: fixed;
                right: 0px;
                width: 430px;
           " >
  
            <app-wallet-iframe></app-wallet-iframe>
  
          </div>
        </div>
  
      </mat-sidenav-content>
      <!--/ Page Content -->
  
    </mat-sidenav-container>
  </div>
  