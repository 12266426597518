import { ComponentsModule } from './../../_components/components.module';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from 'src/app/@theme/custom-material.module';
import { MenuFooterComponent } from './../../_widgets/menu/menu-footer/menu-footer.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    LeftMenuComponent,
    MenuFooterComponent,
    MobileMenuComponent,
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ComponentsModule,
    RouterModule,
    AngularSvgIconModule
  ],
  exports: [
    LeftMenuComponent,
    MenuFooterComponent,
    MobileMenuComponent,
  ]
})
export class MenuModule { }
