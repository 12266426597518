import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from '../../_shared/services/toast.service';
import { AuthUserService } from '../../@auth/auth-user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss']
})
export class PasswordConfirmComponent implements OnInit {
  public form: FormGroup;

  constructor(@Inject (MAT_DIALOG_DATA) public data: any,
              public toastService: ToastService,
              private authUserService: AuthUserService,
              public dialogRef: MatDialogRef<PasswordConfirmComponent>) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl(null, [Validators.required,  Validators.maxLength(50)])
    });
  }

}
