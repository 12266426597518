<div class="content">
    <button class="btn-icon close" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <form [formGroup]="form" autocomplete="off" (submit)='toPay()' (keyup.enter)="toPay()">
        <div class="input-wrapp">
            <p>Type The Amount</p>
            <mat-form-field class="amount-field" floatLabel="never" appearance="outline">
                <input matInput
                        #amountInput
                        [min]='minRecharge'
                        [max]='maxRecharge'
                        step = '10'
                        type="number"
                        placeholder="Type The Amount"
                        formControlName="amount">
                        <span class="currency">{{currency}}</span>
            </mat-form-field>
            <p class="tokens" [@fadeMix] *ngIf="amountInput.value&&amountInput.value<maxRecharge&&tokenRate">You will get: <span>{{amountInput.value*tokenRate}} Tokens</span></p>
        </div>
        <p>Payment Method</p>
        <mat-form-field floatLabel="never" appearance="outline">
            <mat-select formControlName="method"
                        placeholder="Choose Payment Method"
                        name="method">
              <mat-option *ngFor="let method of methods" [value]="method.value">{{ method.viewValue }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="bottom full-flex">
            <button tabindex="3" type="button" (click)="dialogRef.close()" class="btn btn-gray">Cancel</button>
            <button tabindex="2" type="submit" [disabled]='form.invalid' class="btn btn-full shadow">
                Top Up
                <span class="spinner"><mat-spinner [diameter]="20" [style.display]="isLoad ? 'block' : 'none'"></mat-spinner></span>
            </button>
        </div>
    </form>
</div>
