import { Router } from '@angular/router';
import { BasePageComponent } from 'src/app/pages-reader/base-page/pase-page.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AvatarsService } from './../../_shared/services/rest-services/avatars.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent extends BasePageComponent implements OnInit, OnChanges {
  @Input() reviewList: any = [];
  @Input() actualSortType;
  @Output() sortBy = new EventEmitter();
  @Input () useAvatars = true;
  @Input() isDesktop = true;
  @Input() useHeaders = true;
  public sortsType = [
    {viewValue: 'Last Added', value: 'lastAdded'},
    {viewValue: 'Location ASC', value: 'locationASC'},
    {viewValue: 'Location DESC', value: 'locationDESC'},
    {viewValue: 'Rate ASC', value: 'rateASC'},
    {viewValue: 'Rate DESC', value: 'rateDESC'}
  ];

  customOptions: OwlOptions = {
    loop: false,
    dots: false,
    autoWidth: true,
    nav: false,
    navText: ['', ''],
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    margin: 15
  };

  constructor(private avatarsService: AvatarsService, 
              public router: Router) { 
    super('Reviews');
  }

  ngOnInit(): void {
  }

  sortUpdate(event) {
    this.sortBy.emit(this.actualSortType);
  }

  seeAllReviews() {
    this.router.navigate(['reader/all-reviews']);
  }

  ngOnChanges() {
  }
}
