import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-read-feedback',
  templateUrl: './read-feedback.component.html',
  styleUrls: ['./read-feedback.component.scss']
})
export class ReadFeedbackComponent implements OnInit {
  @Input() feedback: any;

  constructor(public router: Router,) { }

  ngOnInit(): void {
  }

  openReceipt() {
    this.router.navigate(['publisher/receipt', this.feedback.receiptUid]);
  }
}
