import { ActivatedRoute } from '@angular/router';
import { NewTicketComponent } from './new-ticket/new-ticket.component';
import { ToastService } from './../../_shared/services/toast.service';
import { AuthUserService } from './../../@auth/auth-user.service';
//import { SupportService } from './../../_shared/services/rest-services/support.service';
import { Subscription } from 'rxjs';
import { BasePageComponent } from 'src/app/pages-reader/base-page/pase-page.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpSupportService } from 'src/app/_shared/services/http-services/http-support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent extends BasePageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public openTickets = [];
  public ticketsList = [];
  public ticketsCount = 0;
  public closedTickets = [];
  public activeTicket: any;
  public ticket: any;
  public userUid = '';
  public statuses = ['open', 'close'];
  public selectedIndexTab = 0;
  public unreadCount = 0;
  public userData;

  constructor(  private dialog: MatDialog,
                private authUserService: AuthUserService,
                private toastService: ToastService,
                public route: ActivatedRoute,
                //private supportService: SupportService,
                private svHttpSupportService: HttpSupportService) {
                  super('Support');
                  this.userData = this.authUserService.getUser();
  }


  ngOnInit(): void {
    this.userUid = this.authUserService.getUserUid();
    this.getTicketsList();
    this.getUnreadCount();
    this.subscription.add(this.route.queryParamMap
      .subscribe ((queryParams: any) => {
        if (queryParams.get('newTicket') === 'downgrade') {
          const data = {
            title: 'Change Plan Request',
            message: 'Hello, \nI would like to downgrade my current subscription plan, \n\nBest Regards, \n' + this.userData.firstName + ' ' + this.userData.surname
          };
          setTimeout(() => {
            this.newTicket(data);
          }, 500);
        }
    }));
  }

  selectedIndex(event) {
    this.selectedIndexTab = event;
    this.activeTicket = '';
    this.getTicketsList();
  }

  getTicketsList() {
    const category = this.statuses[this.selectedIndexTab];
    this.subscription.add(this.svHttpSupportService.getTicketList(category)
    .subscribe ((response: any) => {
      this.ticketsList = response.result.ticketsList;
      this.ticketsCount = response.result.count;
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }

  getUnreadCount() {
    this.subscription.add(this.svHttpSupportService.getUnreadCount()
    .subscribe ((response: any) => {
      this.unreadCount = response.result.counter;
    }));
  }

  openTicket(ticket, toRead?: boolean) {
    if (toRead) {
      this.changeReadStatus(ticket);
    }
    this.subscription.add(this.svHttpSupportService.getTicket(ticket.ticketNumber)
    .subscribe ((response: any) => {
      this.activeTicket = response.result;
      }, (err) => {
        this.toastService.warning(err.error.errors[0].message);
    }));
  }

  reply(ticket?) {
    const data = {
      title: this.activeTicket ? this.activeTicket.ticketsList[this.activeTicket.ticketsList.length - 1].title : ticket.title,
      messageUid: this.activeTicket ? this.activeTicket.ticketsList[this.activeTicket.ticketsList.length - 1].uid : ticket.lastMessageUid,
      reply: true
    };
    const replyDialog = this.dialog.open(NewTicketComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '900px',
      data
    });
    replyDialog.afterClosed()
      .subscribe(result => {
        if (result && this.activeTicket) {
          this.selectedIndexTab = 0;
          this.getTicketsList();
          this.openTicket(this.activeTicket, true);
        }
      });
  }

  changeReadStatus(ticket) {
    this.subscription.add(this.svHttpSupportService.changeReadStatus(ticket.ticketNumber, {isRead: true})
    .subscribe ((response: any) => {
      this.getTicketsList();
      this.getUnreadCount();
    }));
  }

  closeTicket(ticket?) {
    const ticketNumber = this.activeTicket ? this.activeTicket.ticketNumber : ticket.ticketNumber;
    this.subscription.add(this.svHttpSupportService.changeStatus(ticketNumber, {isOpen: false})
      .subscribe ((response: any) => {
        this.getTicketsList();
        this.activeTicket = '';
    }));
  }

  newTicket(data?: any) {
      const newTicketDialog = this.dialog.open(NewTicketComponent, {
        disableClose: true,
        backdropClass: 'dialog-back',
        width: '900px',
        data: data ? data : null
      });
      newTicketDialog.afterClosed()
        .subscribe(result => {
          if (result) {
            this.selectedIndexTab = 0;
            this.getTicketsList();
            this.getUnreadCount();
          }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
