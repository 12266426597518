import { Pagination } from './../../_models/api.model';
import { MatTableDataSource } from '@angular/material/table';
import { AuthUserService } from './../../@auth/auth-user.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

const DEBOUNCE_TIME = 800;

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit, OnChanges {
  @Input() data = [];
  @Input() pagination: Pagination;
  @Input() columns: any = {};
  @Input() isLoading;
  @Input() useOldIcon = false;

  @Output() paginationUpdate = new EventEmitter<Pagination>();

  dataSource: any;
  displayedColumns: string[] = [];
  currency = '';

  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit(): void {
  }

  onPageUpdate(event) {
    this.pagination.currentPage = event;
    this.paginationUpdate.emit(this.pagination);
  }

  ngOnChanges() {
    const columns = [];
    for (const column of this.columns) {
      columns.push(column.fieldName);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
  }


}
