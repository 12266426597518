import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { FiltersMobileComponent } from '.././../../_dialogs/filters-mobile/filters-mobile.component';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-select-table-mobile',
  templateUrl: './select-table-mobile.component.html',
  styleUrls: ['./select-table-mobile.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SelectTableMobileComponent implements OnInit, OnChanges {
  public subscription = new Subscription();
  @Input() filterOff: boolean;
  @Input() useBlueIcon: boolean = false;
  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() selectedRows: (event: any) => any;
  @Input() isLoading: boolean;
  @Input() maxColumns = 3;
  @Input() autoSelect: boolean;
  @Input() isLockTable: boolean;
  @Input() selectedRowsList = [];
  @Input() clearSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input() useExtraBtn: boolean;
  @Input() selectOff: boolean;
  @Input() useExpand: boolean;
  @Input() paginationData;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  @Output() paginationUpdate = new EventEmitter();
  @Output() btnClicked = new EventEmitter();
  @Output() btnClicked2 = new EventEmitter();
  @Output() elementClicked = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public dataSource: any;
  public displayedColumns: string[] = [];
  public expandedColumn = [];
  selection = new SelectionModel(true, []);
  public ratingFilterCount = 0;
  public expandedElement;
  public currency = '';

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');


  constructor(public dialog: MatDialog,
              private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
   }

  ngOnInit() {
    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }

  updatePagination(data, type) {
    this.paginationUpdate.emit({data, type});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  textFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  elementClick(columnName, row) {
    this.elementClicked.emit({columnName, row});
  }


  toAutoSelect() {
    if (this.autoSelect) {
      this.selection = new SelectionModel(true, []);
      for (const row of this.data) {
        row.isSelected ? this.toggleRow(row) : null;
      }
    }
  }

  openColumnFilter() {
    const filtersDialog = this.dialog.open(FiltersMobileComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '350px',
      data: {columns: this.columns, maxColumns: this.maxColumns},
    });
    this.subscription.add(filtersDialog.afterClosed()
      .subscribe(result => {
        this.tableInitialization();
    }));
  }


  ngOnChanges() {
    this.tableInitialization();
  }

  tableInitialization() {
    this.clearSelected.subscribe((event: any) => {
      this.selection.clear();
    });
    const columns = [];
    const expandedColumn = [];
    for (const column of this.columns) {
      column.toUseMobile ? columns.push(column.fieldName) : expandedColumn.push(column);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.toAutoSelect();
    this.displayedColumns = columns;
    this.selection = new SelectionModel(true, [...this.selectedRowsList]);
    this.expandedColumn = expandedColumn;
    !this.usePagination ? this.dataSource.sort = this.sort : null;
  }

}
