import { AnalyticsComponent } from './analytics/analytics.component';
import { HistoryDetailsComponent } from './history-details/history-details.component';
import { AdHistoryComponent } from './ad-history/ad-history.component';
import { AdEventService } from './_services/ad-event.service';
import { ManageAdsComponent } from './manage-ads.component';
import { ComponentsModule } from '../../_components/components.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/@theme/custom-material.module';
import { CommonModule } from '@angular/common';
import { AdCreatorComponent } from './ad-creator/ad-creator.component';
import { ImageAdComponent } from './ad-creator/image-ad/image-ad.component';
import { VideoAdComponent } from './ad-creator/video-ad/video-ad.component';
import { CarouselAdComponent } from './ad-creator/carousel-ad/carousel-ad.component';
import { AdPreviewComponent } from './ad-preview/ad-preview.component';
import { AdConfirmComponent } from './_dialogs/ad-confirm/ad-confirm.component';


@NgModule({
  declarations: [
    ManageAdsComponent,
    AdCreatorComponent,
    AdHistoryComponent,
    ImageAdComponent,
    VideoAdComponent,
    CarouselAdComponent,
    AdPreviewComponent,
    AdConfirmComponent,
    HistoryDetailsComponent,
    AnalyticsComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AdEventService
  ],
  entryComponents: [
    AdConfirmComponent
  ]
})
export class ManageAdsModule {}
