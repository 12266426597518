<section>
    <header>
        <h3>RECEIPTIUM ID <app-tooltip [key]="tooltip"></app-tooltip></h3>
    </header>
    <div class="wrapper" [ngClass]="{'ag-flex-row': !isAllProfileFieldsComplited}">
      <div class="full-flex">
        <ng-container *ngIf="!isLoading">
          <div class="info" [ngClass]="{'hiden-content': isImagesLoading}">
              <img [style.marginBottom]="isAllProfileFieldsComplited ? '8px' : '0px'" class="logo" src="../../../assets/images/New design/logo-white.svg" alt="Receiptium logo">
              <div class="name">{{checkOutData.login}}</div>
              <div class="id">{{checkOutData.receiptiumId}}</div>
          </div>
          <div *ngIf="isAllProfileFieldsComplited" class="codes flex" [ngClass]="{'hiden-content': isImagesLoading}">
              <img class="qr" [src]="checkOutData.qrCode" (load)="onImgCodesLoaded()" alt="">
              <img class="barcode" [src]="checkOutData.barcode" (load)="onImgCodesLoaded()" alt="">
          </div>
          <div *ngIf="!isAllProfileFieldsComplited" class="to-complite-text">
              <a [routerLink]="profileLink">You must complete your Profile so RECEIPTIUM can generate your QR Code.</a>
          </div>
        </ng-container>
        <app-loader *ngIf="isLoading || isImagesLoading" [diameter]='60' [strokeWidth]='5'></app-loader>
      </div>
    </div>
</section>
