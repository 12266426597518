<div class="filter-wrapp" [class.dark]="bDarkMode" [class.gray]="bGrayMode">
  <input nbInput
    class="filter"
    fullWidth
    matInput
    [(ngModel)]="text"
    (keyup.enter)="onEnter.emit(text)"
    (keyup)="textChanged.emit(text)"
    placeholder="Search..."
    name="filter">
  <mat-icon class="search">search</mat-icon>
</div>
