
<div class="content-wrapp">
    <div class="top full-flex">
        <p class="topic">Ticket# {{ticket.ticketNumber}}</p>
        <button (click)="reply()" class="btn btn-full shadow full-flex">
            <img src="../../../../assets/images/Messages/reply.png" alt=""> Reply
        </button>
    </div>
    <div #chatArea *ngIf="ticket" class="content">
        <button class="btn-icon close" (click)="this.dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
        <div *ngFor="let message of ticket.ticketsList; let i = index" class="wrapper">
            <div class="header" [ngClass]="{'no-subject': i!==0}">
                <div class="head full-flex">
                    <p *ngIf="i===0" class="topic flex"><span class="field-name">Subject: </span>{{message.title}}</p>
                    <p class="date">{{message.date}}</p>
                </div>
                <p class="from flex">
                    <span class="field-name"> From:</span> {{message.name}}
                </p>
            </div>
            <div class="body">
                <textarea class="message-text" [innerHTML]="message.message" readonly></textarea>
                <div *ngIf="message.attachmentsList.length>0" class="attachments at-desktop">
                    Attachments
                    <div class="files flex mt-3">
                        <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5" *ngFor="let file of message.attachmentsList">
                            <div class="file dark">
                                <img  *ngIf='file.type=="image/jpeg" || file.type =="image/png"' [src]="file.imgLink" alt="">
                                <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/tickets/pdf.png" alt="">
                                <img *ngIf='file.type!="image/jpeg"
                                        && file.type !="image/png"
                                        && file.type !="application/pdf"' src="../../../../assets/images/tickets/document.png" alt="">
                                <span>{{file.fileName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <app-attachments-mobile class="at-mobile" *ngIf="message.attachmentsList.length>0" [list]='message.attachmentsList'></app-attachments-mobile>
            </div>
        </div>
    </div>
</div>



