<div class="content">
    <h2>Preview Your Ad</h2>
    <ng-container #image>
        <div #imageDiv *ngIf="mainParams.size&&this.adTypes.image.id==this.mainParams.adType&&specParams" class="ad-box full-flex"
            [ngClass]="{'no-border': backImage}"
            [style.background]="backImage ? 'url('+ backImage +')': 'none'"
            [style.height.px]="mainParams.size.height"
            [style.width.px]="mainParams.size.width">
            <div *ngIf="specParams" class="left">
                <h1>{{specParams.heading}}</h1>
                <div *ngIf="specParams.price" class="price">
                    {{specParams.price}}
                </div>
            </div>
            <div class="right">
                <div *ngIf="specParams.logo||specParams.text" class="logo">
                    <span>
                        <img [src]="specParams.logo" alt="">
                    </span>
                </div>
                <div *ngIf="specParams.text" class="text">
                    <p >
                        {{specParams.text}}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
    <div  #carousel class="car-wrapp" *ngIf="mainParams.size&&this.adTypes.carousel.id==this.mainParams.adType&&specParams">
        <ng-container *ngFor="let slide of specParams.slides; let i = index">
            <h6>Slider {{i+1}}</h6>
            <div #carouselDivs class="ad-box full-flex mb-5"
                [ngClass]="{'no-border': slide.image}"
                [style.background]="slide.image ? 'url('+ slide.image +')': 'none'"
                [style.height.px]="mainParams.size.height"
                [style.width.px]="mainParams.size.width">
                <div *ngIf="slide" class="left">
                    <h1>{{slide.heading}}</h1>
                    <div *ngIf="slide.price" class="price">
                        {{slide.price}}
                    </div>
                </div>
                <div class="right">
                    <div *ngIf="specParams.logo||slide.text" class="logo">
                        <span>
                            <img [src]="specParams.logo" alt="">
                        </span>
                    </div>
                    <div *ngIf="slide.text" class="text">
                        <p >
                            {{slide.text}}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <div class="bottom flex-center">
            <button [disabled]='activeSlide===0' (click)="activeSlide=activeSlide-1" class="btn-icon flex-center mr-3">
                <img src="../../../../assets/images/Home/arrow_l.svg" alt="">
            </button>
            <p class="slide-count">{{activeSlide+1}}</p>
            <button [disabled]='activeSlide===specParams.slides.length-1' (click)="activeSlide=activeSlide+1" class="btn-icon flex-center ml-3">
                <img src="../../../../assets/images/Home/arrow_r.svg" alt="">
            </button>
        </div> -->
    </div>
    <ng-container #video *ngIf="mainParams.size&&this.adTypes.video.id==this.mainParams.adType&&specParams" >
        <div *ngIf="specParams.video" class="video-wrapp flex-center">
            <iframe [src]='safeUrl' frameborder="0" allow="autoplay;encrypted-media" [width]="mainParams.size.width" [height]="mainParams.size.height" ></iframe>
        </div>
    </ng-container>
    <div *ngIf="!mainParams.size||!specParams" class="advertisement"
                [style.height.px]="mainParams.size ? mainParams.size.height: 'auto'"
                [style.width.px]="mainParams.size ? mainParams.size.width: 'auto'">
        Your AD
    </div>
</div>