<form *ngIf="videoSize" [formGroup]='form' class="content">
    <div class="section">
        <p>Link to youtube video
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </p>
        <mat-form-field floatLabel="never" appearance="outline">
          <input matInput
                  placeholder="Please add a link to youtube video"
                  formControlName="video"
                  name="video">
        </mat-form-field>
    </div>
</form>