import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthUserService } from "../@auth/auth-user.service";
import { HttpMessagesService } from "../_shared/services/http-services/http-messages.service";
import { HttpSupportService } from "../_shared/services/http-services/http-support.service";
import { MenuService } from "../_shared/services/menu.service";
import { WindowsSizeService } from "../_shared/services/window-size.service";
import { NAVIGATION_SIDE_NAV_PUBLIC } from "./menus";

@Component({
  selector: "app-pages-public",
  templateUrl: "./pages-public.component.html",
  styleUrls: ["./pages-public.component.scss"],
})
export class PagesPublicComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  public isMobile$: BehaviorSubject<boolean>;
  // Mobile Menu
  @ViewChild("sidenavMobile", { static: true }) sidenavMobile: MatSidenav;
  @ViewChild("content", { static: true }) content: any;
  public user;
  // Left Menu
  public menuList = NAVIGATION_SIDE_NAV_PUBLIC;
  public isMenuOpened: boolean;

  constructor(
    public router: Router,
    public menuService: MenuService,
    public authUserService: AuthUserService,
    public dialog: MatDialog,
    private httpMessagesService: HttpMessagesService,
    private httpSupportService: HttpSupportService,
    // DO NOT DELETE THIS IMPORT \/
    private windowsSizeService: WindowsSizeService
  ) {
    this.isMobile$ = WindowsSizeService.isLess1100$;
    this.menuService.toggleMenu.subscribe(() => {
      this.isMenuOpened = !this.isMenuOpened;
    });

    //this.getUnreadCounters();
    this.toScrollTop();
  }

  mobileMenuEvent(event) {
    this.user = event;
    this.sidenavMobile.toggle();
  }

  // Unread Messages/Support
  // getUnreadCounters() {
  //   this.subscription.add(
  //     forkJoin([
  //       this.httpMessagesService.getUnreadCount(),
  //       this.httpSupportService.getUnreadCount(),
  //     ]).subscribe((response: any) => {
  //       this.unreadMessages = response[0].result.counter;
  //       this.unreadSupport = response[1].result.counter;
  //     })
  //   );
  // }

  toScrollTop() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.content.elementRef.nativeElement.scrollTop = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
