import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chartLegendPipe'
})
export class ChartLegendPipe implements PipeTransform {
    transform(value: any, list: [], colorsList: []) {
      const i = list.findIndex((el:any) => el.name === value );
      return colorsList[i];
    }
}
