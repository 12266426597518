import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  @Input() statsCount = 3;
  @Input() theme = 'white';
  @Input() data = [];
  @Input() useLastSeparator: boolean;
  public currency = '';
  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit(): void {
  }

}
