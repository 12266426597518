import { ReplyMessageComponent } from '../reply-message/reply-message.component';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MessagesService } from './../../../_shared/services/rest-services/messages.service';
import { Component, OnInit, Inject } from '@angular/core';
import { getServerLink } from 'src/app/_shared/utils';

@Component({
  selector: 'app-read-messsages-dialog',
  templateUrl: './read-messsages-dialog.component.html',
  styleUrls: ['./read-messsages-dialog.component.scss']
})
export class ReadMesssagesDialogComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  public message: any;
  constructor(private messagesService: MessagesService,
              private dialog: MatDialog,
              @Inject (MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ReadMesssagesDialogComponent>,
              ) { }

  ngOnInit(): void {
    this.getMessage();
  }

  getMessage() {
    this.subscription.add(this.messagesService.getMessage(this.data.messageUid)
    .subscribe ((response: any) => {
      this.message = response.result;
      this.changeReadStatus();
    }));
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.messagesService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      })
    );
  }

  reply() {
    const replyDialog = this.dialog.open(ReplyMessageComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '900px',
      data: {message: this.message, category: 'Inbox'}
    });
  }

  changeReadStatus() {
    this.subscription.add(this.messagesService.changeReadStatus(this.data.messageUid, {isRead: true})
    .subscribe ((response: any) => {}));
  }

}
