import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { ReadTicketDialogComponent } from './../support/read-ticket-dialog/read-ticket-dialog.component';
import { ReadMesssagesDialogComponent } from './../messages/read-messsages-dialog/read-messsages-dialog.component';
import { ReadMessageComponent } from './../messages/read-message/read-message.component';
import { SearchService } from './../../_shared/services/rest-services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public searchData: any;
  public searchResult = [];
  public isPublisher: boolean;
  public userUid = '';
  public testSearchResult = [
    {header: 'Pepperoni', category: 'Products', date: 'Fri 05.22 08:05'},
    {header: 'manager Pablo Asda', category: 'Messages', date: 'Mon 04.22 12:35'},
    {header: 'aleja Pokoju 6', category: 'Receipts', date: 'Tue 02.21 11:12'}
  ];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private authUserService: AuthUserService,
              private searchService: SearchService) {
                this.isPublisher = this.authUserService.isPublisher();
                this.userUid = this.authUserService.getUserUid();
              }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParamMap
      .subscribe ((queryParams: any) => {
        this.searchData = queryParams.get('searchData');
        this.isPublisher ? this.toSearchPublisher() : this.toSearchReader();
    }));
  }

  toSearchReader() {
    this.subscription.add(this.searchService.toSearchReader(this.userUid, {words: this.searchData})
    .subscribe ((response: any) => {
      this.searchResult = response.result.resultList;
    }));
  }

  toSearchPublisher() {
    this.subscription.add(this.searchService.toSearchPublisher(this.userUid, {words: this.searchData})
    .subscribe ((response: any) => {
      this.searchResult = response.result.resultList;
    }));
  }

  checkToShowContentType(contentType) {
    if ( !contentType?.includes('Receipt') && !contentType?.includes('Message') && !contentType?.includes('Support')) {
      return true;
    }
    return false;
  }



  open(row) {
    switch (row.category) {
      case 'Receipts':
        this.router.navigate(['reader/receipt', row.id]);
        break;
      case 'Messages':
        this.openMessage(row.id);
        break;
      case 'Support':
        this.openTicket(row.id);
        break;
    }
  }

  openMessage(messageUid) {
    this.dialog.open(ReadMesssagesDialogComponent, {
      backdropClass: 'dialog-back',
      width: '900px',
      data: {messageUid},
    });
  }

  openTicket(ticketNumber) {
    this.dialog.open(ReadTicketDialogComponent, {
      backdropClass: 'dialog-back',
      width: '900px',
      data: {ticketNumber},
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
