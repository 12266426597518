<div class="m-rec-one">
  <div>{{oReview.location}}</div>
  <div class="ag-flex-row-between">
    <div class="m-rec">{{oReview.nickName}}</div>
    <div class="ag-t-r">{{oReview.reviewDate}}</div>
  </div>
  <div class="m-text">{{oReview.content}}</div>
  <div class="ag-flex-row-between align-center m-footer">
    <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="oReview.rating"></star-rating>
    <img  [src]='oReview.companyAvatarLink' alt="">
  </div>
</div>
