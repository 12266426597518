import { MatTableDataSource } from '@angular/material/table';
import { AuthUserService } from './../../../@auth/auth-user.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Pagination } from 'src/app/_models/api.model';

@Component({
  selector: 'app-simple-table-mobile',
  templateUrl: './simple-table-mobile.component.html',
  styleUrls: ['./simple-table-mobile.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SimpleTableMobileComponent implements OnInit {
  @Input() data: any = [];
  @Input() pagination: Pagination;
  @Input() columns: any = {};
  @Input() isLoading;
  @Input() disableExpand: boolean;
  @Input() perExpandRow = 2;
  @Output() paginationUpdate = new EventEmitter<Pagination>();
  public dataSource: any;
  public displayedColumns: string[] = [];
  public expandedColumns = [];
  public expandedElement;
  public currency = '';


  constructor(private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
  }

  ngOnInit() {
  }

  countRows(length: number) {
    if (length > 0) {
      const count = Math.ceil(length/this.perExpandRow);
      return Array.apply(null, {length: count}).map(Number.call, Number);
    } else {
      this.disableExpand = true;
      return [];
    }
  }

  onPageUpdate(event) {
    this.pagination.currentPage = event;
    this.paginationUpdate.emit(this.pagination);
  }

  ngOnChanges() {
    this.tableInitialization();
    this.expandedElement = null;
  }

  tableInitialization() {
    const columns = [];
    const expandedColumn = [];
    for (const column of this.columns) {
      column.toUseMobile ? columns.push(column.fieldName) : expandedColumn.push(column);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.displayedColumns = columns;
    this.expandedColumns = expandedColumn;
  }

}
