import { Component } from '@angular/core';
import {WsService} from './_services/ws.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private wsService: WsService, private router: Router) {
    this.wsService.connectToApi();

    this.router.events.subscribe((navigationEvent) => {
      if (navigationEvent instanceof NavigationEnd) {
        this.wsService.emit('route_changed', navigationEvent.url);
        this.wsService.lastUrl = navigationEvent.url;
      }
    });
  }
}
