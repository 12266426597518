import { AuthUserService } from '../../../@auth/auth-user.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-messages-left-menu',
  templateUrl: './messages-left-menu.component.html',
  styleUrls: ['./messages-left-menu.component.scss']
})
export class MessagesLeftMenuComponent implements OnInit, OnChanges {
  @Input() list = [];
  @Input () listCount = 0;
  @Input () isFeedback: boolean;
  @Output() clickItemEvent = new EventEmitter();
  @Output() replyEvent = new EventEmitter();
  @Output() archiveEvent = new EventEmitter();
  public activeItem;
  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges()  {
  }

  clickItem(ticket) {
    this.clickItemEvent.emit(ticket);
    this.activeItem = ticket;
  }

  doAction(event, item) {
    if (event.action === 'trash') {
      this.archiveEvent.emit(item);
    }
    if (event.action === 'edit') {
      this.replyEvent.emit(item);
    }
  }



}
