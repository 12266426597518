import { Input, Directive } from '@angular/core';

@Directive()
export abstract class BasePageComponent {
  @Input() pageName: string;

  constructor(pageName: string) {
    this.pageName = pageName;
  }
}
