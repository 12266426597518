<app-page class="page">
    <app-page-header class="header">
        <button *ngIf="analiticsDetailsData" (click)="analiticsDetailsData=null" class="btn-icon back-btn">
            <mat-icon>keyboard_backspace</mat-icon>
        </button> {{pageName}}
    </app-page-header>
    <div class="wrapper">
        <app-page-body  class="main-page" [ngClass]="{'half': activeTab===0}">
            <mat-tab-group [selectedIndex]='activeTab' (selectedIndexChange)='changeActualTab($event)' class="cards-menu">
                <mat-tab label="AD Creator">
                    <app-ad-creator *ngIf="activeTab===0" (sentEvent)='activeTab=1'></app-ad-creator>
                </mat-tab>
                <mat-tab label="AD History">
                    <app-ad-history (openDetailsEvent)='analiticsDetailsData=$event' *ngIf="activeTab===1&&!analiticsDetailsData"></app-ad-history>
                    <app-history-details [adData]='analiticsDetailsData' *ngIf="activeTab===1&&analiticsDetailsData"></app-history-details>
                </mat-tab>
                <mat-tab label="Analytics">
                  <app-analytics *ngIf="activeTab===2"></app-analytics>
              </mat-tab>
            </mat-tab-group>
        </app-page-body>
        <div  #previewWrapp *ngIf="activeTab===0" class="preview-wrapp">
            <app-page-body class="preview">
                <app-ad-preview></app-ad-preview>
            </app-page-body>
        </div>
    </div>
</app-page>
