import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AvatarsService {
  constructor(private httpClient: HttpClient) {}

  getAvatar(uid: any) {
    return this.httpClient.get(`${environment.apiUrl}/avatars/${uid}`);
  }

  getBlobAvatar(id: any) {
    return this.httpClient.get(`${environment.apiUrl}/avatars/${id}?blob=true`, { responseType: 'blob' });
  }

}
