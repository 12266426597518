<form *ngIf="!sent" [formGroup]='form' class="content">
    <h2>Design Your Ad</h2>
    <p class="description">
      Use this panel to add your advertisement campaign. There are 3 advert types to choose from namely images, videos and the carousel styled images. Billing will be based on the advert type selected.
    </p>
    <section class="ad-types">
        <h3>Ad Type
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </h3>
        <mat-radio-group *ngIf="adTypes.image" formControlName='adType'>
            <div class="grid-wrapper">
                <div class="box start-1 end-5">
                    <mat-radio-button #image class="ad-type" [value]="adTypes.image.id" [ngClass]="{'active': adType.value===image.value}">
                        <img src="../../../../assets/images/AdCreator/image_ad.svg" alt="">
                        <mat-checkbox (change)="$event.checked?adType.patchValue(image.value):adType.patchValue(null)"
                                    [checked]='adType.value===image.value' class="checkbox" required >
                        </mat-checkbox>
                        <p>Image</p>
                    </mat-radio-button>
                </div>
                <div class="box start-5 end-9">
                    <mat-radio-button #video class="ad-type" [value]="adTypes.video.id" [ngClass]="{'active': adType.value===video.value}">
                        <img src="../../../../assets/images/AdCreator/video_ad.svg" alt="">
                        <mat-checkbox (change)="$event.checked?adType.patchValue(video.value):adType.patchValue(null)"
                                        [checked]='adType.value===video.value' class="checkbox" required >
                        </mat-checkbox>
                        <p>Video</p>
                    </mat-radio-button>
                </div>
                <div class="box start-9 end-13">
                    <mat-radio-button #carousel class="ad-type" [value]="adTypes.carousel.id" [ngClass]="{'active': adType.value===carousel.value}">
                        <img src="../../../../assets/images/AdCreator/carousel_ad.svg" alt="">
                        <mat-checkbox (change)="$event.checked?adType.patchValue(carousel.value):adType.patchValue(null)"
                                        [checked]='adType.value===carousel.value' class="checkbox" required >
                        </mat-checkbox>
                        <p>Carousel</p>
                    </mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
    </section>
    <div class="section">
        <div class="grid-wrapper">
            <div class="box start-1 end-7">
                <p>Ad Size
                    <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                </p>
                <mat-form-field floatLabel="never" appearance="outline">
                    <mat-select #adSize  name="size" formControlName='size'>
                        <mat-option *ngFor="let size of adSizes" [value]="size">{{ size.width }} x {{ size.height }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="box start-7 end-13">
                <p>Target Page
                    <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                </p>
                <mat-form-field floatLabel="never" appearance="outline">
                    <mat-select [disabled]='targetPages.length===0'  name="size" formControlName='page'>
                        <mat-option *ngFor="let page of targetPages" [value]="page.pageId">{{ page.pageName }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <p>URL Address
            <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
        </p>
        <mat-form-field class="w-100" floatLabel="never" appearance="outline">
          <input matInput
                  placeholder="Please add an URL Address"
                  formControlName="url">
        </mat-form-field>
    </div>
    <ng-container *ngIf="adType.value&&form.get('size').value">
        <section>
            <app-image-ad [imageSize]="form.get('size').value" *ngIf="adType.value === adTypes.image.id"></app-image-ad>
            <app-video-ad [videoSize]="form.get('size').value"  *ngIf="adType.value === adTypes.video.id"></app-video-ad>
            <app-carousel-ad [imageSize]="form.get('size').value" *ngIf="adType.value === adTypes.carousel.id"></app-carousel-ad>
        </section>
        <div class="section">
            <p>Target Groups
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                (hardcode)
            </p>
            <mat-form-field floatLabel="never" appearance="outline">
                <mat-select [disabled]='true'  name="targetGroups" formControlName='targetGroups'>
                    <mat-option ></mat-option>
                </mat-select>
            </mat-form-field>
            <div class="grid-wrapper align-end">
                <div class="box start-1 end-5">
                    <p>Duration of the Ad
                        <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
                    </p>
                    <mat-form-field floatLabel="never" appearance="outline">
                        <input matInput
                                #fromDate
                                placeholder="DD/MM/YYYY"
                                [min]="today"
                                [max]="form.get('toDate').value"
                                [matDatepicker]="fromDatepicker"
                                formControlName="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #fromDatepicker startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="box start-5 end-9">
                    <mat-form-field floatLabel="never" appearance="outline">
                        <input matInput
                                #toDate
                                placeholder="DD/MM/YYYY"
                                [min]="form.get('fromDate').value ? form.get('fromDate').value : today"
                                [matDatepicker]="toDatepicker"
                                formControlName="toDate"
                                name="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #toDatepicker startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="box start-9 end-13">
                    <p>Number of Days</p>
                    <mat-form-field class="days" floatLabel="never" appearance="outline">
                        <input #daysCount matInput
                                [ngModelOptions]="{standalone: true}"
                                [(ngModel)]='durationDays'
                                disabled
                                type='number'
                                placeholder="0 days">
                        </mat-form-field>
                </div>
            </div>
        </div>
        <div class="section calc">
            <p>Daily Budget
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
            </p>
            <div class="budget-amount flex-center">
                <div class="amount">{{dailyBudgetTest| number : '1.2-2'}}</div>
                <span class="currency">Tokens</span>
            </div>
            <!-- <mat-slider #dailyBudget class="slider"
                name="dailyBudget"
                [disabled]='true'
                formControlName='dailyBudget'
                [value]='dailyBudgetTest'
                step="50"
                max='500'
                min="50" >
            </mat-slider> -->
            <!-- <p class="calc-info mt-5 mb-3">Actual amounts Spend on individual days may vary.
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
            </p> -->
            <p  class="calc-info mt-5">Estimated number of clicks per day: <b>7 - 20 </b>
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
            </p>
            <div *ngIf="daysCount.value&&toDate.value" class="total-wrapp">
                <p class="calc-info">
                    You will spend a total of <b>{{daysCount.value*dailyBudgetTest| number : '1.2-2'}} Tokens.</b>
                    The campaign will run for {{daysCount.value}} days and will end on {{form.get('toDate').value| date:"MMMM dd, yyyy"}}
                </p>
            </div>

        </div>
        <div class="section payment">
            <p>Payment Method
                <mat-icon  matTooltip="Info about the action" matTooltipPosition="after" class="tooltip">info</mat-icon>
            </p>
            <div class="card">
                RECEIPTIUM Wallet
                <!-- VISA *1446
                <button class="btn-icon ml-2">Change</button> -->
            </div>
        </div>
    </ng-container>
    <p class="rules">Clicking the Promote button means that you accept <span> Regulations.</span></p>
    <div class="grid-wrapper botttom mb-4">
        <div class="box start-1 end-5">
            <button (click)="toClearForm()" class="btn btn-gray clear">Clear</button>
        </div>
        <div class="box start-5 end-9">
            <button class="btn btn-gray view">View for Reader</button>
        </div>
        <div class="box start-9 end-13">
            <button [disabled]='form.invalid||!childFormValidate' (click)="generatePromote()" class="btn btn-green shadow ml-auto">
                Promote
                <span class="spinner"><mat-spinner [diameter]="20" [style.display]="isLoad ? 'block' : 'none'"></mat-spinner></span>
            </button>
        </div>
    </div>
</form>
