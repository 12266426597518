<div class="content">
    <div class="carousel">
        <owl-carousel-o class="carousel-multi" id="carousel" [options]="desktopOptions" #owlCar>
            <ng-container *ngFor="let item of list">
                <ng-template [width]="size==='xl' ? 140 : 100" carouselSlide>
                    <div class="item" [ngClass]="{'t-blue': theme==='blue', 'sm' : size ==='sm'}">
                        <div class="amount flex">
                            {{item.value}}
                            <span *ngIf="item.isAmount && item.value" class="currency">{{currency}}</span>
                            <span *ngIf="item.isToken && item.value" class="currency">RCPT</span>
                        </div>
                        <div class="field-name">
                            {{item.fieldName}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>
