import { NewTicketComponent } from './../new-ticket/new-ticket.component';
import { MessagesService } from './../../../_shared/services/rest-services/messages.service';
import { Subscription } from 'rxjs';
import { SupportService } from './../../../_shared/services/rest-services/support.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getServerLink } from 'src/app/_shared/utils';

@Component({
  selector: 'app-read-ticket-dialog',
  templateUrl: './read-ticket-dialog.component.html',
  styleUrls: ['./read-ticket-dialog.component.scss']
})
export class ReadTicketDialogComponent implements OnInit {
  @ViewChild('chatArea') private chatArea: ElementRef;
  private subscription: Subscription = new Subscription();
  public ticket: any;

  constructor(private supportService: SupportService,
              private messagesService: MessagesService,
              private dialog: MatDialog,
              @Inject (MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ReadTicketDialogComponent>,
              ) { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 100);
    this.getTicket();
  }

  getTicket() {
    this.subscription.add(this.supportService.getTicket(this.data.ticketNumber)
    .subscribe ((response: any) => {
      this.ticket = response.result;
      this.changeReadStatus();
    }));
  }

  downloadDocument(fileUid: any) {
    this.subscription.add(this.messagesService.getFile(fileUid)
      .subscribe((response: any) => {
        window.open(getServerLink(response.link), '_blank');
      })
    );
  }

  scrollToBottom(): void {
    try {
      this.chatArea.nativeElement.scrollTop = this.chatArea.nativeElement.scrollHeight;
    } catch (err) { }
  }

  reply() {
    const replyDialog = this.dialog.open(NewTicketComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '900px',
      data: {message: this.ticket.ticketsList[this.ticket.ticketsList.length - 1]}
    });
  }

  changeReadStatus() {
    this.subscription.add(this.supportService.changeReadStatus(this.data.ticketNumber, {isRead: true})
    .subscribe ((response: any) => {}));
  }

}
