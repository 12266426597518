import { convertPagination } from 'src/app/_shared/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { IOldPagination } from '../../interfaces/old-pagination.model';

@Injectable({
  providedIn: 'root'
})
export class HttpMessagesService {

  constructor(private httpClient: HttpClient, private authUserService: AuthUserService) {}

  private get userUid() {
    return this.authUserService.getUserUid();
  }

  getUnreadCount() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/messages/count-unread`);
  }

  getMessagesByCategory(category: string, pagination: IOldPagination) {
    const pag = convertPagination(pagination)
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/my-message-list`,
    {params: {category, rowsFrom:pag.rowsFrom, rowsTo:pag.rowsTo}});
  }

  getMessage(messageUid: string) {
    console.log('getting message', messageUid);
    return this.httpClient.get(`${environment.apiUrl}/users/message/${messageUid}`);
  }

  changeReadStatus(messageUid: string, isRead: any) {
    return this.httpClient.put(`${environment.apiUrl}/users/message-read/${messageUid}`, isRead);
  }

  changeArchiveStatus(messageUid: string, isArchive) {
    return this.httpClient.put(`${environment.apiUrl}/users/${this.userUid}/message-archive/${messageUid}`, isArchive);
  }

  getFile(fileUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/general/blobs/${fileUid}`);
  }

  // New message

  getPublishersList() {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/messages/companies`);
  }

  getReceiptsList(companyUid: string) {
    return this.httpClient.get(`${environment.apiUrl}/users/${this.userUid}/messages/receipts/${companyUid}`);
  }

  sendMessage(receiptUid: string, message: any) {
    return this.httpClient.post(`${environment.apiUrl}/users/${this.userUid}/messages/${receiptUid}`, message);
  }

}
