<section>
  <header>
    <h3>Notifications
      <span *ngIf="totalUnread > 0" class="ag-counter">{{totalUnread}}</span>
      <app-tooltip [key]="TooltipTypeReader.NOTIFICATIONS"></app-tooltip>
    </h3>
    <div class="right flex align-center">
        <button [disabled]="currentIndex === 0" (click)="prev()" class="btn-square"><img src="../../../../assets/images/New design/left-arrow.svg" alt="left arrow"></button>
        <button [disabled]="( currentIndex === pagination.totalItems-1 ) || pagination.totalItems == 0" (click)="next()" class="btn-square" style="margin-left: 12px;"><img src="../../../../assets/images/New design/right-arrow.svg" alt="right arrow"></button>
    </div>
  </header>
  <app-content-wrapp class="content-wrapp">
    <div class="ag-flex-row ag-flex-align-center" *ngIf="notifications.length>0">
      <img class="logo" [src]="notification.imageLink" alt="publisher logo">
      <div class="right ml-3 ag-w-100">
        <div class="ag-flex-row-between">
          <p class="ag-font-16 ag-strong">{{notification.companyName}}</p>
          <p style="line-height: 21px;">{{notification.date}}</p>
        </div>
        <p class="ag-light mt-3">{{notification.notification}}</p>
      </div>
    </div>
    
    <p *ngIf="notifications.length===0" class="empty"> No notifications yet.</p>
  </app-content-wrapp>
</section>
