import { ImageConfiguratorComponent } from './../../../../_dialogs/image-configurator/image-configurator.component';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdEventService } from './../../_services/ad-event.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-carousel-ad',
  templateUrl: './carousel-ad.component.html',
  styleUrls: ['./../image-ad/image-ad.component.scss']
})
export class CarouselAdComponent implements OnInit, OnDestroy {

  public subscription: Subscription = new Subscription();
  public form: FormGroup;
  @Input () imageSize: any;

  constructor(public adEventService: AdEventService,
              private fb: FormBuilder,
              public dialog: MatDialog) {}

  ngOnInit(): void {
    this.createForm();
    this.addNewSlide();
  }

  createForm() {
    this.form = new FormGroup({
      slides: this.fb.array([]),
      logo: new FormControl (null),
      logoBlob: new FormControl (null),
    });
    this.subscription.add(this.form.valueChanges
      .subscribe(value => {
        this.adEventService.updateChildForm(value);
    }));
    // Form Validation update
    this.subscription.add(this.form.statusChanges
      .subscribe(status => {
        this.adEventService.updateChildFormValidation(status === 'VALID' ? true : false);
    }));
  }

  get slides() {
    return this.form.get('slides') as FormArray;
  }

  addNewSlide() {
    this.slides.push(new FormGroup({
      layout: new FormControl(null),
      image: new FormControl (null),
      imageBlob: new FormControl (null),
      heading: new FormControl (null, [Validators.maxLength(100)]),
      text: new FormControl (null, [ Validators.maxLength(300)]),
      price: new FormControl (null, [Validators.maxLength(20)]),
    }));
  }

  deleteSlide(i) {
    this.slides.removeAt(i);
  }

  openImageConfigurator(i) {
    const imageDialog = this.dialog.open(ImageConfiguratorComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: this.imageSize.width > 550 ? this.imageSize.width + 52 + 'px' : '600px',
      data: this.imageSize
    });
    this.subscription.add(imageDialog.afterClosed()
    .subscribe(result => {
      if (result) {
        this.convertImage(result, false, i);
        this.slides.at(i).patchValue({imageBlob: result});
      }
    }));
  }

  openLogoConfigurator() {
    const logoDialog = this.dialog.open(ImageConfiguratorComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '600px',
      data: {height: 200, width: 330}
    });
    this.subscription.add(logoDialog.afterClosed()
    .subscribe(result => {
      if (result) {
        this.convertImage(result, true);
        this.form.get('logoBlob').patchValue(result);
      }
    }));
  }

  private convertImage(image, isLogo: boolean, i?: number) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      const result = reader.result;
      isLogo ?
        this.form.get('logo').patchValue(reader.result) :
        this.slides.at(i).patchValue({image: reader.result});
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
