import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() label: string;
  @Input() value = 0;
  @Input() totalValue = 1000;
  @Input() i = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
