<div class="content">
    <button class="btn-icon close" (click)="this.dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h1  mat-dialog-title>Reply message 
        <img src="../../../../assets/images/Messages/reply-gray.svg" alt="">
    </h1>
    <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content>
            <aside class="grid-wrapper mb-2">
                <div class="box start-1 end-5">
                    <p>To</p>
                    <mat-form-field floatLabel="never" appearance="outline">
                        <input matInput
                                [disabled]='true'
                                placeholder=""
                                formControlName="toName"
                                name="toName">
                      </mat-form-field>
                </div>
                <div class="box start-5 end-9">
                    <p>Receipt</p>
                    <mat-form-field floatLabel="never" appearance="outline">
                        <input matInput
                                [disabled]='true'
                                placeholder="Type receipt number"
                                formControlName="receipt"
                                name="receipt">
                      </mat-form-field>
                </div>
            </aside>
            <p>Subject</p>
            <mat-form-field disabled class="title" floatLabel="never" appearance="outline">
                <textarea matInput
                    cdkTextareaAutosize
                    readonly
                    placeholder="Please add some title"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"
                    formControlName ="title"
                    name="title">
                </textarea>
            </mat-form-field>
            <p>Message</p>
            <mat-form-field class="message-area" floatLabel="never" appearance="outline">
                <textarea matInput
                    cdkTextareaAutosize
                    placeholder="Please enter your Message"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="10"
                    cdkAutosizeMaxRows="13"
                    formControlName ="message"
                    name="message">
                </textarea>
            </mat-form-field>
        </div>
        <div mat-dialog-actions class="bottom full-flex" >
            <div class="attachments">
                <div class="files-list flex mr-3">
                    <div [matMenuTriggerFor]="menu"
                         #menuTrigger="matMenuTrigger"
                         [matMenuTriggerData]="{pickedFile:i}"
                         class="file flex ml-1 mb-1"
                         *ngFor="let file of files; index as i">
                        <mat-icon>description</mat-icon>
                        <span class="file-name">{{file.name}}</span> <span class="file-size">({{file.size*0.001 | number : '1.0-0'}} kb)</span>
                    </div>
                </div> 
                <div class="add-btn"  (click)='clickInput()'>
                    <input  hidden
                            type="file"
                            #FileInput 
                            (change)="fileSelected($event.target.files)"
                            name="files"
                            multiple>  
                    <button class="btn btn-gray">
                        <img src="../../../../assets/images/Messages/attach.svg" alt="">  Add Attachment
                    </button>
                </div>
            </div>
            <button (click)="send()" [disabled]='form.invalid' class="btn btn-full shadow ml-6">
                <img src="../../../../assets/images/Messages/send.svg" alt="">Send
            </button>
        </div>
    </form>
</div>

<mat-menu #menu="matMenu" yPosition="above">
    <ng-template matMenuContent let-indexOfFile="pickedFile">
        <div class="popover-container flex">
            <button class="btn-icon" (click)="deleteFile(indexOfFile)">
                <mat-icon>clear</mat-icon>
                <span>delete</span>
            </button>
        </div>
    </ng-template>
</mat-menu>
