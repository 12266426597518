<div #chatArea *ngIf="ticket" class="content">
    <div *ngFor="let message of ticket.ticketsList; let i = index"  class="wrapper">
        <div class="header" [ngClass]="{'no-subject': i!==0}">
            <div class="head full-flex">
                <p *ngIf="i===0" (click)="scrollToBottom()" class="topic flex"><span class="field-name">Subject: </span>{{message.title}}</p>
                <p class="date">{{message.date}}</p>
            </div>
            <p class="item flex mb-2">
                <span class="field-name">Ticket#:</span> <span class="bold">{{ticket.ticketNumber}}</span>
            </p>
            <p class="from flex">
                <span class="field-name"> From:</span> {{message.name}}
            </p>
        </div>
        <div [ngClass]="{'closed': isClosed}" class="body">
            <textarea class="message-text" [innerHTML]="message.message" readonly></textarea>
            <div *ngIf="message.attachmentsList.length>0" class="attachments at-desktop">
                Attachments
                <div class="files flex mt-3">
                    <div (click)="downloadDocument(file.uid)" class="file-wrapper flex mr-5" *ngFor="let file of message.attachmentsList">
                        <div class="file dark">
                            <img  *ngIf='file.type=="image/jpeg" || file.type =="image/png"' [src]="file.imgLink" alt="">
                            <img *ngIf='file.type=="application/pdf"' src="../../../../assets/images/Messages/pdf.png" alt="">
                            <img *ngIf='file.type!="image/jpeg"
                                    && file.type !="image/png"
                                    && file.type !="application/pdf"' src="../../../../assets/images/Messages/document.png" alt="">
                            <span>{{file.fileName}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <app-attachments-mobile class="at-mobile" *ngIf="message.attachmentsList.length>0" [list]='message.attachmentsList'></app-attachments-mobile>
        </div>
    </div>
    <div *ngIf="!isClosed" class="btn-wrapp">
        <button (click)="reply()" class="btn btn-full shadow">
            <img src="../../../../assets/images/Messages/reply.png" alt=""> Reply
        </button>
        <button (click)="closeEvent.emit()" class="btn btn-gray">
            <img src="../../../../assets/images/Messages/close.svg" alt=""> Close Ticket</button>
    </div>
</div>
<div *ngIf="!ticket" class="empty">
    <img src="../../../../assets/images/Messages/click-message2.png" alt="">
</div>