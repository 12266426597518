<div
  style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  "
  class="break-and-center-on-medium ag-mt-2"
>
  <div class="btn-container">
    <button
      class="btn--selector"
      *ngFor="let btn of btnSelectorList"
      [value]="btn.value"
      [ngClass]="{ 'btn--selector--active': btn.value === selectorValue }"
      (click)="onSelectorBtnClick($event)"
    >
      {{ btn.name }}
    </button>
  </div>
  <app-content-wrapp
    style="
      padding: 0;
      height: 550px;
      box-shadow: 0px 0px 6px #0000000f;
      display: flex;
      align-items: center;
      width: 100%;
    "
  >
    <div class="chart-container">
      <div class="header-container ag-mt-2"></div>
      <app-chart-linear-horizontal
        [data]="data"
        [legend]="false"
        [showYAxisLabel]="false"
        [showGridLines]="false"
      ></app-chart-linear-horizontal>
    </div>
  </app-content-wrapp>
</div>
