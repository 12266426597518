<div *ngIf="isDesktop" class="content">
    <div *ngIf="useHeaders" class="filter-wrapp full-flex">
        <button (click)="seeAllReviews()" class="btn btn-full shadow see-all">See All</button>
        <div class="gray-select flex">
            <p>Sort by: </p>
            <mat-form-field floatLabel="never" appearance="outline">
              <mat-select [panelClass]="'review-select'" [(ngModel)]="actualSortType" (selectionChange)="sortUpdate($event)">
                <mat-option *ngFor="let type of sortsType" [value]="type.value"> {{ type.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <h3 *ngIf="useHeaders">Reviews and Ratings</h3>
    <div class="reviews-wrapp">
        <p *ngIf="!reviewList.length" class="empty">
            There are no any reviews yet.
        </p>
        <div *ngFor="let review of reviewList" class="review">
            <span class="location">{{review.location}}</span>
            <div class="header full-flex">
                <span class="name">{{review.nickName}}</span>
                <span class="date">{{review.reviewDate}}</span>
            </div>
            <p>{{review.content}}</p>
            <div class="footer full-flex">
                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="review.rating"></star-rating>
                <img *ngIf="useAvatars" [src]='review.companyAvatarLink' alt="">
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isDesktop" class="content-mobile">
    <div class="filter-wrapp">
        <app-page-header class="page-header">{{pageName}}</app-page-header>
        <div class="gray-select flex mb-4">
            <button (click)="seeAllReviews()" class="btn btn-full shadow see-all">See All</button>
            <mat-form-field floatLabel="never" appearance="outline">
              <mat-select [(ngModel)]="actualSortType" (selectionChange)="sortUpdate($event)">
                <mat-option *ngFor="let type of sortsType" [value]="type.value"> {{ type.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="reviewList.length>0"  class="carousel-wrapp flex">
        <div class="carousel">
            <owl-carousel-o id="carousel" [options]="customOptions" #owlCar>
                <ng-container *ngFor="let review of reviewList">
                    <ng-template [width]="230" carouselSlide>
                        <div class="review flex-column">
                            <div class="wrapper">
                                <span class="location">{{review.location}}</span>
                                <div class="header full-flex">
                                    <span class="name">{{review.nickName}}</span>
                                    <span class="date">{{review.reviewDate}}</span>
                                </div>
                                <p>{{review.content}}</p>
                            </div>
                            <div class="footer full-flex">
                                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="review.rating"></star-rating>
                                <img *ngIf="useAvatars" [src]='review.companyAvatarLink' alt="">
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
    <p *ngIf="!reviewList.length" class="empty">
        There are no any reviews yet.
    </p>
</div>
