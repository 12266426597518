<main>
    <section id="image-placeholder" *ngIf="!croppieImage">
      <div class="circle circle--outer">
          <mat-icon *ngIf="!image&&!imageOnly">person</mat-icon>
          <div  *ngIf="image" class="image-circle">
            <img [src]="image" />
          </div>
      </div>
    </section>
    <section *ngIf="croppieImage">
      <ngx-croppie
        #ngxCroppie
        [outputFormatOptions]="outputoption"
        [croppieOptions]="croppieOptions"
        [imageUrl]="croppieImage"
        (result)="newImageResultFromCroppie($event)">
      </ngx-croppie>
    </section>
  </main>
    