import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  @Input() centerPoint;
  @Input() list;
  @Input() toOpenElement: boolean;
  @Input() pageName: string;
  zoom = 4;
  mapStyle =
  [
    {
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  clusterStyles: ClusterStyle[] =  [
    {
        textColor: '#FFFFFF',
        url: '../../../../assets/images/Home/m1.png',
        height: 102,
        width: 102,
        textSize: 22
    },
  ];


  constructor(public router: Router) {}

  ngOnInit(): void {
  }

  openCompany(companyUid) {
    this.router.navigate(['reader/company-receipts', companyUid]);
  }

}
