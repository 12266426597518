import { Component, forwardRef, OnChanges, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {NgxCroppieComponent} from '../ngx-croppie/ngx-croppie.component';
import { CroppieOptions } from 'croppie';
import { from } from 'rxjs';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements ControlValueAccessor, OnChanges {

  @Output()
  public saveImage = new EventEmitter();
  @Input()
  public imgCropToHeight = '45';
  @Input()
  public imgCropToWidth = '45';
  @Input()
  public imageData: any;
  @Input()
  upload: (event: any) => any;
  @Input()
  imageOnly: boolean;
  @Input()
  firstName = '';
  @Input()
  surname = '';

  public image: any;
  public toSave: boolean;

  /* Our cropped image and the value of our image controller */
  public croppieImage;

  /* Options for the cropped image type and size */
  public outputoption = { type: 'blob', size: 'viewport' };

  /* Element to paint our form control */
  @ViewChild('ngxCroppie', { static: false })
  ngxCroppie: NgxCroppieComponent;

  constructor() {}

  ngOnChanges(changes: any) {
    this.loadImage();
    if (this.croppieImage) {
      return;
    }

    if (!changes.imageUrl) {
      return;
    }
    if (!changes.imageUrl.previousValue && changes.imageUrl.currentValue) {
      this.croppieImage = changes.imageUrl.currentValue;
      this.propagateChange(this.croppieImage);
    }
  }

  private loadImage() {
    if (this.imageData) {
      this.image = this.imageData;
    }
  }

  /* Options for croppie, you can learn more about this here -> https://foliotek.github.io/Croppie/ */
  public get croppieOptions(): CroppieOptions {
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: parseInt(this.imgCropToWidth, 10),
      height: parseInt(this.imgCropToHeight, 10),
      type: 'circle'
    };
    opts.boundary = {
      width: 45,
      height: 45
    };
    opts.enforceBoundary = true;
    return opts;
  }

  newImageResultFromCroppie(img: string) {
    this.croppieImage = img;
    this.propagateChange(this.croppieImage);
  }

  /* Takes the value  */
  writeValue(value: any) {
    if (value !== undefined) {
      this.croppieImage = value;
      this.propagateChange(this.croppieImage);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}


