import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {
  @Input() title = 'Empty List!';
  @Input() description = '';
  @Input() type = 'list';
  @Input() color = 'blue'

  constructor() { }

  ngOnInit(): void {
  }

}
