import { formatDate } from '@angular/common';
import { environment } from "src/environments/environment";

export class Pagination {
    constructor(itemsPerPage?: number) {
        if (itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
        }
    }

    currentPage = 1
    itemsPerPage = 10
    totalItems = 0

    id = Math.trunc(Math.random()*1000);

    request(): PaginationRequest {
        return {
            currentPage: this.currentPage.toString(),
            itemsPerPage: this.itemsPerPage.toString()
        }
    }
}

export interface PaginationRequest {
    currentPage: string;
    itemsPerPage: string;
  }

export class DateFilter {
    dateFrom = new Date(environment.sFormsStaticDateFrom);
    dateTo = new Date();

    request(): DateFilterRequest {
        return {
            dateFrom: formatDate(this.dateFrom, 'yyyy/MM/dd', 'en'),
            dateTo: formatDate(this.dateTo, 'yyyy/MM/dd', 'en')
        }
    }
}

export interface DateFilterRequest {
    dateFrom: string;
    dateTo: string;
}


