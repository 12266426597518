import { DictionariesService } from './../../_shared/dictionaries/dictionaries.service';
import { fadeMix } from './../../@animations/animations';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { PaymentService } from './../../_shared/services/rest-services/payments.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-publisher-top-up',
  templateUrl: './publisher-top-up.component.html',
  styleUrls: ['./publisher-top-up.component.scss'],
  animations: [fadeMix]
})
export class PublisherTopUpComponent implements OnInit {
  public minRecharge = 10;
  public maxRecharge = 1000000;
  public currency = '';
  public tokenRate;
  public isLoad: boolean;
  public form: FormGroup;
  public methods = [
    {
      value: 'paypal', viewValue: 'PayPal'
    }
  ];

  constructor(@Inject (MAT_DIALOG_DATA) public data: any,
              private paymentService: PaymentService,
              private authUserService: AuthUserService,
              private toastService: ToastService,
              private dictionariesService: DictionariesService,
              public dialogRef: MatDialogRef<PublisherTopUpComponent>) {
                this.currency =  this.authUserService.getUserCurrency();
  }

  ngOnInit(): void {
    this.createForm();
    this.getActualRate();
  }

  createForm() {
    this.form = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.max(this.maxRecharge), Validators.min(this.minRecharge)]),
      method: new FormControl({value: 'paypal', disabled: true}, [Validators.required]),
    });
  }

  getActualRate() {
    this.dictionariesService.getTokenRate()
      .subscribe((res: any) => {
        res.result.wordsFromDictionary.find((rate: any) => {
          rate.groupNameFullName === this.currency ? this.tokenRate = rate.value : null;
          this.data ? this.form.get('amount').patchValue(this.data.amount / this.tokenRate) : null;
        });
      });
  }

  toPay() {
    this.isLoad = true;
    const payData = {
      price: this.form.get('amount').value,
      currency: this.currency
    };
    this.paymentService.toPay(this.authUserService.getUserUid(), payData)
      .subscribe ((response: any) => {
        window.open(response.rel, '_blank');
        this.dialogRef.close();
        }, (err) => {
          this.toastService.warning(err.error.errors[0].message);
    });
  }

}
