<header>
  <h3>Product Recall <app-tooltip [key]="TooltipTypeReader.PRODUCT_RECALL"></app-tooltip></h3>
</header>
<div class="ag-box-v ag-box-rounding margin-button">
  <div  class="ag-bg-light"></div>
  <div class="ag-bg-light ag-t-dark ag-p-none ">
    <atom-widget-list-scroll (onNewDataNeed)="onListScroll()" [isLoading]="bLoading" [isRollLoading]="isRollLoading" [bShowExpandButton]="aRecallList.length > 3" [bShowList]="aRecallList.length > 0">
      <app-one-recall agList *ngFor="let oRecall of aRecallList" [oRecall]="oRecall"></app-one-recall>
    </atom-widget-list-scroll>
  </div>
</div>
