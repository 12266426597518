<mat-card
  fxLayout="row"
  fxLayoutAlign="space-around center"
  style="height: 100%; width: 100%; box-shadow: none"
>
  <ngx-charts-line-chart
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="data"
    [showGridLines]="showGridLines"
    [roundDomains]="true"
  >
  </ngx-charts-line-chart>
</mat-card>
