import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProfilePublisherComponent } from "src/app/pages-publisher/company-profile/profile-publisher.component";
import { CroppieOptions } from "croppie";
import { Observable, Subscription } from "rxjs";
import { HttpProfileService } from "src/app/_shared/services/http-services/http-profile.service";
import { MenuService } from "src/app/_shared/services/menu.service";
import { ToastService } from "src/app/_shared/services/toast.service";
import { HttpClient } from "@angular/common/http";
import {UsersService} from '../../_shared/services/rest-services/users.service';
const MAX_FILE_SIZE = 1048576; // 1 MB
@Component({
  selector: "app-profile-picture-editor",
  templateUrl: "./profile-picture-editor.component.html",
  styleUrls: ["./profile-picture-editor.component.scss"],
})
export class ProfilePictureEditorComponent implements OnInit {
  @ViewChild("previewImage") previewImage: ElementRef;
  public currImgSrc: string | Blob;
  public isPublisher: boolean = false;
  public isEditMode: boolean = false;
  private isURL: boolean = false;
  public croppieImage: string;
  public inputImg: string;
  public modifiedImage: string;
  private subscription: Subscription = new Subscription();
  public outputoption = { type: "blob", size: "viewport" };
  constructor(
    public dialogRef: MatDialogRef<ProfilePublisherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpProfileService: HttpProfileService,
    private userService: UsersService,
    private httpClient: HttpClient,
    private menuService: MenuService,
    private toastService: ToastService
  ) {
    this.isPublisher = data.isPublisher || false;
  }
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }
  newImageResultFromCroppie(event) {
    this.isURL = false;
    this.modifiedImage = event;
  }
  onConfirmEditedImg(onlyConfirm?: boolean) {
    if (!this.currImgSrc) {
      this.toastService.success("No changes made.");
      this.dialogRef.close();
      return;
    }
    if (onlyConfirm) this.toggleEditMode();
    this.saveAvatar();
    this.dialogRef.close({ reason: "saved" });
  }
  public get croppieOptions(): CroppieOptions {
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: 220,
      height: 220,
      type: "circle",
    };
    opts.boundary = {
      width: 220,
      height: 220,
    };
    opts.minZoom = 0.03;
    opts.maxZoom = 3;
    opts.enforceBoundary = true;
    opts.mouseWheelZoom = "ctrl";
    return opts;
  }
  imageUploadEvent(evt: any) {
    if (!evt.target) {
      return;
    }
    if (!evt.target.files) {
      return;
    }

    if (evt.target.files.length !== 1) {
      return;
    }

    const file = evt.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/gif" &&
      file.type !== "image/jpg"
    ) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.toastService.warning("File is too big.");
    } else {
      const fr = new FileReader();
      console.log("starting load");
      fr.onloadend = (loadEvent) => {
        this.currImgSrc = fr.result.toString();
        this.inputImg = this.currImgSrc;
      };
      fr.readAsDataURL(file);
      this.isURL = false;
    }
  }
  loadInputUrl(event) {
    // const value: string = event.target.value;
    // if (!!value && value !== this.inputImg) {
    //   if (value.match(/\.(jpeg|jpg|gif|png|svg)$/) != null) {
    //     // this.inputImg = value;
    //     // this.currImgSrc = value;

    //     // this.modifiedImage = value;
    //     // let downloadedImg = new Image();
    //     // downloadedImg.crossOrigin = "Anonymous";
    //     // downloadedImg;
    //     // downloadedImg.addEventListener("load", this.imageReceived, false);
    //     // downloadedImg.src = value;
    //     this.getImage(value).subscribe((res) => {
    //       console.log("result", res);
    //     });
    //   }
    // }
  }
  imageReceived(event) {
    console.log("IMAGE RECEIVED", event, event.target, event.target.src);
    this.currImgSrc = event;
    this.modifiedImage = event;
    this.inputImg = event;
  }
  getImage(imageUrl: string): Observable<any> {
    return this.httpClient.get(imageUrl, {
      responseType: "blob",
      observe: 'response',
      withCredentials: true,
    });
  }
  // createImageFromBlob(image: Blob) {
  //   let reader = new FileReader();
  //   reader.addEventListener("load", () => {
  //   this.imageToShow = reader.result;
  //   }, false);

  //   if (image) {
  //   reader.readAsDataURL(image);
  //   }
  //   }
  saveAvatar() {
    // Avatar saving
    const fd = new FormData();
    fd.append("avatarImage", this.modifiedImage);
    this.uploadAvatar(fd);
  }

  uploadAvatar(fd) {
    console.log('publisher', this.isPublisher);
    this.subscription.add(
      this.httpProfileService.saveUserAvatar(fd).subscribe(
        (response: any) => {
          this.menuService.toReloadHeader();
          this.toastService.success("Profile picture changed.");
          if (this.isPublisher) {
            this.subscription.add(
              this.userService.setCompanyAvatar(this.data.userUid, fd).subscribe(
                () => {
                  this.menuService.toReloadHeader();
                },
                () => {
                }
              )
            );
          }
        },
        (err) => {
          this.toastService.warning("Invalid file. Note: Max size after crop is 1MB");
        }
      )
    );
  }
  ngOnInit(): void {}
}
