import { Component, OnInit } from "@angular/core";
import { WsService } from "../../_services/ws.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AuthUserService } from "../../@auth/auth-user.service";
import { environment } from "../../../environments/environment";
import { CurrencyService } from "src/app/_services/currency.service";

@Component({
  selector: "app-wallet-iframe",
  templateUrl: "./wallet-iframe.component.html",
  styleUrls: ["./wallet-iframe.component.scss"],
})
export class WalletIframeComponent implements OnInit {
  public urlForIframe: SafeUrl = null;
  constructor(
    private wsService: WsService,
    protected sanitizer: DomSanitizer,
    private authUserService: AuthUserService,
    private currencyService: CurrencyService
  ) {
    this.urlForIframe = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.walletUrl}/load/${
        this.wsService.wsId
      }?token=${this.authUserService.getToken()}`
    );
    this.currencyService.init();
  }

  ngOnInit(): void {}
}
