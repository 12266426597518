import { AuthUserService } from 'src/app/@auth/auth-user.service';
import { FiltersMobileComponent } from '.././../../_dialogs/filters-mobile/filters-mobile.component';
import { Subscription, Subject } from 'rxjs';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';

const DEBOUNCE_TIME = 800;

@Component({
  selector: 'app-filter-table-mobile',
  templateUrl: './filter-table-mobile.component.html',
  styleUrls: ['./filter-table-mobile.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FilterTableMobileComponent implements OnInit, OnChanges {
  public subscription = new Subscription();
  @Input() data: any = [];
  @Input() columns: any = {};
  @Input() onRowClick: (event: any) => any;
  @Input() dateFilterData;
  @Input() amountFilterData;
  @Input() ratingFilterData;
  @Input() amountSelect;
  @Input() paginationData;
  @Input() filtersOff: boolean;
  @Input() selectedRows: (event: any) => any;
  @Input() isLoading: boolean;
  @Input() autoSelect: boolean;
  @Input() maxColumns = 3;
  @Input() disableExpand: boolean;
  @Output() dateFilter = new EventEmitter();
  @Output() amountFilter = new EventEmitter();
  @Output() ratingFilter = new EventEmitter();
  @Output() elementClicked = new EventEmitter();
  @Output() paginationUpdate = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() usePagination: boolean;
  @Input() totalRows = 0;
  @Input() paginationSizeOptions;
  public dataSource: any;
  public displayedColumns: string[] = [];
  public expandedColumn = [];
  public searchChanged: Subject<any> = new Subject<any>();
  selection = new SelectionModel(true, []);
  public ratingFilterCount = 0;
  public expandedElement;
  public currency = '';

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');


  constructor(public dialog: MatDialog,
              private authUserService: AuthUserService) {
    this.currency = this.authUserService.getUserCurrency();
    this.searchChanged
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((model: any) => {
        this.updatePagination(model, 'filter');
    });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    if (this.usePagination) {
      this.paginationData.pageSize = this.paginationSizeOptions[0];
    }
  }

  updatePagination(data, type) {
    if (type === 'sort') {
      switch (data.active) {
        case 'companyAvatarLink':
          data.active = 'company';
          break;
        case 'select':
          data.active = 'status';
          break;
      }
    }
    this.paginationUpdate.emit({data, type});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleRow(row) {
    this.selection.toggle(row);
    this.selectedRows(this.selection.selected);
  }
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  elementClick(columnName, row) {
    this.elementClicked.emit({columnName, row});
  }

  textFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.usePagination) {
      this.searchChanged.next(filterValue );
    } else {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  dateUpdate(event) {
    this.dateFilter.emit(this.dateFilterData);
  }

  amountFilterUpdate(event) {
    if (this.amountFilterData.useFilter || event.checked === false) {
      this.amountFilter.emit(this.amountFilterData);
    }
  }

  rateUpdate(event) {
    event.rating ? this.ratingFilterData.rating = event.rating : null;
    if (this.ratingFilterCount > 0 && (this.ratingFilterData.useFilter || event.checked === false)) {
      this.ratingFilter.emit(this.ratingFilterData);
    }
    this.ratingFilterCount++;
  }

  filterPredicate() {
    this.dataSource.filterPredicate = (order: any, filter: string) => {
      const transformedFilter = filter.trim().toLowerCase();
      const listAsFlatString = (obj): string => {
        let returnVal = '';
        Object.values(obj).forEach((val) => {
          val = val.toString();
          if (typeof val !== 'object') {
            if (val.toString().length < 15) {
              returnVal = returnVal + ' ' + val;
            }
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase().replace(/ +(?= )/g, '');
      };
      return listAsFlatString(order).includes(transformedFilter);
    };
  }

  toAutoSelect() {
    if (this.autoSelect) {
      this.selection = new SelectionModel(true, []);
      for (const row of this.data) {
        row.isSelected ? this.toggleRow(row) : null;
      }
    }
  }

  openColumnFilter() {
    const filtersDialog = this.dialog.open(FiltersMobileComponent, {
      disableClose: true,
      backdropClass: 'dialog-back',
      width: '350px',
      data: {columns: this.columns, maxColumns: this.maxColumns},
    });
    this.subscription.add(filtersDialog.afterClosed()
      .subscribe(result => {
        this.tableInitialization();
    }));
  }


  ngOnChanges() {
    this.tableInitialization();
  }

  tableInitialization() {
    const columns = [];
    const expandedColumn = [];
    for (const column of this.columns) {
      column.toUseMobile ? columns.push(column.fieldName) : expandedColumn.push(column);
    }
    this.dataSource = new MatTableDataSource(this.data);
    this.filterPredicate();
    this.toAutoSelect();
    this.displayedColumns = columns;
    this.expandedColumn = expandedColumn;
    !this.usePagination ? this.dataSource.sort = this.sort : null;
  }

}
