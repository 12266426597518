<div class="circle-wrapp" #circleWrapp>
    <ngx-charts-pie-chart
        *ngIf="data.length>0"
        style="display: block; margin: auto;"
        [scheme]="customScheme"
        [legend]="false"
        [doughnut]="true"
        [labels]="false"
        [results]="data"
        [gradient]="false"
        [view]="view">
        <ng-template let-model="model" #tooltipTemplate>
            <div class="tooltip flex-center">
                <div class="color-mark" [style.backgroundColor]='model.name|chartLegendPipe:data:customScheme.domain'></div>
                <span>{{model.name}}: </span>{{model.value}}
            </div>
        </ng-template>
    </ngx-charts-pie-chart>
    <div class="total-value" [ngStyle]="{'color': customScheme.domain && customScheme.domain[0] && customScheme.domain[0], 'font-size': fontForTotal }">
        {{totalValue}}
    </div>
</div>

<div class="legend" *ngIf="!hideLegend">
    <div *ngFor="let item of data; let i = index" class="label full-flex">
        <div class="name flex">
            <div class="color-mark" [style.backgroundColor]='customScheme.domain[i]'></div>
            <p>{{item.name}}</p>
        </div>
        <div class="value">
            <p>{{item.value}}</p>
        </div>
    </div>
</div>