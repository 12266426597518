<section>
  <app-page-header class="header" *ngIf="pageName">{{pageName}}</app-page-header>
  <agm-map
    *ngIf="centerPoint"
    class="map"
    [zoom]="zoom"
    [styles]="mapStyle"
    [latitude]="centerPoint.lat"
    [longitude]="centerPoint.lng"
    [disableDefaultUI]="false"
    [zoomControl]="true">
    [fitBounds]="false">
    <agm-marker-cluster [styles]="clusterStyles" imagePath="../../../../assets/images/Home/m">
      <agm-marker
        *ngFor="let shop of list; let i = index"
        [latitude]="shop.latitude"
        [longitude]="shop.longitude"
        [label]="{color: 'white'}"
        [markerDraggable]="false"
        [iconUrl]="{url: shop.companyAvatarLink, scaledSize: {width: 90, height: 60}}"
        [agmFitBounds]="true">
        <agm-info-window>
          <strong (click)="toOpenElement ? openCompany(shop.companyUid) : null">{{shop.shopName}}</strong>
        </agm-info-window>
      </agm-marker>
    </agm-marker-cluster>
  </agm-map>
  <div class="flex-center loader-wrapp" *ngIf="!centerPoint">
    <mat-spinner [diameter]="130" [strokeWidth]='7'></mat-spinner>
  </div>
</section>