<div class="content">
  <div class="header">
    <p class="label">{{label}}</p>
    <app-switcher
      *ngIf="!disabled && !noSelectAll"
      [firstValue]="false"
      [secondValue]="true"
      secondValueLabel="Select all"
      (valueChange)="toggleSelectAll($event)"
    ></app-switcher>
  </div>
  <ng-select [items]="values"
              dropdownPosition="auto"
              [multiple]="!isOnlyOneValue"
              [bindLabel]="bindLabel"
              [closeOnSelect]="false"
              class="select"
              [placeholder]="placeholder"
              [virtualScroll]="true"
              (ngModelChange)="valueChanged($event)"
              [disabled]="disabled"
              [(ngModel)]="val">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected" [ngModelOptions]="{ standalone : true }"> <span [ngOptionHighlight]="search">{{item[bindLabel]}} <span *ngIf="secondBindLabel">, {{item[secondBindLabel]}}</span></span>
    </ng-template>
  </ng-select>
</div>