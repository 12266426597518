<div class="m-rec-one">
  <div class="ag-flex-row-between">
    <a target="_blank" [href]="oRecall.website" class="m-rec ag-action">Recall# {{ oRecall.recallNo}}</a>
    <div class="ag-t-r">{{oRecall.startDate}}</div>
  </div>
  <div class="ag-flex-row-stretch">
    <div style="flex-grow: 2">
      <div class="ag-flex-row-stretch m-separation-h">
        <div>
          <span class="m-value">{{oRecall.brand}}</span>
          <br>
          <span class="ag-t-light">Brand</span>
        </div>
        <div class="ag-flex-row-center">
          <div>
            <span class="m-value">{{oRecall.model}}</span>
            <br>
            <span class="ag-t-light">Model</span>
          </div>
        </div>
      </div>
      <div>
        <span>{{oRecall.recallReason}}</span>
      </div>
    </div>
    <div style="flex-grow: 1"  class="ag-flex-row-between">
      <div></div>
      <div>
        <div class="m-separation-h">
          <span class="m-value">{{oRecall.productNo}}</span>
          <br>
          <span class="ag-t-light">Product No</span>
        </div>
        <div>
          <span class="m-value">{{oRecall.sku ? oRecall.sku : '-'}}</span>
          <br>
          <span class="ag-t-light">SKU</span>
        </div>
        <div *ngIf="oRecall.imageLink">
          <img class="m-avatar" [src]="oRecall.imageLink" alt="Company logo">
        </div>
      </div>
    </div>
  </div>
</div>

