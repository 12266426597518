<main>
  <section id="image-placeholder" *ngIf="!croppieImage" (click)="imageUpload.click()">
    <div class="box" [style.min-height.px]="imgCropToHeight">
        <img  [style.width.px]="(imgCropToHeight)/3.2"
              class="add-photo"
              *ngIf="!image&&!imageOnly" src="../../../assets/images/add_photo.svg" alt="">
        <mat-icon *ngIf="!image&&imageOnly">person</mat-icon>
        <img *ngIf="image" [src]="image" />
    </div>
  </section>
  <section *ngIf="croppieImage">
    <ngx-croppie
      #ngxCroppie
      [defaultZoom]='0.1'
      [outputFormatOptions]="outputoption"
      [croppieOptions]="croppieOptions"
      [imageUrl]="croppieImage"
      (result)="newImageResultFromCroppie($event)">
    </ngx-croppie>
  </section>
  <input #imageUpload hidden type="file" id="fileupload" [disabled]="imageOnly" (change)="imageUploadEvent($event)" accept="image/gif, image/jpeg, image/png"/>
  <button *ngIf="upload && croppieImage" class="btn btn-warning mb-2" (click)="save()">Save image</button>
  <div *ngIf="useButtons" class="btn-wrapp">
    <p class="clickable" (click)="imageUpload.click()" *ngIf="!croppieImage && !image">Add {{imageName}}</p>
    <p class="clickable" [ngClass]="{'edit': croppieImage}" (click)="imageUpload.click()" *ngIf="!imageOnly && (croppieImage || image)">
      Edit {{imageName}}
    </p>
  </div>
</main>
<p *ngIf="showSizeInfo" class="size-message mt-2"> Please use the following size for best result: 120 x 220 px</p>
