<app-page>
    <app-page-header class="page-header"> Search results for: <span>{{searchData}}</span></app-page-header>
    <aside class="wrapper flex">
        <div class="left">
            <app-page-body class="page-body">
                <div *ngIf="(searchResult | searchPipe: isPublisher).length>0"  class="result-wrapp">
                    <div *ngFor="let row of (searchResult | searchPipe: isPublisher)" (click)="open(row)" class="row">
                        <div class="head mb-3 full-flex">
                            <h4>
                                <span class="type">{{row.contentType}}: </span> <span class="content" innerHTML="{{ row.content | highlight : searchData}}"></span>.
                                <span *ngIf="checkToShowContentType(row.contentType)" class="type"> {{row.description}}</span> 
                            </h4>
                            <div class="category">
                                <span>Category:</span> {{row.category}}
                            </div>
                        </div>
                        <div class="date">
                            <span>Added:</span>  {{row.date}}
                        </div>
                    </div>
                </div>
                <div *ngIf="(searchResult | searchPipe: isPublisher).length===0" class="empty">
                    <mat-icon>search</mat-icon>
                    <h1 >List is empty</h1>
                </div>
            </app-page-body>
        </div>
        <img class="advertisement" src="../../../assets/images/AdPlaceholder/389x709.png" alt="">
    </aside>
</app-page>