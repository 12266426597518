<div *ngIf="!filterOff" class="filters-wrapp mb-2">
    <div (click)="openColumnFilter()" class="mobile-filter">
        Select Fields
      </div>
</div>
<div [ngClass]="{'loading': isLoading}" class="table-wrapp">
    <table [ngClass]="{'select': !selectOff, 'isLockTable': isLockTable, 'extra-height': useExtraBtn}" [hidden]='isLoading||data.length===0' mat-table [dataSource]="dataSource" multiTemplateDataRows (matSortChange)="updatePagination($event, 'sort')" matSort class="table">
        <ng-container matColumnDef="{{column.fieldName}}" *ngFor="let column of columns">
            <th class="table-head" mat-sort-header mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td class='table-row' mat-cell *matCellDef="let element" (click)="onRowClick ? onRowClick(element) : ''">
                <span (click)="elementClick(column.fieldName, element)" class="element" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                    blueUnderline: ('blue-underline').includes(column.extraClass), 'simple': !element[column.fieldName]}" *ngIf="!column.valuePrepareFunction&&!column.extraValue" >
                        <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : ''">{{element[column.fieldName] ? element[column.fieldName] :"—"}} </span>
                </span>
                <span class="element" *ngIf="column.extraValue=='select'">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? toggleRow(element) : null"
                        [checked]="selection.isSelected(element)"
                        [disabled]='isLockTable'
                        [aria-label]="checkboxLabel(element)">
                    </mat-checkbox>
                </span>
                <span class="element btn-wrapp flex-center" *ngIf="column.extraValue=='extraBtn'">
                    <button (click)="btnClicked.emit(element)" class="btn btn-full shadow">{{column.fieldName}}</button>
                </span>
                <span class="element btn-wrapp flex-center" *ngIf="column.extraValue=='extraBtn2'">
                    <button (click)="btnClicked2.emit(element)" class="btn btn-red">{{column.fieldName}}</button>
                </span>
                <span (click)="elementClick(column.fieldName, element)" class="element blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                <span (click)="elementClick(column.fieldName, element)" class="element" *ngIf="column.valuePrepareFunction" (click)="column.action ? column.action($event, element) : null" [innerHTML]="element ? column.valuePrepareFunction(element) : ''"></span>
                <span (click)="elementClick(column.fieldName, element)" class="avatar element" *ngIf="column.extraValue=='avatar'" (click)="column.action ? column.action($event, element) : null">
                    <img *ngIf="element[column.fieldName]" [src]="element[column.fieldName]" alt=""> <span *ngIf="!element[column.fieldName]">-</span>
                </span>
                <span (click)="elementClick(column.fieldName, element)" class="element flex-center" *ngIf="column.extraValue=='offers'" (click)="column.action ? column.action($event, element) : null">
                    <!-- <span class="advertisement" *ngIf="!element[column.fieldName]">offers</span>  -->
                    <img class="advertisement" *ngIf="!element[column.fieldName]" src="../../../../assets/images/AdPlaceholder/140x38.png" alt="">
                </span>
                <span class="element" *ngIf="column.extraValue=='rating'||column.extraValue=='rating-editable'">
                    <star-rating #rating (click)="column.extraValue=='rating-editable'&&!isLockTable ? elementClick(column.fieldName, {element : element, rating:rating}) : null" [hoverEnabled]="true" [showHalfStars]="true"  [readOnly]="column.extraValue=='rating-editable'&&!isLockTable ? false : true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                </span>
                <span (click)="elementClick(column.fieldName, element)" class="element product-description" *ngIf="column.extraValue=='productDescription'" (click)="column.action ? column.action($event, element) : null">
                    <div class="product-image">
                        <img *ngIf="element[column.fieldName].productDescriptionURL" [src]="element[column.fieldName].productDescriptionURL" alt="">
                        <img *ngIf="!useBlueIcon && !element[column.fieldName].productDescriptionURL" src="../../../../assets/images/Table/product-placeholder.png" alt="">
                        <img *ngIf="useBlueIcon && !element[column.fieldName].productDescriptionURL" src="../../../assets/images/New design/empty-product.svg" alt="">
                    </div>
                    <span>{{element[column.fieldName].productName}}</span>
                </span>
            </td>
        </ng-container>

        <ng-container  matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="element-description">
                    <div [ngClass]="{'full-border': expandedColumn.length<=2}" class="row row-1 full-flex">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="i<3" class="item">
                                {{column.title}}:
                            <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                            <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : elementClick(column.fieldName, element)" *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass), 'simple': !element[column.fieldName]}">{{element[column.fieldName] ? element[column.fieldName] :"—"}}</span>
                            <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                            <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                            </span>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="expandedColumn.length>2" class="row row-2 full-flex">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="i>2&&i<5" class="item">
                                {{column.title}}:
                            <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                            <span  (click)="column.fieldName === 'receiptNumber' ? onRowClick(element) : ''" *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass)}">{{element[column.fieldName]}}</span>
                            <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                            <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                            </span>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="expandedColumn.length>4" class="row row-3 flex-center">
                        <ng-container  *ngFor="let column of expandedColumn; let i=index">
                            <div *ngIf="i>4" class="item">
                                {{column.title}}:
                            <img *ngIf="column.extraValue=='lock'" src="../../../assets/images/Table/{{element.status}}.svg" alt="">
                            <span *ngIf="!column.extraValue" class="value" [ngClass]="{blue: ('blue').includes(column.extraClass), underline: ('underline').includes(column.extraClass),
                                            blueUnderline: ('blue-underline').includes(column.extraClass)}">{{element[column.fieldName]}}</span>
                            <span class="value blue" *ngIf="column.extraValue=='amount'">  {{element[column.fieldName]}} <span *ngIf="element[column.fieldName]" class="currency">{{currency}}</span> </span>
                            <span class="value" *ngIf="column.extraValue=='rating'" (click)="column.action ? column.action($event, element) : null">
                                <star-rating [showHalfStars]="true" [readOnly]="true" [starType]="'svg'" [rating]="element[column.fieldName]"></star-rating>
                            </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="useExpand ? (expandedElement = expandedElement === element ? null : element) : null">
        </tr>
        <ng-container *ngIf="useExpand">
            <tr  mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </ng-container>
    </table>
    <div *ngIf="data.length===0&&!isLoading" class="empty">
        <img src="../../../../assets/images/Home/bag-empty-3.png" alt="">
        <h1 >List is empty</h1>
    </div>
    <mat-spinner [diameter]="100" [strokeWidth]='7' [style.display]="isLoading ? 'block' : 'none'"></mat-spinner>
</div>

<mat-paginator
    [hidden]='isLoading'
    *ngIf="usePagination"
    (page)="updatePagination($event, 'page')"
    class="pagination"
    [length]="totalRows"
    [pageSizeOptions]="paginationSizeOptions">
</mat-paginator>
