<div class="content">
    <button class="btn-icon close" (click)="this.dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <h1  mat-dialog-title>Upload image</h1>
    <app-image-editor
        class="editor"
        [useButtons]='false'
        [(ngModel)]="image"
        [imgCropToHeight]='imageSize.height'
        [imgCropToWidth]='imageSize.width'
        [showSizeInfo]='false'
        [imageName]='"Image"'>
    </app-image-editor>
    <div mat-dialog-actions class="bottom full-flex">
        <div class="btn-wrapp full-flex">
            <button (click)="imageEditorComponent.imageUpload.nativeElement.click()" class="btn btn-gray mr-6">
                <span *ngIf="!image">Add image</span>
                <span *ngIf="image">Change image</span>
            </button>
            <button [disabled]='!image' (click)="save()" class="btn btn-green shadow">Save</button>
        </div>
    </div>
</div>