import { MessagesService } from '../../../_shared/services/rest-services/messages.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthUserService } from '../../../@auth/auth-user.service';
import { ToastService } from '../../../_shared/services/toast.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';

const MAX_SIZE = 10 * 1024 * 1024; // 10MB

@Component({
  selector: 'app-reply-message',
  templateUrl: './reply-message.component.html',
  styleUrls: ['./reply-message.component.scss']
})
export class ReplyMessageComponent implements OnInit {
  @ViewChild('FileInput', { static: false }) FileInput: ElementRef;
  public userUid = '';
  public form: FormGroup;
  public subscription: Subscription;
  public files = [];

  constructor(@Inject (MAT_DIALOG_DATA) public data: any,
              public toastService: ToastService,
              private authUserService: AuthUserService,
              private messagesService: MessagesService,
              public dialogRef: MatDialogRef<ReplyMessageComponent>) {
              }

  ngOnInit(): void {
    this.userUid = this.authUserService.getUserUid();
    this.createForm();
    this.patchValues();
  }

  createForm() {
    this.form = new FormGroup({
      toName: new FormControl({value: null, disabled: true}, [Validators.required, Validators.maxLength(100)]),
      receipt: new FormControl({value: null, disabled: true}, [Validators.required, Validators.maxLength(500)]),
      title: new FormControl({value: null, disabled: true}, [Validators.required, Validators.maxLength(100)]),
      message: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
    });
  }

  patchValues() {
    this.form.patchValue({
      toName: this.data.category === 'Sent' ? this.data.message.to : this.data.message.from,
      receipt: this.data.message.receiptNumber,
      title: this.data.message.title
    });
  }

  send() {
    const fd = new FormData();
    fd.append('title', this.form.get('title').value);
    fd.append('message', this.form.get('message').value);
    if (this.data.message.uid) {
      fd.append('replyUid', this.data.message.uid);
    }
    if (this.data.message.readerUid) {
      fd.append('readerUid', this.data.message.readerUid);
    }
    this.files.forEach((file: any) => {
      fd.append('files', file);
    });
    this.messagesService.sendMessage(this.userUid, this.data.message.receiptUid, fd)
      .subscribe((response: any) => {
        this.toastService.success(response.message);
        setTimeout(() => {
          this.dialogRef.close(true);
          this.files = [];
        }, 1000);
      },
    (err) => this.toastService.warning(err.error.errors[0].message));
  }

  fileSelected(event) {
    for (const file of event) {
      if (file.size <= MAX_SIZE) {
        this.files.push(file);
      } else {
        this.toastService.warning(`Maximum file size exceed. Max file size is 10 MB.`);
      }
    }
  }

  clickInput() {
    this.FileInput.nativeElement.click();
  }

  deleteFile(index) {
    this.files.splice(index, 1);
  }

}
